<button mat-icon-button class="close-button" (click)="onNoClick()" >
  <mat-icon>close</mat-icon>
</button>
<div class="ostso-dialog" [ostsoIsEnabled]="isEnabled" (closeEvent)="onNoClick()">
  <h1>{{ isNew?"Создание группы":"Редактирование группы" }}</h1>
  <div mat-dialog-content>

    <textarea class="remarktype-textarea" matInput [formControl]="name" placeholder="Полное наименование"></textarea>
    <textarea class="remarktype-textarea" matInput [formControl]="shortName" placeholder="Краткое наименование"></textarea>

  </div>
  <div mat-dialog-actions>
    <button class="btn btn-secondary" mat-button (click)="onNoClick()">Отменить</button>
    <button class="btn btn-blue" mat-button [mat-dialog-close]="data" cdkFocusInitial>Сохранить</button>
  </div>
</div>

