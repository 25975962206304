import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {JsonPipe} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {
  AccessDeniedMessageComponent
} from '../../../../../shared/components/access-denied-message/access-denied-message.component';
import {IsEnabledDirective} from '../../../../../core/directives/is-enabled.directive';
import {AccessService} from '../../../../../core/services/access.service';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {Router} from '@angular/router';


@Component({
  standalone: true,
  selector: 'ostso-kind-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['../dialogForGroup/register-dialog.component.scss'],
  imports: [
    MatFormField,
    MatDialogClose,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    JsonPipe,
    MatAutocomplete,
    MatOption,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatSelect,
    AccessDeniedMessageComponent,
    IsEnabledDirective,
    MatIcon,
    MatIconButton
  ]
})
export class RegisterDialogGroupEditComponent {

  isNew: boolean = false;

  name = new FormControl();
  shortName = new FormControl();
  isEnabled: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RegisterDialogGroupEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, any>,
    public accessService: AccessService,
    private router: Router
  ) {
    this.isNew = data['isNew'];
    this.initSelectors();
    this.accessService.guide$.subscribe(v=>{
      this.isEnabled = v['group'];
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/group']);
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
    this.router.navigate(['/group']);
  }

  private initSelectors(): void {
    this.name.setValue(this.data['name']);
    this.shortName.setValue(this.data['shortName']);
    this.name.valueChanges.subscribe(v => {
      this.data['name'] = v;
    });
    this.shortName.valueChanges.subscribe(v => {
      this.data['shortName'] = v;
    });
  }


}

