<div class="zoom-dialog">
  <img class="zoom-dialog__image" [src]="data" alt="Zoom" [ngStyle]="{width: zoomScale*100+'%', height: 'calc('+zoomScale*100+'vh - 2rem)'}" />
  <div class="zoom-dialog__close" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </div>
  <div class="zoom-dialog__zoom">
    <div class="zoom-dialog__zoom-title">x {{ zoomScale }}</div>
    <mat-slider vertical min="1" max="3" step="0.1">
      <input matSliderThumb [(ngModel)]="zoomScale" >
    </mat-slider>
  </div>
</div>
