import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'ostso-access-denied-message',
  standalone: true,
  imports: [],
  templateUrl: './access-denied-message.component.html',
  styleUrl: './access-denied-message.component.scss'
})
export class AccessDeniedMessageComponent {
  @Output() public closeEvent = new EventEmitter();
  whenDisabledMessage = 'У Вас нет доступа к редактированию данной информации';

  onClose():void{
    this.closeEvent.emit();
  }
}
