import {Injectable} from '@angular/core';
import {RateStatusTypeService} from '../../modules/apiModule/api/rate-status-type.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatusesService {

  statuses$ = new BehaviorSubject<StatusRemarkType[]>([]);

  constructor(private statusesService: RateStatusTypeService) {
    this.fetch();
  }

  fetch(): void {
    this.statusesService.apiRatestatustypeGet().subscribe(data => {
      this.statuses$.next(data.values);
    });
  }

  getStatusById(id: number): StatusRemarkType|undefined {
    return this.statuses$.value.find(status => status.id === id);
  }

  getStatusByCode(code: string): StatusRemarkType|undefined {
    return this.statuses$.value.find(status => status.code === code);
  }
}
