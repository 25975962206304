<main>


    <mat-sidenav-container [class.opened]="opened">
        <mat-sidenav #sidenav mode="side" [(opened)]="opened">
            <button mat-icon-button (click)="sidenav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <ostso-side-bar-double/>
<!--            <ostso-side-bar></ostso-side-bar>-->
        </mat-sidenav>

        <mat-sidenav-content>
            <section>

                <h1>Группа оценок</h1>
                <ostso-table
                    [updateList]="list.asObservable()"
                    (rowSelected)="onRowSelected($event)"
                    (pageChange)="onPageChange($event)"
                >
                    <ng-template ostsoCellTemplate="other" let-element>
                        {{element.title}} {{element.isSelected}}
                    </ng-template>
                    <ng-template ostsoCellTemplate="isSelected" let-element>
                        <div style="width: 20px;height: 20px" [ngStyle]="element.isSelected ? {'background': 'green'} : {'background': 'red'}"></div>
                    </ng-template>

                    <ng-template ostsoActionTemplate let-element>
                        <button mat-icon-button [matMenuTriggerFor]="actionMenu" #menuTrigger="matMenuTrigger">
                            <mat-icon>menu</mat-icon>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="custom-menu">
                            <a mat-menu-item class="close-button" (click)="menuTrigger.closeMenu()">
                                <mat-icon>close</mat-icon>
                            </a>
                            <a>Редактировать</a>
                            <a>Копировать</a>
                            <a>Удалить ссылку</a>
                        </mat-menu>
                    </ng-template>

                </ostso-table>
            </section>
        </mat-sidenav-content>
    </mat-sidenav-container>

</main>
