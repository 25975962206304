import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import {Observable, catchError} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthService} from '../services/auth.service';
import {CommunicationService} from '../services/communication.service';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {

    // private secureRoutes = [`${environment.schema}://${environment.apiUrl}`];
    private secureRoutes = [`${environment.api_url}`];
    private static ERROR_401_MESSAGE = 'Неверный логин или пароль';

    constructor(private authService: AuthService,  private communicator: CommunicationService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.secureRoutes.find((x) => request.url.startsWith(x))) {
            return next.handle(request);
        }

        request = request.clone({
            withCredentials: true,
            setHeaders: {Authorization: `Bearer ${this.authService.token||''}`}
        });

        return next.handle(request).pipe(
            catchError((error) => {

                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return this.handle401Error(request, next);
                }

                throw error;
            })
        );
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // this.communicator.publish(BasicAuthInterceptor.ERROR_401_MESSAGE);
      return next.handle(request);
    }
    //
    //     if (!this.isRefreshing) {
    //         this.isRefreshing = true;
    //
    //         const lastEvent = new Date(this.storage.getData<any>('lastEvent')?.dt);
    //         const expires_in = this.authService.expires_in;
    //         const tr = new Date(this.storage.getData('tr'));
    //         const seconds = (lastEvent.getTime() - tr.getTime()) / 1000 - 60;
    //
    //         console.log('handle401Error', lastEvent, expires_in, tr, seconds);
    //
    //         if (expires_in <= seconds) {
    //             this.eventService.emit(new EventData('endSession', seconds));
    //             console.log(`emit::endSession -> ${seconds}`)
    //         } else {
    //             return this.authService.refreshToken().pipe(
    //                 switchMap(() => {
    //                     this.isRefreshing = false;
    //                     return next.handle(request);
    //                 }),
    //                 catchError((error) => {
    //                     this.isRefreshing = false;
    //                     console.log('error.status', error.status);
    //                     if (error.status == '403' || error.status == '401') {
    //                         this.eventService.emit(new EventData('logout', null));
    //                     }
    //
    //                     throw error;
    //                 })
    //             )
    //         }
    //     }
    //
    //     return next.handle(request);
    // }
}

