import {AfterViewInit, Component, DestroyRef, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule, Validators,
} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {RemarkTypeService} from '../../../../apiModule';
import {Group1Service} from '../../../../apiModule/api/group1.service';
import {JsonPipe, NgClass, NgStyle} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {SubgroupService} from '../../../../apiModule/api/subgroup.service';
import {map, Observable, toArray} from 'rxjs';
import {KindService} from '../../../../apiModule/api/kind.service';
import {MatSelect} from '@angular/material/select';
import {GroupEstimate} from '../../../../apiModule/model/groupEstimate';
import {SubgroupEstimate} from '../../../../apiModule/model/subgroupEstimate';
import {Kind} from '../../../../apiModule/model/kind';
import {RemarkType} from '../../../../apiModule/model/remarktype';
import {RemarktypeService} from '../../../../apiModule/api/remarktype.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton} from '@angular/material/button';
import {FallbackComponent} from '../../../../../shared/components/fallback/fallback.component';

export interface LinkOptions {
  data: any[],
  node: string,
  control: AbstractControl
}

export interface LinkExtendOptions extends LinkOptions {
  firstElement: any
}

@Component({
  standalone: true,
  selector: 'ostso-remarktype-filter',
  templateUrl: './filter-remarktype.component.html',
  styleUrls: [
    '../../dialogs/dialogForGroup/register-dialog.component.scss',
    './filter-remarktype.component.scss'
  ],
  imports: [
    MatFormField,
    MatDialogClose,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    JsonPipe,
    MatAutocomplete,
    MatOption,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatSelect,
    MatIcon,
    MatIconButton,
    FallbackComponent,
    MatButton,
    NgClass,
    NgStyle
  ]
})
export class FilterRemarktypeComponent implements AfterViewInit {

  loadedGroup: boolean = false;
  loadedSubgroup: boolean = false;
  loadedKind: boolean = false;
  loadedRemarktype: boolean = false;

  typeOfFilter: FilteredObjectName = 'remarktype';

  get loaded(): boolean {
    return this.loadedGroup && this.loadedSubgroup && this.loadedKind && this.loadedRemarktype;
  }

  isValid: boolean = false;

  groups: GroupEstimate[] = [];
  subgroups: SubgroupEstimate[] = [];
  kinds: Kind[] = [];
  remarktypes: RemarkType[] = [];

  form = this.fb.group({
    group: new FormControl() as FormControl<GroupEstimate[]>,
    subgroup: new FormControl() as FormControl<SubgroupEstimate[]>,
    kind: new FormControl() as FormControl<Kind[]>,
    remarktype: new FormControl() as FormControl<RemarkType[]>,
    notHasRemarks: [false],
    cost: this.fb.group({
      from: new FormControl(0, [Validators.min(0), Validators.max(100)]) as FormControl<number>,
      to: new FormControl(100, [Validators.min(0), Validators.max(100)]) as FormControl<number>
    }),
    archive: 0
  });

  get archive(): AbstractControl<number | null, number | null> | null{
    return this.form.get('archive');
  }

  get filteredSubgroups(): SubgroupEstimate[] {
    return this.subgroups.filter(subgroup => {
      const groupValue = this.form.get('group')!.value;
      if (!groupValue || groupValue.length === 0) return false;
      return !!groupValue.find(group => subgroup.rateGroup?.id === group.id);
    });
  }

  get filteredKinds(): Kind[] {
    return this.kinds.filter(kind => {
      const subgroupValue: SubgroupEstimate[] = this.form.get('subgroup')!.value;
      if (!subgroupValue || subgroupValue.length === 0) return false;
      return !!subgroupValue.find(subgroup => kind.rateSubGroup?.id === subgroup.id);
    });
  }

  get filteredRemarktypes(): RemarkType[] {
    return this.remarktypes.filter(remarktype => {
      const kindValue: Kind[] = this.form.get('kind')!.value;
      if (!kindValue || kindValue.length === 0) return false;
      return !!kindValue.find(kind => remarktype.rateKind?.id === kind.id);
    });
  }

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<FilterRemarktypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, any>,
    public groupData: Group1Service|null,
    public subgroupData: SubgroupService|null,
    public kindData: KindService|null,
    public remarktypeService: RemarktypeService|null,
    public destroyRef: DestroyRef
  ) {

  }

  ngAfterViewInit(): void {
    this.form.valueChanges.subscribe(console.log);
    this.form.statusChanges.subscribe(console.log);
    this.archive?.valueChanges.subscribe(console.log);

    this.mapData.forEach(e => {
      e.data && this.getAll(e.data)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(value => {
          (this as Record<string, any>)[e.name] = value;
          (this as Record<string, any>)[e.flag] = true;
          if(!this.data || !this.data[e.fromData] || !this.data[e.fromData].length) return;
          const injectingData = (this as Record<string, any>)[e.name].filter((v:{id:number}) => this.data[e.fromData].find((i:{id:number}) => i.id === v.id));
          this.form.get(e.fromData)!.setValue(injectingData);
        });
    });
    this.data && this.data['cost'] && this.data['cost']['from'] && this.form.get('cost')!.get('from')!.setValue(this.data['cost']['from']);
    this.data && this.data['cost'] && this.data['cost']['to'] && this.form.get('cost')!.get('to')!.setValue(this.data['cost']['to']);

    this.form.statusChanges.subscribe(v => {
      this.isValid = v === 'VALID';
    });

    this.fillControlsFromData();

  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  onReset(): void {
    this.form.reset();
    this.form.get('cost')!.get('from')!.setValue(0);
    this.form.get('cost')!.get('to')!.setValue(100);
  }

  mapData = [
    {name: 'groups', data: this.groupData, flag: 'loadedGroup', fromData: 'group'},
    {name: 'subgroups', data: this.subgroupData, flag: 'loadedSubgroup', fromData: 'subgroup'},
    {name: 'kinds', data: this.kindData, flag: 'loadedKind', fromData: 'kind'},
    {name: 'remarktypes', data: this.remarktypeService, flag: 'loadedRemarktype', fromData: 'remarktype'},
  ];


  getAll(service: RemarkTypeService): Observable<any[]> {
    return service
      .apiRategroupFilterlistGet({filteredObjectName: this.typeOfFilter}, 'body', false, {transferCache: false})
      .pipe(
        map(v => v.values),
        toArray(),
        map(v => v.flat())
      );
  }

  fillControlsFromData():void{
    if(!this.data) return;
    this.form.get('group')!.setValue(this.data['group']||[]);
    this.form.get('subgroup')!.setValue(this.data['subgroup']||[]);
    this.form.get('kind')!.setValue(this.data['kind']||[]);
    this.form.get('remarktype')!.setValue(this.data['remarktype']||[]);
    this.form.get('notHasRemarks')!.setValue(this.data['notHasRemarks']||false);
    this.form.get('archive')!.setValue(this.data['StatusId']||0);

  }

}

