import { Component, DestroyRef, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {
  AbstractControl,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule, ValidationErrors,
} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {AsyncPipe, JsonPipe, NgClass, NgStyle} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton} from '@angular/material/button';
import {FallbackComponent} from '../../../../shared/components/fallback/fallback.component';
import {
  MatAccordion,
  MatExpansionModule,
  MatExpansionPanel,
  MatExpansionPanelDescription
} from '@angular/material/expansion';
import {SearchSelectComponent} from '../../../../shared/components/search-select/search-select.component';
import {MaskNumberDirective} from '../../../../core/directives/mask-number.directive';
import {AutocompleteComponent} from '../../../../shared/components/autocomplete/autocomplete.component';
import {InstitutionsService} from '../../../apiModule/api/institutions.service';
import {FacilityService} from '../../../apiModule/api/facility.service';
import {Group1Service} from '../../../apiModule/api/group1.service';
import {SubgroupService} from '../../../apiModule/api/subgroup.service';
import {CommunicationService} from '../../../../core/services/communication.service';
import {FacilityTypeService} from '../../../apiModule/api/facility-type.service';
import {KindService} from '../../../apiModule/api/kind.service';
import {RemarktypeService} from '../../../apiModule/api/remarktype.service';
import {ConnectList, connectListItem} from './connectList';
import {MultiSelectComponent} from '../../../../shared/components/multi-select/multi-select.component';
import {Fields, type FilterField} from './fields';
import {InspectorsService} from '../../../../core/services/inspectors.service';
import {OperatorsService} from '../../../../core/services/operators.service';
import {PriorityService} from '../../../apiModule/api/priority.service';
import {RateStatusTypeService} from '../../../apiModule/api/rate-status-type.service';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {CustomDateAdapter} from '../../../../custom-date-adapter';
import 'moment/locale/ru';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  QuickAutocompleteComponent
} from '../../../../shared/components/quick-autocomplete/quick-autocomplete.component';
// import {default as _rollupMoment} from 'moment/moment';
// import * as _moment from 'moment/moment'; // Импортируйте русскую локаль

// const moment = _rollupMoment || _moment;

@Component({
  standalone: true,
  selector: 'ostso-institutions-filter',
  templateUrl: './filter-rate-card.component.html',
  styleUrls: [
    '../../register/dialogs/dialogForGroup/register-dialog.component.scss',
    './filter-rate-card.component.scss'
  ],
  imports: [
    MatFormField,
    MatDialogClose,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    JsonPipe,
    MatAutocomplete,
    MatOption,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatSelect,
    MatIcon,
    MatIconButton,
    FallbackComponent,
    MatButton,
    NgClass,
    NgStyle,
    MatExpansionModule,
    MatExpansionPanelDescription,
    MatExpansionPanel,
    MatAccordion,
    SearchSelectComponent,
    MaskNumberDirective,
    AutocompleteComponent,
    AsyncPipe,
    MultiSelectComponent,
    MatDatepicker,
    MatDatepickerInput,
    MatDatepickerToggle,
    QuickAutocompleteComponent
  ],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter}, // Используйте ваш пользовательский адаптер
    {provide: MAT_DATE_LOCALE, useValue: 'ru'} // Установите нужную локаль
  ]
})
export class FilterRateCardComponent implements OnInit {

  private loadedFlags: Record<string, boolean> = {
   loadedFirms: false,
   loadedFacility: false,
    loadedAddress: false,
   loadedFacilityType: false,
   loadedGroup: false,
   loadedSubGroup: false,
   loadedKind: false,
   loadedRemarkTypes: false,
   loadedInspectors: false,
   loadedOperators: false,
   loadedPriority: false,
   loadedStatuses: false,
  };

  get loaded(): boolean {
    return Object.values(this.loadedFlags).every(Boolean);
  }


  form = this.fb.group({
    firmIds: [this.dataDialog && this.dataDialog['firmIds'] || []],
    facilityIds: [this.dataDialog && this.dataDialog['facilityIds'] || []],
    facilityTypeIds: [this.dataDialog && this.dataDialog['facilityTypeIds'] || []],
    rateGroupIds: [this.dataDialog && this.dataDialog['rateGroupIds'] || []],
    rateSubGroupIds: [this.dataDialog && this.dataDialog['rateSubGroupIds'] || []],
    rateKindIds: [this.dataDialog && this.dataDialog['rateKindIds'] || []],
    remarkTypeIds: [this.dataDialog && this.dataDialog['remarkTypeIds'] || []],
    priorityIds: [this.dataDialog && this.dataDialog['priorityIds'] || []],
    statusesIds: [this.dataDialog && this.dataDialog['statusesIds'] || []],
    InspectorIds: [this.dataDialog && this.dataDialog['InspectorIds'] || []],
    OperatorIds: [this.dataDialog && this.dataDialog['OperatorIds'] || []],
    dateFrom: [this.dataDialog && this.dataDialog['dateFrom']],
    dateTo: [this.dataDialog && this.dataDialog['dateTo']],
    daysInWorkFrom: [this.dataDialog && this.dataDialog['daysInWorkFrom']],
    daysInWorkTo: [this.dataDialog && this.dataDialog['daysInWorkTo']],

    statusId: [this.dataDialog && this.dataDialog['statusId']],
    ids: [this.dataDialog && this.dataDialog['ids'], transformCommaSeparated],
    facilityAddress: [this.dataDialog && this.dataDialog['facilityAddress']],
    isInvestmentObject: [this.dataDialog && this.dataDialog['isInvestmentObject']],
    isLongspanStructureObject: [this.dataDialog && this.dataDialog['isLongspanStructureObject']],
  });

  data: Record<string, any[]> = {
    firms: [],
    facilities: [],
    addresses: [],
    facilityTypes: [],
    groups: [],
    subGroups: [],
    kinds: [],
    remarkTypes: [],
    inspectors: [],
    operators: [],
    priority: [],
    statuses: [],
    ids:[]
  };


  readonly fields: FilterField[] = Fields;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataDialog: Record<string, any>,
    public dateAdapter: DateAdapter<Date>,
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<FilterRateCardComponent>,
    public notifications: CommunicationService,
    public firmsService: InstitutionsService,
    public facilityService: FacilityService,
    public facilityTypeService: FacilityTypeService,
    public groupService: Group1Service,
    public subGroupService: SubgroupService,
    public kindService: KindService,
    public remarkTypesService: RemarktypeService,
    public inspectorsService: InspectorsService,
    public operatorsService: OperatorsService,
    public priorityService: PriorityService,
    public statusesService: RateStatusTypeService,
    private destroyRef: DestroyRef,
  ) {
    this.dateAdapter.setLocale('ru');

    this.form.get('daysInWorkFrom')?.valueChanges.subscribe(value => {
      const transformedValue = value.replace(/\D/g, '');
      if (value !== transformedValue) {
        this.form.get('daysInWorkFrom')?.setValue(transformedValue, { emitEvent: false });
      }
    });
    this.form.get('daysInWorkTo')?.valueChanges.subscribe(value => {
      const transformedValue = value.replace(/\D/g, '');  // Оставляем только цифры
      if (value !== transformedValue) {
        this.form.get('daysInWorkTo')?.setValue(transformedValue, { emitEvent: false });
      }
    });
    // this.form.get('dateFrom')?.valueChanges.subscribe((value) => {
    //   if(!value) return;
    //   if (value) {
    //     this.form.get('dateFrom')?.setValue(value.toDate().toISOString(), { emitEvent: false });
    //   }
    // })
    // this.form.get('dateTo')?.valueChanges.subscribe((value) => {
    //   if(!value) return;
    //   if (value) {
    //     this.form.get('dateTo')?.setValue(value.toDate().toISOString(), { emitEvent: false });
    //   }
    // })
  }


  static readonly getDataErrorMessages: Record<string, string> = {
    firmsService: 'Ошибка получения списка учреждений',
    facilityTypeService: 'Ошибка получения списка типов объектов',
    facilityService: 'Ошибка получения списка объектов',
    groupService: 'Ошибка получения списка групп замечаний',
    subGroupService: 'Ошибка получения списка подгрупп замечаний',
    kidsService: 'Ошибка получения списка видов замечаний',
    remarkTypesService: 'Ошибка получения списка замечаний',
    inspectorsService: 'Ошибка получения списка испекторов',
    operatorsService: 'Ошибка получения списка операторов',
    priorityService: 'Ошибка получения списка приоритетов',
    statusesService: 'Ошибка получения списка статусов',
  };

  private readonly connectList: connectListItem[] = ConnectList;

  private connectFn({method, service, target, loadedLink, errorMessageLink}: connectListItem): void {
    (this as Record<string, any>)[service][method]({filteredObjectName:'ratecard'})
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: any) => {
          if(!value) value=[];
          this.data[target] = value.length ? value : value.values;
          this.loadedFlags[loadedLink] = true;
          console.log('загружен', loadedLink, this.loadedFlags);
        },
        error: () => {
          this.notifications.publish(FilterRateCardComponent.getDataErrorMessages[errorMessageLink], {type: 'error'});
        }
      });
  }


  ngOnInit(): void {
    this.connectList.forEach((item: connectListItem) => {
        this.connectFn(item);
    });
    this.form.get('dateFrom')!.valueChanges.subscribe(v=>{
      console.log(v);
    });
  }

  onReset(): void {
    this.form.patchValue({
      firmIds: [],
      facilityIds: [],
      facilityTypeIds: [],
      rateGroupIds: [],
      rateSubGroupIds: [],
      rateKindIds:[],
      remarkTypeIds:[],
      priorityIds: [],
      statusesIds: [],
      InspectorIds: [],
      OperatorIds: [],
      dateFrom: null,
      dateTo: null,
      daysInWorkFrom: null,
      daysInWorkTo: null,
      statusId: 0,
      facilityAddress: '',
      ids:[],
      isInvestmentObject: null,
      isLongspanStructureObject: null,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onApply():void {
    const dateFrom = this.form.get('dateFrom')?.value;
    if(dateFrom){
      this.form.get('dateFrom')?.setValue(dateFrom.toISOString(),{ emitEvent: false });
    }
    const dateTo = this.form.get('dateTo')?.value;
    if(dateTo){
      this.form.get('dateTo')?.setValue(dateTo.toISOString(),{ emitEvent: false });
    }
    this.dialogRef.close(this.form.value);
  }
}

export function transformCommaSeparated(control: AbstractControl): ValidationErrors | null {
  const value = control.value;
  if (typeof value === 'string') {
    const transformedValue = value.split(',').map(v => {
      const res = parseInt(v.trim());
      return isNaN(res)? '' : res;
    });
    control.setValue(transformedValue, { emitEvent: false });
  }
  return null;  // Возвращаем null, потому что это не является ошибкой валидации
}

//TODO: проблемы с датами (возвращает Moment объект)
