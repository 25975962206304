import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit
} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {AsyncPipe} from '@angular/common';
import {ToastsService} from '../../../core/services/toasts.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'ostso-toasts',
  standalone: true,
  imports: [
    MatIcon,
    AsyncPipe
  ],
  templateUrl: './toasts.component.html',
  styleUrl: './toasts.component.scss',
})
export class ToastsComponent implements OnInit{
  toasts = inject(ToastsService).toasts$;
  destroyRef = inject(DestroyRef);
  cdr = inject(ChangeDetectorRef);

  ngOnInit(): void {
  this.toasts
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe((toasts) => {
    console.log({toasts});
    this.cdr.detectChanges();
  });
  }
}
