import {Component, EventEmitter, HostBinding, input, model, OnInit, Output, viewChild} from '@angular/core';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'ostso-do-agree',
  standalone: true,
  imports: [
    MatButton
  ],
  templateUrl: './do-agree.component.html',
  styleUrl: './do-agree.component.scss'
})
export class DoAgreeComponent  {
  show = model(false, {alias: 'showDoAgree'});
  answer = model({value:false}, {alias: 'answerDoAgree'});
  title = input('', {alias: 'titleDoAgree'});

  @HostBinding('class.open') get hostClass(): boolean{
    return this.show();
  }

  yes():void{
    this.show.set(false);
    this.answer.set({value: true});
  }

  no():void{
    this.show.set(false);
    this.answer.set({value: false});
  }

}
