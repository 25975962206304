import {Component, Inject} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {StorageService} from './core/services/storage.service';
import {RETURN_URL} from './app.config';
import {environment} from '../environments/environment';

@Component({
  selector: 'ostso-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'ostso.front';



  constructor(private router: Router, private storage: StorageService, @Inject(RETURN_URL) private returnURL: string) {
    console.log('environment',environment);
    if(environment.production === true) return;
    this.router.events.subscribe(() => {
      if (this.router.url !== '/login' && this.router.url !== '/logout' && this.router.url !== '/') {
        this.storage.setItem(this.returnURL, this.router.url);
      }
    });
  }


}
