import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {map} from 'rxjs/operators';
import {RemarktypeService} from '../../../apiModule/api/remarktype.service';
import {RemarkType} from '../../../apiModule/model/remarktype';

export const remarktypeResolver: ResolveFn<any> = (
  _,
  __,
  remarktypeService: RemarktypeService = inject(RemarktypeService)
) => {
  return remarktypeService
    .apiRemarktypeGet({}, 'body', false, {transferCache: false})
    .pipe(map(res => ({
      pager: res.pager,
      values: res.values.map((item: RawData) => new RemarkType(
        item.id,
        item.name,
        item.shortName,
        item.significance ,
        item.rateGroup,
        item.rateSubGroup,
        item.rateKind,
        item.ratePriorityDaysForFix,
        item.status,
        item.notHasRemarks
      ))
    })));
};
