<button mat-icon-button class="close-button" (click)="onNoClick()" >
  <mat-icon>close</mat-icon>
</button>
<div class="ostso-dialog" [ostsoIsEnabled]="isEnabled" (closeEvent)="onNoClick()">
  @if (!loaded) {
    <ostso-fallback/>
  }
    <h1>{{ isNew ? "Создание подгруппы" : "Редактирование подгруппы" }}</h1>
    <div mat-dialog-content>
        <mat-select class="remarktype-select" [formControl]="groupSelector" placeholder="Группа">
          @for (group of groups; track group.name) {
            <mat-option [value]="group.name">
              {{ group.name }}
            </mat-option>
          }
        </mat-select>

      <textarea class="remarktype-textarea" matInput [formControl]="name" placeholder="Полное наименование"></textarea>
      <textarea class="remarktype-textarea" matInput [formControl]="shortName" placeholder="Краткое наименование"></textarea>

    </div>
    <div mat-dialog-actions>
      <button class="btn btn-secondary" mat-button (click)="onNoClick()">Отменить</button>
      <button
        [disabled]="!groupSelector.valid || !name.valid"
        class="btn save-button"
        [class.btn-blue]="groupSelector.valid && name.valid"
        mat-button (click)="onClose()" cdkFocusInitial>Сохранить</button>
    </div>
</div>

