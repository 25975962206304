import {Component, Inject} from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';


@Component({
  selector: 'dashboard-modal',
  templateUrl: 'dashboard-modal.component.html',
  styleUrls: ['./dashboard-modal.component.scss', '../dashboard/dashboard.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
MatIconButton,
MatIcon
  ],
})
export class DashboardModal {
  constructor(
    public dialogRef: MatDialogRef<DashboardModal>,    
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}