import {AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[ostsoAccessable]',
  standalone: true
})
export class AccessableDirective implements AfterViewInit, OnChanges{
  @Input('ostsoAccessable') enabled?: boolean;

  constructor(public el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    if (this.enabled) return;
    this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.5');
    this.renderer.addClass(this.el.nativeElement, 'disabled-access');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enabled'] && changes['enabled'].previousValue !== changes['enabled'].currentValue) {
      console.log({changes});
      if (this.enabled) {
        this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'all');
        this.renderer.setStyle(this.el.nativeElement, 'opacity', '1');
        this.renderer.removeClass(this.el.nativeElement, 'disabled-access');
      } else {
        this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
        this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.5');
        this.renderer.addClass(this.el.nativeElement, 'disabled-access');

      }
    }
  }
}
