import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private storage: Storage | undefined;
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
    this.storage = document.defaultView?.localStorage;
  }

  setItem(key: string, value: string): void {
    this.storage?.setItem(key, value);
  }

  getItem(key: string): string | null | undefined {
    return this.storage?.getItem(key);
  }

  removeItem(key: string):void{
    this.storage?.removeItem(key);
  }
}
