<div class="grade-right-wrapper">
  <input #file type="file" class="grade-right-wrapper__file" multiple (change)="selectFiles()">
  <h2><span>{{ title }}</span></h2>
  <div class="slider">
    <div class="element">

      <div class="swiper-container" #container>
        <div class="swiper-wrapper" >
          @for (i of slides; track i.id) {
            <div swiperSlide class="swiper-slide">
              <ostso-slide [item]="i"/>
            </div>
          }
        </div>
        <!-- навигация -->
        <button class="slider-right" (click)="slideNext()" [disabled]="isDisabledRightButton">
          <mat-icon matSuffix>keyboard_arrow_right</mat-icon>
        </button>
        <button class="slider-left" (click)="slidePrev()" [disabled]="isDisabledLeftButton">
          <mat-icon matSuffix>keyboard_arrow_left</mat-icon>
        </button>
        <button class="zoom" (click)="swiper && zoom(swiper.activeIndex)">
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path
                d="M16.875 20.25C17.825 20.25 18.625 19.925 19.275 19.275C19.925 18.625 20.25 17.825 20.25 16.875C20.25 15.925 19.925 15.125 19.275 14.475C18.625 13.825 17.825 13.5 16.875 13.5C15.925 13.5 15.125 13.825 14.475 14.475C13.825 15.125 13.5 15.925 13.5 16.875C13.5 17.825 13.825 18.625 14.475 19.275C15.125 19.925 15.925 20.25 16.875 20.25ZM24.1125 26.25L20.175 22.3125C19.675 22.6375 19.1437 22.875 18.5812 23.025C18.0187 23.175 17.45 23.25 16.875 23.25C15.1 23.25 13.5938 22.6313 12.3563 21.3937C11.1188 20.1562 10.5 18.65 10.5 16.875C10.5 15.1 11.1188 13.5938 12.3563 12.3563C13.5938 11.1188 15.1 10.5 16.875 10.5C18.65 10.5 20.1562 11.1188 21.3937 12.3563C22.6313 13.5938 23.25 15.1 23.25 16.875C23.25 17.45 23.1688 18.0187 23.0063 18.5812C22.8438 19.1437 22.6 19.675 22.275 20.175L26.25 24.15L24.1125 26.25ZM7.5 31.5C6.675 31.5 5.96875 31.2063 5.38125 30.6188C4.79375 30.0312 4.5 29.325 4.5 28.5V22.5H7.5V28.5H13.5V31.5H7.5ZM22.5 31.5V28.5H28.5V22.5H31.5V28.5C31.5 29.325 31.2063 30.0312 30.6188 30.6188C30.0312 31.2063 29.325 31.5 28.5 31.5H22.5ZM4.5 13.5V7.5C4.5 6.675 4.79375 5.96875 5.38125 5.38125C5.96875 4.79375 6.675 4.5 7.5 4.5H13.5V7.5H7.5V13.5H4.5ZM28.5 13.5V7.5H22.5V4.5H28.5C29.325 4.5 30.0312 4.79375 30.6188 5.38125C31.2063 5.96875 31.5 6.675 31.5 7.5V13.5H28.5Z"
                fill="white"/>
            </g>
          </svg>
        </button>


      </div>
      <div class="img-actions">
        <button [disabled]="slides.length===0" (click)="saveFile()" >
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path
                d="M11.5972 15.1436L7.16419 10.7106L8.40543 9.42502L10.7106 11.7302V4.50439H12.4838V11.7302L14.789 9.42502L16.0302 10.7106L11.5972 15.1436ZM6.27759 18.69C5.78996 18.69 5.37252 18.5164 5.02527 18.1691C4.67802 17.8219 4.50439 17.4044 4.50439 16.9168V14.257H6.27759V16.9168H16.9168V14.257H18.69V16.9168C18.69 17.4044 18.5164 17.8219 18.1691 18.1691C17.8219 18.5164 17.4044 18.69 16.9168 18.69H6.27759Z"
                fill="#272B37"/>
            </g>
          </svg>
        </button>
        <button [disabled]="slides.length ===0" (click)="swiper && removeItem.emit(swiper.activeIndex)" [ostsoAccessable]="status()?.code!=='fixed' && status()?.code!=='without_remark' && mode()!=='watch' && !!slides.length && canControl">
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_69_1433" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                  width="22" height="23">
              <rect x="0.614502" y="0.958008" width="21.2784" height="21.2784" fill="#D9D9D9"/>
            </mask>
            <g>
              <path
                d="M6.82081 19.5765C6.33318 19.5765 5.91574 19.4029 5.56849 19.0556C5.22124 18.7083 5.04761 18.2909 5.04761 17.8033V6.27748H4.16101V4.50428H8.59401V3.61768H13.9136V4.50428H18.3466V6.27748H17.46V17.8033C17.46 18.2909 17.2864 18.7083 16.9391 19.0556C16.5919 19.4029 16.1744 19.5765 15.6868 19.5765H6.82081ZM15.6868 6.27748H6.82081V17.8033H15.6868V6.27748ZM8.59401 16.0301H10.3672V8.05068H8.59401V16.0301ZM12.1404 16.0301H13.9136V8.05068H12.1404V16.0301Z"
                fill="#272B37"/>
            </g>
          </svg>
        </button>
        <button (click)="downloadFile()" [ostsoAccessable]="mode()!=='watch' && canControl">
          <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path
                d="M10.024 16.0301H11.7972V12.4837H15.3436V10.7105H11.7972V7.16408H10.024V10.7105H6.47755V12.4837H10.024V16.0301ZM4.70435 19.5765C4.21672 19.5765 3.79928 19.4029 3.45203 19.0556C3.10478 18.7083 2.93115 18.2909 2.93115 17.8033V5.39088C2.93115 4.90325 3.10478 4.48581 3.45203 4.13855C3.79928 3.7913 4.21672 3.61768 4.70435 3.61768H17.1168C17.6044 3.61768 18.0218 3.7913 18.3691 4.13855C18.7163 4.48581 18.89 4.90325 18.89 5.39088V17.8033C18.89 18.2909 18.7163 18.7083 18.3691 19.0556C18.0218 19.4029 17.6044 19.5765 17.1168 19.5765H4.70435ZM4.70435 17.8033H17.1168V5.39088H4.70435V17.8033Z"
                fill="#272B37"/>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
</div>
