import {Injectable} from '@angular/core';
import {ITableColumn} from '../common/interfaces/table-column.interface';
import {StorageService} from './storage.service';
import {RegisterDataTableService} from './register-data-table.service';


@Injectable({
  providedIn: 'root'
})
export class RegisterFacilityDataTableService extends RegisterDataTableService {


  constructor(
    storageService: StorageService
  ) {
    super(storageService);
  }


  override getColumns(): ITableColumn[] {
    return [
      {
        name: 'id',
        title: 'Id',
        useCustomTemplate: false,
        default: true
      },
      {
        name: 'iasId',
        title: 'ID ИАС Спорт',
        useCustomTemplate: true,
        default: true
      },
      {
        name: 'egipRegistryNumber',
        title: 'Реестровый номер',
        useCustomTemplate: false,
        default: true
      },
      {
        name: 'balanceHolder',
        title: 'Балансодержатель',
        useCustomTemplate: true,
        default: true
      },
      {
        name: 'nameFacility',
        title: 'Название',
        useCustomTemplate: true,
        default: true
      },
      {
        name: 'type',
        title: 'Тип',
        useCustomTemplate: false,
        default: true
      },
      {
        name: 'address',
        title: 'Адрес',
        useCustomTemplate: false,
        default: true
      },
      {
        name: 'isInvestmentObject',
        title: 'Объект инвестстроительства',
        useCustomTemplate: true,
        default: false
      },
      {
        name: 'isLongspanStructureObject',
        title: 'Объект БПК',
        useCustomTemplate: true,
        default: false
      },
      {
        name: 'isInspectionRequired',
        title: 'Подлежит проверке',
        useCustomTemplate: true,
        default: true
      },
      {
        name: 'startDate',
        title: 'Дата ввода в эксплуатацию',
        useCustomTemplate: true,
        default: false
      },
      {
        name: 'district',
        title: 'Округ',
        useCustomTemplate: true,
        default: false
      },
      {
        name: 'region',
        title: 'Район',
        useCustomTemplate: true,
        default: false
      },
      {
        name: 'cadastralNumber',
        title: 'Кадастровый номер',
        useCustomTemplate: false,
        default: false
      },

    ];
  }


}
