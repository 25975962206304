import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input, Input, ViewChild} from '@angular/core';
import {FilesDownloaderComponent} from '../../../../shared/components/files-downloader/files-downloader.component';
import {NgIf} from '@angular/common';
import {SliderComponent} from '../../../../shared/components/slider/slider.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import {RatecardFileTypesService} from '../../../../core/services/ratecard-file-types.service';
import {ModeService} from '../mode.service';
import {AccessableDirective} from '../../../../core/directives/accessable.directive';
import {StatusService} from '../status.service';
// import {Camera2Component} from '../../../../shared/components/camera2/camera2.component';

@Component({
  selector: 'ostso-grade-right-side',
  standalone: true,
  imports: [
    FilesDownloaderComponent,
    NgIf,
    SliderComponent,
    AccessableDirective
  ],
  templateUrl: './grade-right-side.component.html',
  styleUrl: './grade-right-side.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GradeRightSideComponent {
  @Input() set files(value: (RateFile & { data?: File | Blob })[]) {
    this.filesArray = value;
    this.filesArray.forEach((_,i) => setTimeout(()=> {
      this.filesArray[i].uid = Date.now();
      // this.cdr.markForCheck();
    },30*i));
  }

  @Input() disabled = false;
  @ViewChild('sliderRemarkComponent', {read: SliderComponent}) sliderRemark!: SliderComponent;
  @ViewChild('sliderEliminationComponent', {read: SliderComponent}) sliderElimination!: SliderComponent;

  mode = inject(ModeService).mode;
  status = inject(StatusService).status;

  filesArray: (RateFile & { data?: File | Blob })[] = [];
  filesToRemove: (RateFile & { data?: File | Blob })[] = [];

  get addedToRemark(): (RateFile & { data?: File | Blob | null })[] {
    if (!this.sliderRemark) return [];
    return this.sliderRemark.addedData;
  }

  get addedToElimination(): (RateFile & { data?: File | Blob | null })[] {
    if (!this.sliderElimination) return [];
    return this.sliderElimination.addedData;
  }

  cdr = inject(ChangeDetectorRef);

  currentRights = input<Right>();

  dialog = inject(MatDialog);

  ratecardTypesService = inject(RatecardFileTypesService);


  get filesForRemark(): (RateFile & { data?: File | Blob })[] {
    return this.filesArray.filter(f => f.type.code === 'remark');
  }

  get filesForElimination(): (RateFile & { data?: File | Blob })[] {
    return this.filesArray.filter(f => f.type.code === 'elimination');
  }

  get addedFiles(): (RateFile & { data?: File | Blob })[] {
    return this.filesArray.filter(f => f.id === 0);
  }

  removeDialog(event: number, partIs: CodeRateFile): void {
    console.log(event);
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: `Удалить выбранный файл?`
    });
    confirmDialog.beforeClosed().subscribe(result => {
        if (!result) return;
        const mapPartAway: Record<CodeRateFile, RateFile[]> = {
          remark: this.filesForRemark,
          elimination: this.filesForElimination,
        };
        const partArray = mapPartAway[partIs];
        const foundPartItem = partArray.find((_, idx) => idx === event);
        if (!foundPartItem) return;

        this.filesToRemove.push(foundPartItem);

        const indexOfFoundPartItem = this.filesArray.findIndex(e => e.uid === foundPartItem?.uid);
        if (indexOfFoundPartItem === -1) return;
        this.filesArray = this.filesArray.toSpliced(indexOfFoundPartItem, 1);

        this.cdr.detectChanges();
      }
    );
  }

  addItems(file: File, code: CodeRateFile): void {
    console.log(file);
    let fileType: RateFileDateFileType = 'doc';
    if (file.type.includes('image')) {
      fileType = 'image';
    }
    const type = this.ratecardTypesService.getTypeByCode(code);
    if (!type) return;

    const item: RateFile = {
      type,
      name: 'name' in file && file.name || 'new-file',
      createAt: new Date('lastModified' in file && file.lastModified || Date.now()).toISOString(),
      id: 0,
      uid: Date.now()
    };
    this.filesArray = [...this.filesArray, {...item, data: file}];
    this.cdr.detectChanges();
  }


  filesDownloaded($event: File): void {
    const type: RateFileType = this.mode() === 'create' ||
    this.status()?.code === 'project' ||
    this.status()?.code === 'operator_agreement' ||
    this.status()?.code === 'inspector_revision' ? {
      id: 1,
      name: 'Замечание',
      code: 'remark'
    } : {
      id: 2,
      name: 'Устранение',
      code: 'elimination'
    };

    const newItem: RateFile & { data?: File | Blob } = {
      id: 0,
      uid: Date.now(),
      data: $event,
      name: $event.name,
      createAt: new Date(Date.now()).toISOString(),
      type
    };
    this.filesArray = [...this.filesArray, newItem];
  }

  // openCamera(): void {
  //   const dialogRef = this.dialog.open(Camera2Component, {
  //     maxWidth: '768px'
  //   });
  //
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (!result) return;
  //     const newItem = {
  //       type: 'image',
  //       base64: result,
  //       name: 'new-snapshot',
  //     };
  //     // result && (this.images = [...this.images, newItem]);
  //   });
  // }


}
