import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit, signal,
  ViewChild
} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatLabel, MatOption, MatSelect} from '@angular/material/select';
import {FormControl, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'ostso-camera2',
  standalone: true,
  imports: [
    MatIcon,
    MatSelect,
    ReactiveFormsModule,
    MatOption,
    MatLabel,
    MatFormField
  ],
  templateUrl: './camera2.component.html',
  styleUrl: './camera2.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Camera2Component implements OnDestroy, OnInit, AfterViewInit{
  @ViewChild('video') videoElement!: ElementRef<HTMLVideoElement>;
  // @ViewChild('select') selectElement!: ElementRef<HTMLSelectElement>;
  @ViewChild('canvas') canvasElement!: ElementRef<HTMLCanvasElement>;

  select = new FormControl<string>('');

  videoWidth = 0;
  videoHeight = 0;

  result: Blob | null = null;

  currentStream!: MediaStream;
  gotDevices = signal<MediaDeviceInfo[]>([]);

  constructor(public dialogRef: MatDialogRef<Camera2Component>) {

  }

  ngOnInit(): void {
    this.getDevices();
    this.select.valueChanges.subscribe(() => {
      this.startVideo();
    });
  }

  ngAfterViewInit():void {
    this.startVideo();
  }

  ngOnDestroy(): void {
    this.stopMediaTracks(this.currentStream);
  }

  stopMediaTracks(stream: MediaStream):void {
    this.videoElement.nativeElement.pause();
    this.videoElement.nativeElement.srcObject = null;
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
  }

  getDevices():void {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.gotDevices.set(devices.filter((device) => device.kind === 'videoinput'));
    });
  }

  startVideo():void {
    if (this.currentStream) {
      this.stopMediaTracks(this.currentStream);
    }

    const videoConstraints: MediaTrackConstraints = {};
    // const select = this.selectElement.nativeElement;

    if (this.select.value === '') {
      videoConstraints.facingMode = 'environment';
    } else {
      videoConstraints.deviceId = { exact: this.select.value||'' };
    }

    const constraints = {
      video: videoConstraints,
      audio: false,
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        this.currentStream = stream;
        this.videoElement.nativeElement.srcObject = stream;
        return navigator.mediaDevices.enumerateDevices();
      })
      .then((devices) => {
        this.gotDevices.set(devices.filter((device) => device.kind === 'videoinput'));
      })
      .catch((error) => console.error(error));
  }

  capture(): void {
    const context = this.canvasElement.nativeElement.getContext('2d');
    this.canvasElement.nativeElement.width = this.videoWidth;
    this.canvasElement.nativeElement.height = this.videoHeight;
    context && context.drawImage(this.videoElement.nativeElement, 0, 0, this.videoWidth, this.videoHeight);
    const dataURL = this.canvasElement.nativeElement.toDataURL('image/png');

    // Преобразуем Base64 в Blob
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

    const arrayBuffer = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      arrayBuffer[i] = byteString.charCodeAt(i);
    }

    this.result = new Blob([arrayBuffer], {type: mimeString});
    console.log(this.result);
    (window as any)['result'] = this.result;

    this.canvasElement.nativeElement.style.display = 'block';
    setTimeout(() => this.dialogRef.close(this.result), 3000);
  }
  onCanPlay(): void {
    const computedStyle = window.getComputedStyle(this.videoElement.nativeElement);
    this.videoHeight = parseInt(computedStyle.height);
    this.videoWidth = parseInt(computedStyle.width);
  }

  namedCamera(label:string):string{
    const back = label.includes('back');
    const front = label.includes('front');
    return back? 'Задняя камера' : front? 'Передняя камера' : label;
  }
}
