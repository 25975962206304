import {AfterViewInit, Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule, Validators,
} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {Group1Service} from '../../../../apiModule/api/group1.service';
import {JsonPipe, NgClass, NgStyle} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {SubgroupService} from '../../../../apiModule/api/subgroup.service';
import {KindService} from '../../../../apiModule/api/kind.service';
import {MatSelect} from '@angular/material/select';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton} from '@angular/material/button';
import {FallbackComponent} from '../../../../../shared/components/fallback/fallback.component';
import {FilterRemarktypeComponent} from '../remarktype/filter-remarktype.component';
import {InstitutionsService} from '../../../../apiModule/api/institutions.service';
import {ReferenceService} from '../../../../apiModule/api/reference.service';


@Component({
  standalone: true,
  selector: 'ostso-institutions-filter',
  templateUrl: './filter-institutions.component.html',
  styleUrls: [
    '../../dialogs/dialogForGroup/register-dialog.component.scss',
    './filter-institutions.component.scss'
  ],
  imports: [
    MatFormField,
    MatDialogClose,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    JsonPipe,
    MatAutocomplete,
    MatOption,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatSelect,
    MatIcon,
    MatIconButton,
    FallbackComponent,
    MatButton,
    NgClass,
    NgStyle
  ]
})
export class FilterInstitutionsComponent extends FilterRemarktypeComponent implements AfterViewInit {


  formInstitutions = this.fb.group({
    IasId: new FormControl(this.data && this.data['IasId']||null),
    Name: new FormControl(this.data && this.data['Name']||null),
    ManagerFioPart: new FormControl(this.data && this.data['ManagerFioPart']||null),
    Ogrn: new FormControl(this.data && this.data['Ogrn']||null),
    StatusId: new FormControl(this.data && this.data['StatusId'] ||null) as FormControl<StatusRemarkType>,
  });

  statusService = inject(ReferenceService);
  statuses: StatusRemarkType[] =[];

  constructor(fb: FormBuilder,
              dialogRef: MatDialogRef<FilterRemarktypeComponent>,
              @Inject(MAT_DIALOG_DATA) data: Record<string, any>,
              groupData: Group1Service,
              subgroupData: SubgroupService,
              kindData: KindService,
              remarktypeService: InstitutionsService,
              destroyRef: DestroyRef) {
    super(fb, dialogRef, data, null, null, null, remarktypeService, destroyRef);
    this.formInstitutions.get('IasId')!.valueChanges.subscribe((value: string) => {
      const newValue = value.replace(/[^0-9]/g, '');
      this.formInstitutions.get('IasId')!.setValue(newValue);
    });
    this.formInstitutions.get('Ogrn')!.valueChanges.subscribe((value: string) => {
      const newValue = value.replace(/[^0-9]/g, '');
      this.formInstitutions.get('Ogrn')!.setValue(newValue);
    });
    this.statusService.apiReferenceStatusesGet('body').subscribe((value: any)  =>  {
      console.log('statuses',value);
      this.statuses = value.values;
    });
  }

  onInstitutionsReset():void{
    this.formInstitutions.reset();
  }

  override typeOfFilter: FilteredObjectName = 'firm';

}

