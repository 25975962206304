
  <div class="grade-right-wrapper">
    <h2><span>ЗАМЕЧАНИЯ</span></h2>
    <ostso-files-downloader (filesDownloaded)="filesDownloaded($event)" [attr.disabled]="mode()==='watch' ||  (!currentRights()!.load_remark_docs && !currentRights()!.load_elimination_docs)"/>
  </div>
  <ostso-slider
    #sliderRemarkComponent
    [slides]="filesForRemark"
    [title]="'ЗАМЕЧАНИЯ'"
    [canControl]="currentRights()!.load_remark_docs"
    (removeItem)="removeDialog($event,'remark')"
    (addedFiles)="addItems($event,'remark')"
  />
  <ostso-slider
    #sliderEliminationComponent
    [slides]="filesForElimination"
    [title]="'УСТРАНЕНИЕ'"
    [canControl]="currentRights()!.load_elimination_docs"
    (removeItem)="removeDialog($event, 'elimination')"
    (addedFiles)="addItems($event,'elimination')"
  />



