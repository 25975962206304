import {AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {MatListOption, MatSelectionList} from '@angular/material/list';
import {BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, from, mergeMap, of, tap} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {RemarkTypeService} from '../../../modules/apiModule';
import {JsonPipe, NgStyle} from '@angular/common';
import {CdkFixedSizeVirtualScroll, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {FallbackComponent} from '../fallback/fallback.component';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'ostso-search-select',
  standalone: true,
  imports: [
    MatSelectionList,
    MatListOption,
    ReactiveFormsModule,
    JsonPipe,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    FallbackComponent,
    MatIcon,
    NgStyle,
  ],
  templateUrl: './search-select.component.html',
  styleUrls: [
    '../../../modules/routing/register/dialogs/dialogForGroup/register-dialog.component.scss',
    './search-select.component.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectComponent),
      multi: true
    }
  ]
})
export class SearchSelectComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() placeholder: string = '';
  @Input() service: RemarkTypeService | null = null;
  @Input() beforeCaption: string = 'Выбранные балансодержатели';
  @ViewChild('selectedListRef') selectedListRef: ElementRef | null = null;

  public list: any[] = [];
  listControl: FormControl<any[] | null> = new FormControl(null);

  private _value: number[] = [];
  get value(): number[] {
    return this._value;
  }

  pageIndex$: BehaviorSubject<number> = new BehaviorSubject(1);
  pageIndex$$ = this.pageIndex$.pipe(
    debounceTime(1000),
    distinctUntilChanged()
  );

  pager: { pagesTotal: number, page: number, count: number, countTotal: number } | null = null;

  @Input() set value(value: number[]) {
    this._value = value;
    this.onChange(value);
  }

  searchInput: FormControl<string | null> = new FormControl('');

  changeData$ = combineLatest([this.pageIndex$$, this.searchInput.valueChanges]);

  selectedControl = new FormControl<Facility[] | null>(null);
  selectedList = new Set<Facility>();


  onChange = (value: number[]): void => {
  };
  onTouched = (): void => {
  };
  loaded: boolean = false;

  constructor(private renderer: Renderer2) {
  }

  writeValue(value: number[]): void {
    this.value = value;
    console.log('начальные значения', this.value);
    this.service && from(this.value)
      .pipe(mergeMap(v => this.service!.apiRemarktypeIdGet({id: v})))
      .subscribe(value => {
        this.selectedList.add(value);
      });
  }

  registerOnChange(fn: (value: number[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Implement if your component needs to handle the disabled state
  }

  handleInputChange(event: MatSelectionList): void {
    const value = event.selectedOptions.selected.map(item => item.value);
    this.value = value;
    this.onTouched();
  }

  ngOnInit(): void {
    this.searchInput.valueChanges.subscribe(() => {
      this.list = [];
      this.pageIndex$.next(1);
    });
    this.changeData$
      .pipe(tap(() => this.loaded = false))
      .pipe(
        switchMap(v => {
          const [pageIndex, searchString] = v;
          return this.service?.apiRemarktypeGet({
            pageIndex,
            pageSize: 10,
            searchString: searchString || undefined
          }) || of(null);
        }))
      .subscribe(value => {
        this.loaded = true;
        this.pager = value?.pager;
        console.log('pager', value?.pager);
        if (!value || !value?.values.length) return;
        value.values.forEach((value: Facility) => {
          this.list.push(value);
        });
      });

    this.searchInput.setValue('');

    this.selectedControl.valueChanges.subscribe(v => {
      this.selectedList.clear();
      v?.forEach(item => this.selectedList.add(item));
    });


  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.selectedListRef?.nativeElement && this.selectedListRef?.nativeElement.style.setProperty('--content',`'${this.beforeCaption}'`);

    }, );

  }

  doubleScroll: boolean = false;

  onScroll(): void {
    const viewport = document.querySelector('.ostso-search-select__viewport');
    if (!viewport || (viewport.scrollTop + viewport.clientHeight + 8) < viewport.scrollHeight) return;
    console.log('scroll', viewport.scrollTop);
    if ((this.pager?.page || 0) >= (this.pager?.pagesTotal || 0)) return;
    !this.doubleScroll && this.pageIndex$.next(this.pageIndex$.value + 1);
    this.doubleScroll = !this.doubleScroll;
  }

  remove(i: number): void {
    const item = (Array.from(this.selectedList))[i];
    this.selectedList.delete(item);
    this.value = [...this.selectedList].map(v => v.id);
  }

  add(idx: number): void {
    this.selectedList.add(this.list[idx]);
    this.value = [...this.selectedList].map(v => v.id);
    this.selectedListRef && (this.selectedListRef.nativeElement.scrollTop = this.selectedListRef.nativeElement.scrollHeight);
  }
}
