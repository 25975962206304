import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {JsonPipe, NgStyle} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {InstitutionsService} from '../../../../apiModule/api/institutions.service';
import {Institution} from '../../../../apiModule/model/institution';
import {FallbackComponent} from '../../../../../shared/components/fallback/fallback.component';



@Component({
  standalone: true,
  selector: 'ostso-institution-dialog',
  templateUrl: './register-dialog-institution.component.html',
  styleUrls: ['../dialogForGroup/register-dialog.component.scss','./register-dialog-institution.component.scss'],
    imports: [
        MatFormField,
        MatDialogClose,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        JsonPipe,
        MatAutocomplete,
        MatOption,
        ReactiveFormsModule,
        MatAutocompleteTrigger,
        MatSelect,
        NgStyle,
        FallbackComponent
    ]
})
export class RegisterDialogInstitutionEditComponent  {

  loaded: boolean = false;
  institution?: Institution;

  public readonly mapKeys: Record<string, string> = {
    ogrn: 'ОГРН',
    name: 'Наименование',
    shortName: 'Краткое наименование',
    managerPosition: 'Должность руководителя ',
    managerFio: 'ФИО',
    email: 'Email',
    contactsPhone: 'Телефон',
  };

  keys = Object.keys(this.mapKeys);

  getInstitutionsValues(key: string): string|number {
    if(!this.institution) return '';
    return (this.institution as unknown as Record<string, string | number>)[key];
  }

  constructor(
    public dialogRef: MatDialogRef<RegisterDialogInstitutionEditComponent>,
    @Inject(MAT_DIALOG_DATA) public gotData: Record<string, any>,
    public institutionsData: InstitutionsService,
  ) {
    institutionsData.apiRemarktypeIdGet({id:gotData['id']})
      .subscribe((v: Institution) => {
        this.institution = v;
        this.loaded = true;
      });
  }


  onClose(): void {
    this.dialogRef.close();
  }
}

