import {
  AfterViewInit,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule, Validators,
} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { RemarkTypeService} from '../../../../apiModule';
import {Group1Service} from '../../../../apiModule/api/group1.service';
import {JsonPipe, NgClass, NgStyle} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {SubgroupService} from '../../../../apiModule/api/subgroup.service';
import {map, Observable, toArray, forkJoin} from 'rxjs';
import {KindService} from '../../../../apiModule/api/kind.service';
import {MatSelect} from '@angular/material/select';
import {GroupEstimate} from '../../../../apiModule/model/groupEstimate';
import {SubgroupEstimate} from '../../../../apiModule/model/subgroupEstimate';
import {Kind} from '../../../../apiModule/model/kind';
import {RemarkType} from '../../../../apiModule/model/remarktype';
import {RemarktypeService} from '../../../../apiModule/api/remarktype.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton} from '@angular/material/button';
import {StatusFormComponent} from './status-form/status-form.component';
import {FallbackComponent} from '../../../../../shared/components/fallback/fallback.component';
import {ratePriorityDaysForFixItem} from '../dialogForPriority/register-dialog-priority.component';
import {IsEnabledDirective} from '../../../../../core/directives/is-enabled.directive';
import {AccessService} from '../../../../../core/services/access.service';
import {Router} from '@angular/router';


@Component({
  standalone: true,
  selector: 'ostso-remarktype-dialog',
  templateUrl: './register-dialog-remarktype-edit.component.html',
  styleUrls: [
    '../../../filters-modal/filters-modal.component.scss',
    '../dialogForGroup/register-dialog.component.scss',
    './register-dialog-remarktype-edit.component.scss'
  ],
  imports: [
    MatFormField,
    MatDialogClose,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    JsonPipe,
    MatAutocomplete,
    MatOption,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatSelect,
    MatIcon,
    MatIconButton,
    StatusFormComponent,
    FallbackComponent,
    MatButton,
    NgClass,
    NgStyle,
    IsEnabledDirective
  ]
})
export class RegisterDialogRemarktypeEditComponent implements OnInit, AfterViewInit {

  @ViewChild(StatusFormComponent, {static: false}) statusForm: StatusFormComponent | null = null;

  isNew: boolean = false;
  loaded: boolean = false;
  isEnabled: boolean = false;

  result: RemarkType = new RemarkType();

  get ratePriorityDaysForFix(): ratePriorityDaysForFixItem[] | undefined {
    return this.result.ratePriorityDaysForFix;
  }

  groups: GroupEstimate[] = [];
  subgroups: SubgroupEstimate[] = [];
  kinds: Kind[] = [];

  get selectedSubgroups(): SubgroupEstimate[] {
    if (!this.groupSelector.value) return this.subgroups;
    const res = this.subgroups.filter(subgroup => {
      const isEqual = subgroup.rateGroup?.id === Number(this.groupSelector.value?.id);
      return isEqual;
    });
    return res;
  }

  get selectedKinds(): Kind[] {
    if (!this.subgroupSelector.value) return this.kinds;
    const res = this.kinds.filter(kind => {
      const isEqual = kind.rateSubGroup?.id === Number(this.subgroupSelector.value?.id);
      return isEqual;
    });
    return res;
  }

  // get filteredSubgroupNames(): string[] {
  //   if (!this.groupSelector.value) return this.subgroupNames;
  //   return this.subgroups
  //     .filter(subgroup => subgroup['rateGroup']?.name === this.groupSelector.value)
  //     .map(subgroup => subgroup.name);
  // }


  get kindNames(): string[] {
    return this.kinds.map(kind => kind.name);
  }

  // get filteredKindNames(): string[] {
  //   if (!this.subgroupSelector.value) return this.kindNames;
  //   return this.kinds
  //     .filter(kind => kind['rateSubGroup']?.name === this.subgroupSelector.value)
  //     .map(kind => kind.name);
  // }

  groupSelector: FormControl<GroupEstimate | null> = new FormControl({} as GroupEstimate, [Validators.required]);
  subgroupSelector: FormControl<SubgroupEstimate | null> = new FormControl({} as SubgroupEstimate, [Validators.required]);
  kindSelector: FormControl<Kind | null> = new FormControl({} as Kind, [Validators.required]);
  significanceSelector: FormControl<number | null> = new FormControl(0, []);
  name: FormControl<string | null> = new FormControl('', [Validators.required]);
  shortName: FormControl<string | null> = new FormControl('');
  notHasRemarksSelector: FormControl<boolean | null> = new FormControl(null);

  constructor(
    public dialogRef: MatDialogRef<RegisterDialogRemarktypeEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, any>,
    private router: Router,
    public groupData: Group1Service,
    public subgroupData: SubgroupService,
    public kindData: KindService,
    public remarktypeService: RemarktypeService,
    public destroyRef: DestroyRef,
    public accessService: AccessService,
  ) {
    this.isNew = data['isNew'];
    this.accessService.guide$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(v => {
        this.isEnabled = v['remarktype'];
      });
    this.dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/remarktype']);
    });
  }

  get isValid(): boolean {
    return this.groupSelector.valid
      && this.subgroupSelector.valid
      && this.kindSelector.valid
      && this.name.valid
      && this.significanceSelector.valid;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      console.log('this.statusForm contentInit', this.statusForm);
      this.statusForm?.statusesControls.statusChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(console.log);
    });

  }

  fromTableToModel(row: any): RemarkType {
    const {id, name, shortName, significance, rateGroup, rateSubGroup, rateKind, notHasRemarks} = row;
    const remarkType: RemarkType = {
      id,
      name,
      shortName,
      significance,
      notHasRemarks,
      rateGroup: rateGroup ? {id: this.groups.find(group => group.name === rateGroup)?.id, name: rateGroup} : undefined,
      rateSubGroup: rateSubGroup ? {
        id: this.subgroups.find(subgroup => subgroup.name === rateSubGroup)?.id,
        name: rateSubGroup
      } : undefined,
      rateKind: rateKind ? {id: this.kinds.find(kind => kind.name === rateKind)?.id, name: rateKind} : undefined,
    };

    return remarkType;
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.isNew && this.router.navigate(['/remarktype']);
  }

  reset(): void {
    this.groupSelector.setValue({} as GroupEstimate);
    this.subgroupSelector.setValue({} as SubgroupEstimate);
    this.kindSelector.setValue({} as Kind);
    this.name.setValue('');
    this.shortName.setValue('');
    this.significanceSelector.setValue(0);
    this.notHasRemarksSelector.setValue(null);
    this.statusForm?.statusesControls.reset();
  }

  private setSelectors(): void {
    this.groupSelector.setValue(this.groups.find(group => group.name === this.data['rateGroup'])||null);
    this.subgroupSelector.setValue(this.subgroups.find(subgroup => subgroup.name === this.data['rateSubGroup'])||null);
    this.kindSelector.setValue(this.kinds.find(kind => kind.name ===  this.data['rateKind'])||null);
    this.name.setValue(this.data['name']);
    this.shortName.setValue(this.data['shortName']);
    this.significanceSelector.setValue(this.data['significance']);
    this.notHasRemarksSelector.setValue(this.data['notHasRemarks']);
  }

  connectSubscribers():void{
    this.groupSelector.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(groupValue => {
        this.result['rateGroup'] = this.groups.find(group => group.name === groupValue?.name);
      });
    this.subgroupSelector.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(subgroupValue => {
        this.result['rateSubGroup'] = this.subgroups.find(subgroup => subgroup.name === subgroupValue?.name);
        // const expectGroup = this.groups.find(group => group.id === subgroupValue?.rateGroup?.id);
        // if (!expectGroup) return;
        // subgroupValue?.rateGroup && this.groupSelector.setValue(expectGroup);
      });
    this.kindSelector.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(kindValue => {
        this.result['rateKind'] = this.kinds.find(kind => kind.name === kindValue?.name);
        // const expectSubGroup = this.subgroups.find(subgroup => subgroup.id === kindValue?.rateSubGroup?.id);
        // if (!expectSubGroup) return;
        // kindValue?.rateSubGroup && this.subgroupSelector.setValue(expectSubGroup);
      });
    this.name.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(v => {
        this.result['name'] = v || '';
      });
    this.shortName.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(v => {
        this.result['shortName'] = v || '';
      });
    this.significanceSelector.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(v => {
        this.result['significance'] = v || 0;
      });
    this.notHasRemarksSelector.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(v => {
        this.result['notHasRemarks'] = v || false;
      });
  }

  ngOnInit(): void {

    this.connectSubscribers();
    // this.result.ratePriorityDaysForFix. = data[];
    !this.isNew && this.remarktypeService.apiRemarktypeIdGet({id: this.data['id']})
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(v => {
        this.result = v;
        // this.groupSelector.setValue(v.rateGroup);

      });

    const groups$ = this.getAll(this.groupData);
    const subgroups$ = this.getAll(this.subgroupData);
    const kinds$ = this.getAll(this.kindData);

    forkJoin([groups$, subgroups$, kinds$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(v => {
        const [groups, subgroups, kinds] = v;
        this.groups = groups;
        this.subgroups = subgroups;
        this.kinds = kinds;
        // this.data = this.fromTableToModel(this.data);
        this.loaded = true;
        this.setSelectors();
      });


  }

  getAll(service: RemarkTypeService): Observable<any[]> {
    return service
      .apiRemarktypeGet({pageSize: 1e6, statusId: 1}, 'body', false, {transferCache: false})
      .pipe(
        map(v => v.values),
        toArray(),
        map(v => v.flat())
      );
  }

  // createFormControlsDependecies(): void {
  //   this.groupSelector.valueChanges
  //     .pipe(takeUntilDestroyed(this.destroyRef))
  //     .subscribe(group => {
  //     if (!group) return;
  //     const consistentSubGroup = this.subgroups.find(e => e.rateGroup?.name === group);
  //     if(!consistentSubGroup) return;
  //     const inGroup = consistentSubGroup?.rateGroup?.name === group;
  //     if (!inGroup || !this.subgroupSelector.value) this.subgroupSelector
  //       .setValue(consistentSubGroup?.name || '');
  //   });
  //
  //   this.subgroupSelector.valueChanges
  //     .pipe(takeUntilDestroyed(this.destroyRef))
  //     .subscribe(subgroup => {
  //     if (!subgroup) return;
  //     const subgroupModel = this.subgroups.find(e => e.name === subgroup);
  //     if(!subgroupModel) return;
  //     const exceptGroup = this.groups.find(e => e.name === subgroupModel.rateGroup?.name);
  //     if(!exceptGroup) return;
  //     const isGroupExcepted = this.groupSelector.value === subgroupModel.rateGroup?.name;
  //     if (!isGroupExcepted) {
  //       this.groupSelector.setValue(exceptGroup?.name || '', {emitEvent: false});
  //     }
  //
  //     const currentKind = this.kinds.find(kind => kind.name === this.kindSelector.value);
  //     const currentSubgroupInKind = this.subgroups.find(subgroup => subgroup.name === currentKind?.name);
  //     const isSubgroupInKind = currentSubgroupInKind?.name === subgroup;
  //     if (!isSubgroupInKind) {
  //       this.kindSelector.setValue(this.kinds.find(kind => kind.rateSubGroup?.name === subgroup)?.name || '');
  //     }
  //
  //   });
  //
  //   this.kindSelector.valueChanges
  //     .pipe(takeUntilDestroyed(this.destroyRef))
  //     .subscribe(kind => {
  //     if (!kind) return;
  //     const kindModel = this.kinds.find(el => el.name === kind);
  //     const exceptSubgroup = this.subgroups.find(subgroup => subgroup.name === kindModel?.rateSubGroup?.name);
  //     const isSubGroupExcepted = this.subgroupSelector.value === exceptSubgroup?.name;
  //     if (!isSubGroupExcepted) {
  //       this.subgroupSelector.setValue(exceptSubgroup?.name || '');
  //     }
  //   });
  // }



}

