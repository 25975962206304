<div class="camera">
  <video #video autoplay (canplay)="onCanPlay()" playsinline [attr.x-webkit-airplay]="'deny'"></video>
  <canvas #canvas></canvas>
  <div class="camera__actions">
    <mat-form-field class="toggler" >
      <mat-label>Камера</mat-label>
      <mat-select [formControl]="select" (change)="startVideo()">
        @for (device of gotDevices(); track device.deviceId) {
          <mat-option [value]="device.deviceId">{{ namedCamera(device.label) }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <!--  <button class="camera__action camera__switch" (click)="changeVideo()" [disabled]="gotDevices().length<=1">-->
    <!--    <mat-icon>change_circle</mat-icon>-->
    <!--  </button>-->
    <button class="camera__action cemera__capture" (click)="capture()">
      <mat-icon>photo_camera</mat-icon>
    </button>
  </div>
</div>

