import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, signal} from '@angular/core';
import {JsonPipe} from '@angular/common';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {ObjectURLPipe} from '../../../../core/pipes/object-url.pipe';
import {RateCardFilesService} from '../../../../modules/apiModule/api/rate-card-files.service';
import {RatecardFileTypesService} from '../../../../core/services/ratecard-file-types.service';
import {fileMimeTypes} from '../../../../core/services/blob-to-file.service';

@Component({
  selector: 'ostso-slide',
  standalone: true,
  imports: [
    JsonPipe,
    MatProgressSpinner,
    ObjectURLPipe
  ],
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss','../slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideComponent implements OnInit{
@Input() item!: RateFile & {data?: File|Blob};
data: Blob|null = null;

get fileType():string|undefined{
  const ext = this.item.name.split('.').pop();
  if(!ext) return;
  const res = fileMimeTypes[ext.toLowerCase()];
  return res;
}

constructor(
  private fileService:RateCardFilesService,
  private cdr: ChangeDetectorRef){}

ngOnInit():void {
  if(this.item.id){
  this.fileService.apiRatecardFileIdGet(
    {id: this.item.id},
    'body',
    false,
    {httpHeaderAccept: 'image/*', context: undefined, transferCache: false}
  ).subscribe(v=>{
    console.log(v);
    this.data =v;
    this.cdr.markForCheck();
  });

  } else if(this.item.data){
    this.data = this.item.data;
  }

}

  send():void {
    if(!this.data) return;
    this.fileService.apiRatecardFileCardIdTypeTypeIdPost({
      typeId: this.item.type.id,
      cardId: 44,
      file: this.data
    }).subscribe(v=>{
      console.log('apiRatecardFileCardIdTypeTypeIdPost',v);
    });
  }
}
