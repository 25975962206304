<h2 mat-dialog-title>Перемещение в архив</h2>

<mat-dialog-content>
  <button mat-icon-button class="close-button" (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>

  <mat-form-field appearance="outline">
    <input matInput [matDatepicker]="picker" [formControl]="dateControl" placeholder="Дата">
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <textarea class="remarktype-textarea" matInput [formControl]="comment" (input)="onInput($event)"
            placeholder="Причина"></textarea>
  <div class="error">{{error()}}</div>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="btn btn-blue" mat-button (click)="onConfirm()" cdkFocusInitial>В архив</button>
  <button class="btn btn-secondary" mat-button (click)="onClose()">Отмена</button>
</mat-dialog-actions>

