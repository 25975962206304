import {DestroyRef, Injectable} from '@angular/core';
import {RateCardFilesService} from '../../modules/apiModule/api/rate-card-files.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class RatecardFileTypesService {

  types: RateFileType[] = [];

  constructor(private fileService: RateCardFilesService, private destroyRef: DestroyRef) {
    this.fileService.apiRatecardFileTypesGet()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((v: {values:RateFileType[]})=>this.types = v.values);
  }

  getTypeByCode(code: CodeRateFile):RateFileType|undefined {
    if (!this.types.length) return;
    return this.types.find(x => x.code === code);
  }
}
