import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi
} from '@angular/common/http';
import {ApplicationConfig, InjectionToken, makeEnvironmentProviders} from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  provideMomentDateAdapter
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { PreloadAllModules, provideRouter, withPreloading, withRouterConfig } from '@angular/router';
import { environment } from '../environments/environment';
import { Configuration, ConfigurationParameters } from './modules/apiModule';

import { routes } from './modules/routing/app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {BasicAuthInterceptor} from './core/interceptors/basic-auth.interceptor';
import {ErrorCatchingInterceptor} from './core/interceptors/error-catching.interceptor';
import {returnURLInterceptor} from './core/interceptors/return-url.interceptor';
import {TypeOfEstimation} from './shared/components/side-bar/type-of-estimation';
import {CustomDateAdapter} from './custom-date-adapter';
import {provideDialogConfig} from '@ngneat/dialog';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // TODO: token
    // credentials: { Bearer: () => `Bearer <token>` },
    basePath: environment.api_url
  };
  return new Configuration(params);
}

export const USERKEY = new InjectionToken<string>('user-key');
export const RETURN_URL = new InjectionToken<string>('return-url');

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'DD.MM.YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'DD.MM.YYYY',
  },
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withPreloading(PreloadAllModules),
      withRouterConfig({paramsInheritanceStrategy: 'always'})),
    provideClientHydration(),
    provideAnimationsAsync(),
    makeEnvironmentProviders(
      [{provide: Configuration, useValue: apiConfigFactory()}]
    ),
    provideHttpClient(
      withFetch(),
      withInterceptors([returnURLInterceptor]),
      withInterceptorsFromDi()
    ),
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: USERKEY, useValue: 'auth-user' },
    { provide: RETURN_URL, useValue: 'return-url' },
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorCatchingInterceptor, multi: true },
    { provide: TypeOfEstimation },
    provideDialogConfig({
      sizes: {
        sm: {
          width: 300, // 300px
          minHeight: 250, // 250px
        },
        md: {
          width: '60vw',
          height: '60vh',
        },
        lg: {
          width: '90vw',
          height: '90svh',
        },
        fullScreen: {
          width: '100vw',
          height: '100vh',
        },
      },
    }),
    provideMomentDateAdapter(MY_FORMATS)
  ]
};
