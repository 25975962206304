import {Injectable} from '@angular/core';
import {ITableColumn} from '../common/interfaces/table-column.interface';
import {StorageService} from './storage.service';
import {RegisterDataTableService} from './register-data-table.service';


@Injectable({
  providedIn: 'root'
})
export class RegisterInstitutionsDataTableService extends RegisterDataTableService {


  constructor(
    storageService: StorageService
  ) {
    super(storageService);
  }


  override getColumns(): ITableColumn[] {
    return [
      {
        name: 'id',
        title: 'Id',
        default: true,
        useCustomTemplate: false,
      },
      {
        name: 'iasId',
        title: 'ID ИАС Спорт',
        default: true,
        useCustomTemplate: true,
      },
      {
        name: 'name',
        title: 'Наименование',
        isRequired: true,
        default: true,
        useCustomTemplate: true,
      },

      {
        name: 'ogrn',
        title: 'ОГРН',
        default: true,
        useCustomTemplate: false,
      },
      {
        name: 'chief',
        title: 'Руководитель',
        default: true,
        useCustomTemplate: false,
        disabled: true,
      },
      {
        name: 'contact',
        title: 'Контакт',
        default: false,
        useCustomTemplate: true,
        isRequired: false
      },
      {
        name: 'email',
        title: 'email',
        default: false,
        useCustomTemplate: false,
      },
      {
        name: 'status',
        title: 'Архив',
        useCustomTemplate: true,
        disabled: false,
        default: false
      },
    ];
  }


}
