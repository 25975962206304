/**
 * АПИ Бэка
 * АПИ службы сообщений.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent, HttpParameterCodec, HttpContext
} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {ApiRatecardPostRequest} from '../model/api-ratecard-post-request';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';
import {ApiRatecardSetstatusIdPutRequest} from '../model/api-ratecard-setstatus-id-put-request';


export interface ApiRatecardGetRequestParams {
  firmIds?: Array<number>;
  facilityIds?: Array<number>;
  facilityAddress?: string[]|string;
  facilityTypeIds?: Array<number>;
  inspectorIds?: Array<number>;
  operatorIds?: Array<number>;
  dateFrom?: string;
  dateTo?: string;
  rateGroupIds?: Array<number>;
  rateSubGroupIds?: Array<number>;
  rateKindIds?: Array<number>;
  remarkTypeIds?: Array<number>;
  daysInWorkFrom?: number;
  daysInWorkTo?: number;
  priorityIds?: Array<number>;
  statusId?: number;
  searchString?: string;
  sortColumnName?: string;
  sortAscending?: boolean;
  pageIndex?: number;
  pageSize?: number;
  inspector?: Array<number>;
  InspectorIds?: string[];
  operator?: Array<number>;
  OperatorIds?: string[];
  comment?: string;
  note?: string;
  archiveId?: string;
  statusesIds?: number[],
  ids?: number[];
  isLongspanStructureObject?: boolean|null;
  isInvestmentObject?: boolean|null;
}

export interface ApiRatecardIdGetRequestParams {
  id: number;
}

export interface ApiRatecardIdPutRequestParams {
  id: number;
  apiRatecardPostRequest?: ApiRatecardPostRequest;
}

export interface ApiRatecardPostRequestParams {
  apiRatecardPostRequest?: ApiRatecardPostRequest;
}

export interface ApiRatecardSetstatusIdPutRequestParams {
  id: number;
  apiRatecardSetstatusIdPutRequest?: ApiRatecardSetstatusIdPutRequest;
}

export interface ApiRatecardSetarchiveIdPutRequestParams {
  id: number;
  apiRatecardSetstatusIdPutRequest?: ApiRatecardSetstatusIdPutRequest;
}

export interface ApiRatecardSendemailIdGetRequestParams {
  id: number;
}


@Injectable({
  providedIn: 'root'
})
export class RateCardService {

  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string | string[], @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === "object" && !(value instanceof Date)) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
          httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRatecardGet(requestParameters: ApiRatecardGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiRatecardGet(requestParameters: ApiRatecardGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiRatecardGet(requestParameters: ApiRatecardGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiRatecardGet(requestParameters: ApiRatecardGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {
    const firmIds = requestParameters.firmIds;
    const facilityIds = requestParameters.facilityIds;
    const facilityTypeIds = requestParameters.facilityTypeIds;
    const inspectorIds = requestParameters.inspector;
    const operatorIds = requestParameters.operator;
    const dateFrom = requestParameters.dateFrom;
    const dateTo = requestParameters.dateTo;
    const rateGroupIds = requestParameters.rateGroupIds;
    const rateSubGroupIds = requestParameters.rateSubGroupIds;
    const rateKindIds = requestParameters.rateKindIds;
    const remarkTypeIds = requestParameters.remarkTypeIds;
    const daysInWorkFrom = requestParameters.daysInWorkFrom;
    const daysInWorkTo = requestParameters.daysInWorkTo;
    const priorityIds = requestParameters.priorityIds;
    const statusId = requestParameters.statusId;
    const searchString = requestParameters.searchString;
    const sortColumnName = requestParameters.sortColumnName;
    const sortAscending = requestParameters.sortAscending;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;
    const comment: string | undefined = requestParameters.comment;
    const note: string | undefined = requestParameters.note;
    const archiveId: string | undefined = requestParameters.archiveId;
    const statusesIds: number[] | undefined = requestParameters.statusesIds;
    const facilityAddress: string[] | string| undefined = requestParameters.facilityAddress;
    const ids: number[]|undefined = requestParameters.ids;
    const isInvestmentObject: boolean | null | undefined = requestParameters.isInvestmentObject;
    const isLongspanStructureObject: boolean | null | undefined = requestParameters.isLongspanStructureObject;
    const OperatorIds: string[] | undefined = requestParameters.OperatorIds;
    const InspectorIds: string[] | undefined = requestParameters.InspectorIds;


    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (InspectorIds && InspectorIds.length) {
      InspectorIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'InspectorIds');
      })
    }
    if (OperatorIds && OperatorIds.length) {
      OperatorIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'OperatorIds');
      })
    }
    if (isLongspanStructureObject !== undefined) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'isLongspanStructureObject');
    }
    if (isInvestmentObject !== undefined) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'isInvestmentObject');
    }
    if (ids && ids.length) {
      ids.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'ids');
      })
    }
    if (statusesIds) {
      statusesIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'statusesIds');
      })
    }
    if (firmIds) {
      firmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (facilityIds) {
      facilityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityIds');
      })
    }
    if (facilityAddress !== undefined && facilityAddress !== null) {
      if(Array.isArray(facilityAddress)){
        facilityAddress.forEach((element:string) => {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>element, 'facilityAddress');
        })
      }else{
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>facilityAddress, 'FacilityAddress');
      }
    }
    if (facilityTypeIds) {
      facilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })
    }
    if (inspectorIds) {
      inspectorIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'InspectorIds');
      })
    }
    if (operatorIds) {
      operatorIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'OperatorIds');
      })
    }
    if (dateFrom !== undefined && dateFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateFrom, 'DateFrom');
    }
    if (dateTo !== undefined && dateTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>dateTo, 'DateTo');
    }
    if (rateGroupIds) {
      rateGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RateGroupIds');
      })
    }
    if (rateSubGroupIds) {
      rateSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RateSubGroupIds');
      })
    }
    if (rateKindIds) {
      rateKindIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RateKindIds');
      })
    }
    if (remarkTypeIds) {
      remarkTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkTypeIds');
      })
    }
    if (daysInWorkFrom !== undefined && daysInWorkFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>daysInWorkFrom, 'DaysInWorkFrom');
    }
    if (daysInWorkTo !== undefined && daysInWorkTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>daysInWorkTo, 'DaysInWorkTo');
    }
    if (priorityIds) {
      priorityIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'PriorityIds');
      })
    }
    if (statusId !== undefined && statusId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>statusId, 'statusId');
    }
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>searchString, 'SearchString');
    }
    if (sortColumnName !== undefined && sortColumnName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>sortColumnName, 'Sort.ColumnName');
    }
    if (sortAscending !== undefined && sortAscending !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>sortAscending, 'Sort.Ascending');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }
    if (comment !== undefined && comment !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>comment, 'comment');
    }
    if (note !== undefined && note !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>note, 'note');
    }
    if (archiveId !== undefined && archiveId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>archiveId, 'archiveId');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/ratecard`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRatecardIdGet(requestParameters: ApiRatecardIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiRatecardIdGet(requestParameters: ApiRatecardIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiRatecardIdGet(requestParameters: ApiRatecardIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiRatecardIdGet(requestParameters: ApiRatecardIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {
    const id = requestParameters.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiRatecardIdGet.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/ratecard/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int32"
    })}`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRatecardIdPut(requestParameters: ApiRatecardIdPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiRatecardIdPut(requestParameters: ApiRatecardIdPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiRatecardIdPut(requestParameters: ApiRatecardIdPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiRatecardIdPut(requestParameters: ApiRatecardIdPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {
    const id = requestParameters.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiRatecardIdPut.');
    }
    const apiRatecardPostRequest = requestParameters.apiRatecardPostRequest;

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/ratecard/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int32"
    })}`;
    return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: apiRatecardPostRequest,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRatecardPost(requestParameters: ApiRatecardPostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiRatecardPost(requestParameters: ApiRatecardPostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiRatecardPost(requestParameters: ApiRatecardPostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiRatecardPost(requestParameters: ApiRatecardPostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {
    const apiRatecardPostRequest = requestParameters.apiRatecardPostRequest;

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/ratecard`;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: apiRatecardPostRequest,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }



  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRatecardSetstatusIdPut(requestParameters: ApiRatecardSetstatusIdPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiRatecardSetstatusIdPut(requestParameters: ApiRatecardSetstatusIdPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiRatecardSetstatusIdPut(requestParameters: ApiRatecardSetstatusIdPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiRatecardSetstatusIdPut(requestParameters: ApiRatecardSetstatusIdPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {
    const id = requestParameters.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiRatecardSetstatusIdPut.');
    }
    const apiRatecardSetstatusIdPutRequest = requestParameters.apiRatecardSetstatusIdPutRequest;

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/ratecard/setstatus/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int32"
    })}`;
    return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: apiRatecardSetstatusIdPutRequest,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRatecardSetarchiveIdPut(requestParameters: ApiRatecardSetarchiveIdPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiRatecardSetarchiveIdPut(requestParameters: ApiRatecardSetarchiveIdPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiRatecardSetarchiveIdPut(requestParameters: ApiRatecardSetarchiveIdPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiRatecardSetarchiveIdPut(requestParameters: ApiRatecardSetarchiveIdPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {
    const id = requestParameters.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiRatecardSetarchiveIdPut.');
    }
    const apiRatecardSetstatusIdPutRequest = requestParameters.apiRatecardSetstatusIdPutRequest;

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/ratecard/setarchive/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int32"
    })}`;
    return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: apiRatecardSetstatusIdPutRequest,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRatecardSendemailIdGet(requestParameters: ApiRatecardSendemailIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiRatecardSendemailIdGet(requestParameters: ApiRatecardSendemailIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiRatecardSendemailIdGet(requestParameters: ApiRatecardSendemailIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiRatecardSendemailIdGet(requestParameters: ApiRatecardSendemailIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const id = requestParameters.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiRatecardSendemailIdGet.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/ratecard/sendemail/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }



}
