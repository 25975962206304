import {
  ComponentRef,
  Directive,
  ElementRef, EventEmitter,
  Input, Output,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
  OnChanges
} from '@angular/core';
import {
  AccessDeniedMessageComponent
} from '../../shared/components/access-denied-message/access-denied-message.component';


@Directive({
  selector: '[ostsoIsEnabled]',
  standalone: true
})
export class IsEnabledDirective implements OnChanges {
  @Input('ostsoIsEnabled') disablePointerEvents: boolean = false;
  @Output() closeEvent = new EventEmitter();
  accessDeniedMessage: ComponentRef<AccessDeniedMessageComponent> | null = null;

  constructor(private el: ElementRef, private renderer: Renderer2, public vcf: ViewContainerRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['disablePointerEvents']) return;
    if (!this.disablePointerEvents) {
      this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.5');
      this.accessDeniedMessage = this.vcf.createComponent(AccessDeniedMessageComponent);
      this.accessDeniedMessage.instance.closeEvent.subscribe(() => {
        this.closeEvent.emit();
      });
      this.accessDeniedMessage.changeDetectorRef.detectChanges();
    } else {
      this.renderer.removeStyle(this.el.nativeElement, 'pointer-events');
      this.renderer.removeStyle(this.el.nativeElement, 'opacity');
      this.accessDeniedMessage && this.accessDeniedMessage.destroy();
      this.vcf.clear();

    }
  }

}
