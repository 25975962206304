import {Component, DestroyRef, AfterViewInit} from '@angular/core';
import {provideNativeDateAdapter} from '@angular/material/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {map, startWith} from 'rxjs/operators';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialog} from '@angular/material/dialog';
import {SideBarComponent} from '../../../shared/components/side-bar/side-bar.component';
import {SideBarDoubleComponent} from '../../../shared/components/side-bar-double/side-bar-double.component';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {TableActionDirective} from '../../../shared/components/table/table-action.directive';
import {TableCellDirective} from '../../../shared/components/table/table-cell.directive';
import {TableComponent} from '../../../shared/components/table/table.component';
import {MatTable} from '@angular/material/table';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute} from '@angular/router';
import {DataTableService} from '../../../core/services/data-table.service';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, registerables, ChartType, ChartOptions } from 'chart.js';
import {RegisterHeaderComponent} from '../register/register-header/register-header.component';
import {DashboardModal} from '../dashboard-modal/dashboard-modal.component';




import {PageEvent} from '@angular/material/paginator';

Chart.register(...registerables);

@Component({
    selector: 'dashboard',
    standalone: true,
    providers: [
        provideNativeDateAdapter(),
    ],
    imports: [
        MatSelectModule,
        MatSidenavModule,
        MatIconButton,
        MatIcon,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        AsyncPipe,
        CommonModule,
        MatDatepickerModule,
        SideBarComponent,
        SideBarDoubleComponent,
        MatMenu,
        TableActionDirective,
        TableCellDirective,
        TableComponent,
        MatTable,
        MatMenuTrigger,
        BaseChartDirective,
        RegisterHeaderComponent,
    ],
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss', '../../../shared/components/table/table.component.scss', '../register/register.component.scss'],
})

export class DashboardComponent implements AfterViewInit  {

    public opened = true;
    mobileOpen = false;

    list = new BehaviorSubject<void>(undefined);

    updateList = new BehaviorSubject<void>(undefined);

    activeTab: string = 'objects';

        constructor(
        private route: ActivatedRoute,
        private destroyRef: DestroyRef,
        private dataTableService: DataTableService,
        private dialog: MatDialog,
    ) {
        this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({group}) => {
            this.dataTableService.setData(group);
        });
    }
    

    onPageChange(pager: PageEvent): void {

      }

      onSortedColumn($event: any): void {

      }
   

    showMoreInfo(): void {
      this.dialog.open(DashboardModal, {
        width: 'max(320px,50vw)',       
    });
    }

    setActiveTab(tab: string): void {
      this.activeTab = tab;
    }

    ngAfterViewInit(): void {
        const linechart = document.getElementById('lineChart') as HTMLCanvasElement;    
        new Chart(linechart, {
          type: 'line',
          data: {
            labels: [
              'янв.24', 'фев.24', 'мар.24', 'апр.24', 'май.24',
              'июн.24', 'июл.24', 'авг.24', 'сен.24', 'окт.24',
              'ноя.24', 'дек.24'
            ],
            datasets: [
              {
                label: 'Выявлено',
                data: [1500, 2500, 3000, 7000, 2000, 5000, 8000, 4000, 6000, 2000, 1000, 7500],
                borderColor: 'red',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: false,
              },
              {
                label: 'Устранено',
                data: [1000, 2000, 1000, 6000, 4000, 3000, 7000, 5000, 3000, 1000, 2000, 4000],
                borderColor: 'gray',
                backgroundColor: 'rgba(201, 203, 207, 0.2)',
                fill: false,
              }
            ]
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: true,
                position: 'top',
                labels: {
                    boxWidth: 25,         
                    boxHeight: 25,      
                    padding: 20,
                },                
              },
              title: {
                text: 'Количество замечаний',
                display: true,
                font: {
                    size: 20,
                    weight: 'bold',                    
                  },
                  align: 'start',
                
              }
            },
            layout: {
              padding: {
                top: 20
              }
            },
            scales: {
              x: {
                grid: {
                  color: 'white',
                }
              },
              y: {
                beginAtZero: true,
                max: 8000,
                ticks: {
                  stepSize: 1000
                },
                grid: {
                  color: 'white',
                }
              }
            }
          }
        });

        

        

      }

}


