import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectURL',
  standalone: true
})
export class ObjectURLPipe implements PipeTransform {

  transform(value:any): string {
    if(!value) return '';
    const data = value?.base64 ? value.base64 : value;
    const obj = URL.createObjectURL(data);
    setTimeout(()=>URL.revokeObjectURL(obj), 0);
    return obj;
  }

}
