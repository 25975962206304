import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  model,
  signal,
} from '@angular/core';
import {ToastsService} from '../../../../core/services/toasts.service';
import {RateCardService} from '../../../apiModule/api/rate-card.service';

@Component({
  selector: 'ostso-send-mail',
  standalone: true,
  imports: [],
  templateUrl: './send-mail.component.html',
  styleUrl: './send-mail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendMailComponent {

  enableEmailSend = input<boolean>(false);
  emailSent = model<boolean>(false);
  currentId = input<number|undefined>(undefined);
  sendingMail = signal(false);

  private toasts = inject(ToastsService);
  remarkCardService = inject(RateCardService);

  sendMail(): void {
    if(!this.enableEmailSend()) return this.toasts.addToast({
      text: 'У данного Учреждения отстутсвует электронная почта',
      type: 'error'
    });
    // if (!this.currentId || this.emailSent()) return this.toasts.addToast({
    //   text: 'Письмо уже было отправлено',
    //   type: 'check'
    // });
    this.sendingMail.set(true);
    this.remarkCardService
      .apiRatecardSendemailIdGet({id: this.currentId()!})
      .subscribe({
        next: () => {
          this.toasts.addToast({text: 'Письмо отправлено', type: 'check'});
          this.emailSent.set(true);
          this.sendingMail.set(false);
        },
        error: err => {
          this.toasts.addToast({text: 'Ошибка отправки письма!' + err.message, type: 'error'});
          this.sendingMail.set(false);
        }
      });
  }

  emailColor = computed(() => {
    let color = '#e22b36';
    if(this.emailSent()) color = '#57BC33';
    if(!this.emailSent()) color ='#e22b36';
    if(!this.enableEmailSend()) color +='80';
    return color;
  });
}

