import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommunicationService } from '../services/communication.service';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

    excludeRequests: string[] = [];

    constructor(private readonly communicationService: CommunicationService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const needMessage = request.method === 'POST' || request.method === 'PUT' || request.method === 'DELETE' || request.method === 'PATCH';
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse): Observable<HttpEvent<any>> => {
                if (this.excludeRequests.find(value => request.url.includes(value))) {
                    throw error;
                } else {
                    let errorMsg = '';

                    if (error.status !==  401 && needMessage) {
                        if (error.error instanceof ErrorEvent) {
                            errorMsg = `Error: ${error.error.message}`;
                        } else {
                            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                        }

                        if (errorMsg) {
                            this.communicationService.publish('Ошибка связи с сервером', { type: 'error' });
                        }

                    }

                    throw error;
                }
            }));
    }
}
