import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'first3',
  standalone: true
})
export class First3Pipe implements PipeTransform {

  transform(value: string): string {
    return value.slice(0,3).toUpperCase();
  }

}
