import { Injectable } from '@angular/core';
import {AccountControllerService} from '../../modules/apiModule/api/account-controller.service';
import {BehaviorSubject, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OperatorsService {

  operators$ = new BehaviorSubject<User[]>([]);
  constructor(private accountController: AccountControllerService) {
    this.fetch();
  }

  fetch(): void {
    this.accountController.apiAccountGet({roles:['operator','admin'],pageSize: 1e5}).subscribe(v=>{
      console.log('operators',v);
      this.operators$.next(v.values);
    });
  }

  getOperators$(): Observable<User[]> {
    return of(this.operators$.value.map(v=>({...v, name: v.userName})));
  }
}
