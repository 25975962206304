import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {Group1Service} from '../../../apiModule/api/group1.service';
import {SubgroupService} from '../../../apiModule/api/subgroup.service';
import {map} from 'rxjs/operators';
import {SubgroupEstimate} from '../../../apiModule/model/subgroupEstimate';

export const subgroupEstimateResolver: ResolveFn<any> = (
  route,
  state,
  subgroupService: SubgroupService = inject(SubgroupService)
) => {
  return subgroupService
    .apiRemarktypeGet({}, 'body', false, {transferCache: false})
    .pipe(map(res => ({
      pager: res.pager,
      values: res.values.map((item: {
        id: number,
        name: string,
        shortName: string,
        rateGroup: Item,
        status: StatusRemarkType
      }) => new SubgroupEstimate(item.id, item.name, item.shortName, item.rateGroup, item.status))
    })));
};
