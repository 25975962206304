import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {JsonPipe, NgStyle} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {FacilityService} from '../../../../apiModule/api/facility.service';
import {IFacility, IFacilitySimplified} from '../../../../apiModule/model/facility';
import {FallbackComponent} from '../../../../../shared/components/fallback/fallback.component';


@Component({
  standalone: true,
  selector: 'ostso-facility-dialog',
  templateUrl: './register-dialog-facility.component.html',
  styleUrls: [
    '../dialogForGroup/register-dialog.component.scss',
    '../dialogForInstitution/register-dialog-institution.component.scss',
    './register-dialog-facility.component.scss'
  ],
    imports: [
        MatFormField,
        MatDialogClose,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        JsonPipe,
        MatAutocomplete,
        MatOption,
        ReactiveFormsModule,
        MatAutocompleteTrigger,
        MatSelect,
        NgStyle,
        FallbackComponent
    ]
})
export class RegisterDialogFacilityEditComponent {

  loaded: boolean = false;
  facility?: IFacilitySimplified;

  public readonly mapKeys: Record<string, string> = {
    balanceHolder: 'Балансодержатель',
    name: 'Наименование',
    shortName: 'Краткое наименование',
    type: 'Тип',
    district: 'Округ',
    region: 'Район',
    address: 'Адрес',
    cadastralNumber: 'Кадастровый номер',
    egipRegistryNumber: 'Реестровый номер',
    startDate: 'Дата начала',
  };

  keys = Object.keys(this.mapKeys);

  getFacilityValues(key: string): string|number {
    if(!this.facility) return '';
    return (this.facility as unknown as Record<string, string | number>)[key];
  }


  constructor(
    public dialogRef: MatDialogRef<RegisterDialogFacilityEditComponent>,
    @Inject(MAT_DIALOG_DATA) public gotData: Record<string, any>,
    public facilityData: FacilityService,
  ) {
    facilityData.apiRemarktypeIdGet({id: gotData['id']})
      .subscribe((v: IFacility) => {
        this.facility = this.simplifyFacility(v);
        this.loaded = true;
      });
  }

  simplifyFacility({
                     id,
                     iasId,
                     balanceHolder,
                     name,
                     shortName,
                     type,
                     district,
                     region,
                     address,
                     cadastralNumber,
                     startDate,
                     egipRegistryNumber
                   }: IFacility): IFacilitySimplified {
    return {
      id,
      iasId,
      balanceHolder: balanceHolder && balanceHolder.name,
      name,
      shortName,
      type: type && type.name,
      district: district && district.name,
      region: region && region.name,
      address,
      cadastralNumber,
      startDate,
      egipRegistryNumber,
    };
  }


  onClose(): void {
    this.dialogRef.close();
  }
}

