import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {BehaviorSubject} from 'rxjs';

export type guideKeys = 'group' | 'subgroup' | 'kind' | 'remarktype'

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  private _role: UserRoles[] = [];


  guide$: BehaviorSubject<Record<guideKeys, boolean>> = new BehaviorSubject({
    group: false,
    subgroup: false,
    kind: false,
    remarktype: false,
  } as Record<guideKeys, boolean>);
  guide = this.guide$.asObservable();

  newButton$: BehaviorSubject<Record<guideKeys | 'ratecard', boolean>> = new BehaviorSubject({
    group: false,
    subgroup: false,
    kind: false,
    remarktype: false,
    ratecard: false,
  } as Record<guideKeys | 'ratecard', boolean>);
  newButton = this.newButton$.asObservable();

  list$: BehaviorSubject<Record<UserListRightsKeys, boolean>> = new BehaviorSubject({
    registry_archive: false,
    registry_copylink: false,
    registry_create: false,
    registry_open: false,
    registry_reset: false,
  } as Record<UserListRightsKeys, boolean>);

  constructor(private userService: UserService) {
    this.role = this.userService.user$.value.userRoles;
    this.userService.user$.subscribe(v => {
      console.log('rights', v);
      this.newButton$.next({
        group: v.rights?.edit['rategroup'] || false,
        subgroup: v.rights?.edit['ratesubgroup'] || false,
        kind: v.rights?.edit['ratekind'] || false,
        remarktype: v.rights?.edit['remarktype'] || false,
        ratecard: v.rights?.list['registry_create'] || false,
      });
      this.guide$.next({
        group: v.rights?.edit['rategroup'] || false,
        subgroup: v.rights?.edit['ratesubgroup'] || false,
        kind: v.rights?.edit['ratekind'] || false,
        remarktype: v.rights?.edit['remarktype'] || false,
      });
      this.list$.next({
        ...v.rights.list
      });
    });
  }


  get role(): UserRoles[] {
    return this._role;
  }

  set role(value: UserRoles[]) {
    this._role = value;
  }




}
