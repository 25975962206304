<button mat-icon-button class="close-button" (click)="onNoClick()">
  <mat-icon>close</mat-icon>
</button>
<div class="ostso-dialog ">
  @if (!loaded) {
    <ostso-fallback/>
  }
  <h1>Фильтр</h1>
  <form [formGroup]="form" class="remarktype-form">
    <mat-select class="remarktype-select" formControlName="group" placeholder="Группа" multiple>
      @for (group of groups; track group) {
        <mat-option [value]="group">{{ group.name }}</mat-option>
      }
    </mat-select>
    <mat-select class="remarktype-select" formControlName="subgroup" placeholder="Подгруппа" multiple>
      @for (subgroup of filteredSubgroups; track subgroup) {
        <mat-option [value]="subgroup">{{ subgroup.name }}</mat-option>
      }
    </mat-select>
    <mat-select class="remarktype-select" formControlName="kind" placeholder="Вид" multiple>
      @for (kind of filteredKinds; track kind) {
        <mat-option [value]="kind">{{ kind.name }}</mat-option>
      }
    </mat-select>

    <mat-select class="remarktype-select" formControlName="remarktype" placeholder="Замечания" multiple>
      @for (remarktype of filteredRemarktypes; track remarktype) {
        <mat-option [value]="remarktype">{{ remarktype.name }}</mat-option>
      }
    </mat-select>

    <label for="notHasRemarkInput">
      <input id="notHasRemarkInput"  formControlName="notHasRemarks" type="checkbox">
      Без замечаний
    </label>

    <h4>Стоимость</h4>
    <form class="cost-form" [formGroupName]="'cost'">
      <div class="percent-input">
        <input class="remarktype-input" matInput [formControlName]="'from'" type="number" max="100" min="0"
               placeholder="от">
        <span>%</span>
      </div>
      <div class="percent-input">
        <input class="remarktype-input" matInput [formControlName]="'to'" type="number" max="100" min="0"
               placeholder="до">
        <span>%</span>
      </div>
    </form>

    <mat-select class="remarktype-select" formControlName="archive" placeholder="Архив" >
        <mat-option value="0">--- не выбрано ---</mat-option>
        <mat-option value="1">Активен</mat-option>
        <mat-option value="2">В архиве</mat-option>
    </mat-select>

  </form>
  <div mat-dialog-actions>
    <button [ngClass]="['btn', form.status==='VALID' ? 'btn-blue': 'btn-gray']" [ngStyle]="{color:form.status==='VALID' ? '#fff' : '#777'}"
            mat-button [mat-dialog-close]="form.value" cdkFocusInitial [disabled]="form.status!=='VALID'">Применить
    </button>
    <button class="btn btn-secondary" mat-button (click)="onReset()">Сбросить</button>
  </div>

</div>

