import {Component, ElementRef, EventEmitter, Output, signal, ViewChild} from '@angular/core';
import {NgClass} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {Camera2Component} from '../camera2/camera2.component';

@Component({
  selector: 'ostso-files-downloader',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './files-downloader.component.html',
  styleUrl: './files-downloader.component.scss'
})
export class FilesDownloaderComponent {
  @ViewChild('downloadphoto') downloadphoto: ElementRef | null = null;
  @ViewChild('files') fileInput: ElementRef | null = null;
  @Output() filesDownloaded = new EventEmitter();
  dragOver = signal(false);
  // images: FileType[] = [];

  constructor(public dialog: MatDialog) {}

  downloadFiles(): void {
    if (!this.fileInput || !this.fileInput.nativeElement.files) return;
    [...this.fileInput.nativeElement.files].forEach((file: File) => this.addItems(file));
  }

  addItems(file: File): void {
    console.log(file);
    this.filesDownloaded.emit(file);
  }

  openCamera(): void {
    const dialogRef = this.dialog.open(Camera2Component, {
      maxWidth: '768px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      // const newItem = {
      //   type: 'image',
      //   base64: result,
      //   name: 'new-snapshot.png',
      // };
      const newItem = new File([result],'new-snapshot.png', {type: 'image/png'});
      // result && (this.images = [...this.images, newItem]);
      result && this.filesDownloaded.emit(newItem);
    });
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver.set(true);
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver.set(false);
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver.set(false);

    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      const fileArray: File[] = Array.from(files);
      fileArray.forEach((file: File) => this.addItems(file));
      // fileArray.forEach((file: File) => {
      //   let type = 'doc';
      //   if (file.type.includes('image')) {
      //     type = 'image';
      //   }
      //   const reader = new FileReader();
      //
      //   reader.onload = (e) => {
      //     const dataURL = e.target && e.target.result as string;
      //     console.log('Data URL:', dataURL);
      //     // dataURL && (this.images = [...this.images, {type, base64: dataURL, name: file.name}]);
      //     dataURL && this.filesDownloaded.emit({type, base64: dataURL, name: file.name});
      //   };
      //
      //   reader.onerror = (e) => {
      //     console.error('Ошибка чтения файла', e);
      //   };
      //
      //   reader.readAsDataURL(file);
      // });
    }
  }
}
