import {Component, DestroyRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormField} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {
  AvailablePriority,
  ratePriorityDaysForFixItem
} from '../../dialogForPriority/register-dialog-priority.component';
import {JsonPipe, NgClass} from '@angular/common';
import {RatePriorityService} from '../../../../../apiModule/api/rate-priority.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SetUndefinedWhen0Directive} from '../../../../../../core/directives/set-undefined-when0.directive';

export type keyStatus = 'low' | 'average' | 'high' | 'critical';
export type FromTo = {
  from: number | null;
  to: number | null;
}
export type ratePriorityDaysForFixForm = Partial<{
  low: Partial<FromTo>;
  average: Partial<FromTo>;
  high: Partial<FromTo>;
  critical: Partial<FromTo>;
}>

@Component({
  selector: 'ostso-status-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    JsonPipe,
    NgClass,
    SetUndefinedWhen0Directive
  ],
  templateUrl: './status-form.component.html',
  styleUrl: './status-form.component.scss'
})
export class StatusFormComponent implements OnInit {
  @Input() set statusSource(arr: ratePriorityDaysForFixItem[] | undefined) {
    Object.keys(this.statusesControls.value).forEach(key => {
      const statusItem = arr?.find(x => x.ratePriority?.code === key);

      const fg = this.statusesControls.get(key);
      const from = fg && fg.get('from');
      const to = fg && fg.get('to');

      from && from.setValue(statusItem?.daysFrom,{emitEvent: false});
      to && to.setValue(statusItem?.daysTo,{emitEvent: false});
    });
  }

  @Output() statusSourceChange = new EventEmitter<ratePriorityDaysForFixItem[]>();

  statusMap: AvailablePriority[] = [];
  public readonly names: Record<string, string> = {
    low: 'Низкий',
    average: 'Средний',
    high: 'Высокий',
    critical: 'Критический'
  };
  public readonly keys = Object.keys(this.names);

  public readonly MAX = 355;


  public statusesControls = this.fb.group({
    low: this.fb.group({
      from: this.fb.control(0, [Validators.min(0), Validators.max(this.MAX)]),
      to: this.fb.control(0, [Validators.min(0), Validators.max(this.MAX)]),
    }),
    average: this.fb.group({
      from: this.fb.control(0, [Validators.min(0), Validators.max(this.MAX)]),
      to: this.fb.control(0, [Validators.min(0), Validators.max(this.MAX)]),
    }),
    high: this.fb.group({
      from: this.fb.control(0, [Validators.min(0), Validators.max(this.MAX)]),
      to: this.fb.control(0, [Validators.min(0), Validators.max(this.MAX)]),
    }),
    critical: this.fb.group({
      from: this.fb.control(0, [Validators.min(0), Validators.max(this.MAX)]),
      to: this.fb.control(0, [Validators.min(0), Validators.max(this.MAX)]),
    }),
  });


  constructor(
    public fb: FormBuilder,
    public ratePriorityService: RatePriorityService,
    private destroyRef: DestroyRef
  ) {
    this.ratePriorityService.apiRatepriorityGet('body')
      .subscribe(res => {
        this.statusMap = res.values;
      });
  }

  private reverseConvert(v: ratePriorityDaysForFixForm): ratePriorityDaysForFixItem[] {

    const canSkipLowItem = !(v.low?.to && v.low?.from);
    const lowItem: ratePriorityDaysForFixItem = {
      daysTo: v.low?.to || null,
      daysFrom: v.low?.from || null,
      ratePriority: this.statusMap.find(e => e.code === 'low') || null
    };

    const canSkipAverageItem = !(v.average?.to && v.average?.from);
    const averageItem: ratePriorityDaysForFixItem = {
      daysTo: v.average?.to || null,
      daysFrom: v.average?.from || null,
      ratePriority: this.statusMap.find(e => e.code === 'average') || null
    };

    const canSkipHighItem = !(v.high?.to && v.high?.from);
    const highItem: ratePriorityDaysForFixItem = {
      daysTo: v.high?.to || null,
      daysFrom: v.high?.from || null,
      ratePriority: this.statusMap.find(e => e.code === 'high') || null
    };

    const canSkipCriticalItem = !(v.critical?.to && v.critical?.from);
    const criticalItem: ratePriorityDaysForFixItem = {
      daysTo: v.critical?.to || null,
      daysFrom: v.critical?.from || null,
      ratePriority: this.statusMap.find(e => e.code === 'critical') || null
    };

    return [
      canSkipLowItem ? null : lowItem,
      canSkipAverageItem ? null : averageItem,
      canSkipHighItem ? null : highItem,
      canSkipCriticalItem ? null : criticalItem
    ].filter(Boolean) as ratePriorityDaysForFixItem[];
  }

  ngOnInit(): void {

    this.statusesControls.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(v => {
        if (!v) return;
        const res = this.reverseConvert(v);
        this.statusSourceChange.emit(res);
      });

    const linkControls=(prioritetName: keyStatus):void=>{
      this.statusesControls.get(prioritetName)!.get('from')!.valueChanges.subscribe(v => {
        const to = this.statusesControls.get(prioritetName)!.get('to')!.value;
        if(!v) return;
        if ( v >= (to || 1)) this.statusesControls.get(prioritetName)!.get('to')!.setValue(v);
      });
      this.statusesControls.get(prioritetName)!.get('to')!.valueChanges.subscribe(v => {
        const from = this.statusesControls.get(prioritetName)!.get('from')!.value;
        if(!v) return;
        if (v < (from || 1)) this.statusesControls.get(prioritetName)!.get('from')!.setValue(v);
      });
      console.log({prioritetName});
    };

    setTimeout(()=>Object.keys(this.statusesControls.value).forEach(e=>linkControls(e as  keyStatus)));

  }


  protected readonly Object = Object;


}
