import { Injectable } from '@angular/core';
import {StorageService} from './storage.service';
import {FilterType} from './filter-type';



@Injectable({
  providedIn: 'root'
})
export class FiltersStoreService {

  constructor(private storage: StorageService) { }

  set(type: FilterType, value: FilterFormData):void {
    this.storage.setItem(type, JSON.stringify(value));
  }

  get(type: FilterType): FilterFormData|undefined {
    const res = this.storage.getItem(type);
    if(!res) return;
    return JSON.parse(res) as FilterFormData;
  }

  remove(type: FilterType): void {
    this.storage.removeItem(type);
  }

}
