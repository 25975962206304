export class Institution {
  id: number | undefined;
  iasId: number | undefined;
  ogrn: string | null = null;
  name: string | undefined;
  shortName: string | null = null;
  managerPosition: string | null = null
  managerFio: string | null = null
  email: string | null = null
  contactPhone: string | null = null
  status: StatusRemarkType = {} as StatusRemarkType
  constructor(
    id?: number,
    iasId?: number,
    ogrn = null,
    name = '',
    shortName = '',
    managerPosition = null,
    managerFio = null,
    email = null,
    contactPhone = null,
    status: StatusRemarkType = {} as StatusRemarkType
  ) {
    this.id = id;
    this.iasId = iasId;
    this.ogrn = ogrn;
    this.name = name;
    this.shortName = shortName;
    this.managerPosition = managerPosition;
    this.managerFio = managerFio;
    this.email = email;
    this.contactPhone = contactPhone;
  }
}

