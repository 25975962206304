import {ResolveFn} from '@angular/router';
import {GroupService} from '../../../apiModule/api/group.service';
import {inject} from '@angular/core';
import {Group1Service} from '../../../apiModule/api/group1.service';
import {map} from 'rxjs/operators';
import {SubgroupEstimate} from '../../../apiModule/model/subgroupEstimate';
import {GroupEstimate} from '../../../apiModule/model/groupEstimate';

export const groupEstimateResolver: ResolveFn<any> = (route, state, groupService: Group1Service = inject(Group1Service)) => {
  return groupService
    .apiRemarktypeGet({}, 'body', false, {transferCache: false})
    .pipe(map(res => ({
      pager: res.pager,
      values: res.values.map((item: {
        id: number,
        name: string,
        shortName: string,
        status: StatusRemarkType
      }) => new GroupEstimate(item.id, item.name, item.shortName, item.status))
    })));
};
