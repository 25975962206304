import {Component, DestroyRef, Inject, OnInit, signal} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';

import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule, Validators,
} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {JsonPipe} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {CustomDateAdapter} from '../../../../../custom-date-adapter';
import {combineLatest} from 'rxjs';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

@Component({
  standalone: true,
  selector: 'ostso-delete-dialog',
  templateUrl: './register-dialog-delete.component.html',
  styleUrls: ['../dialogForGroup/register-dialog.component.scss','./register-dialog-delete.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter}, // Используйте ваш пользовательский адаптер
    {provide: MAT_DATE_LOCALE, useValue: 'ru'}, // Установите нужную локаль
  ],
  imports: [
    MatFormField,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    JsonPipe,
    MatAutocomplete,
    MatOption,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatSelect,
      MatInputModule,
      MatButtonModule,
      MatDialogTitle,
      MatDialogContent,
      MatDialogActions,
      MatDialogClose,
  MatIconButton,
  MatIcon,
  MatDatepickerModule
  ]
})
export class RegisterDialogDeleteComponent implements OnInit {

  result: { id: number, statusId: number, comment: string, date: string} = {id: -1, statusId: 2, comment: '', date:''};

  comment = new FormControl('',{validators: [Validators.required],});

  dateControl = new FormControl(moment(), {validators: [Validators.required],});

  error = signal<string|undefined>('');


  constructor(
    public dialogRef: MatDialogRef<RegisterDialogDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, any>,
    public destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.result.id = this.data['id'];
    combineLatest([this.comment.valueChanges, this.dateControl.valueChanges])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([comment, date]) => {
      this.result.comment = comment||'';
      const formatDate = date ? date.format('MM.DD.YYYY'): '';
      const timeZone = new Date().getTimezoneOffset() * 60 * 1000;
      const parseDate = new Date(new Date(formatDate).getTime() - timeZone).toISOString();
      this.result.date = parseDate;
    });

    this.dateControl.valueChanges.subscribe((value) => {
      console.log(value);
    });
  }


  onInput($event: Event): void{
    const value = ($event.target as HTMLInputElement).value;
    this.comment.setValue(value);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    let res = '';
    if(!this.dateControl.value && !this.comment.value) res = 'Укажите дату и введите комментарий';
    if(!this.dateControl.value && this.comment.value) res = 'Вы забыли указать дату';
    if(this.dateControl.value && !this.comment.value) res = 'Вы забыли ввести коментарий';
    if(res) return this.error.set(res);
    this.comment.value && (this.result.comment = this.comment.value);
    this.dateControl.value && (this.result.date = this.dateControl.value.format('YYYY-MM-DDTHH:mm:ss'));
    this.dialogRef.close(this.result);
  }


}

