<button mat-icon-button class="close-button" (click)="onNoClick()">
  <mat-icon>close</mat-icon>
</button>
<div class="ostso-dialog ">
  @if (!loaded) {
    <ostso-fallback/>
  }
  <h1>Фильтр</h1>
  <form [formGroup]="formFacilities" class="remarktype-form">
    <!--    <ostso-search-select [formControlName]="'firmIds'" placeholder="Балансодержатель" multiple [service]="firmService">-->
    <!--    </ostso-search-select>-->

    <ostso-multi-select [formControlName]="'firmIds'" [data]="firmIdsList" placeholder="Балансодержатель">
      <ng-template let-item="item">
        {{ item.name }}
      </ng-template>
    </ostso-multi-select>

    <mat-accordion class="remarktype-select">
      <mat-expansion-panel [(expanded)]="expanded0"
                           [ngStyle]="{
                           'background-color': expanded0? 'white' : 'transparent',
                           'box-shadow': expanded0? '': 'none',
                           'padding': expanded0? '': '0',
                           }">
        <mat-expansion-panel-header>
          <mat-panel-description>
            Данные объекта
          </mat-panel-description>
        </mat-expansion-panel-header>
        <input type="text " class="remarktype-textarea" [formControlName]="'IasId'" placeholder="ID ИАС СПОРТ" ostsoMaskNumber>
        <input type="text " class="remarktype-textarea" [formControlName]="'Name'" placeholder="Наименование">
        <mat-select class="remarktype-select" [formControlName]="'facilityTypeIds'" placeholder="Тип" multiple>
          @for (type of facilityTypeIdsList; track type) {
          <mat-option [value]="type.id">{{ type.name }}</mat-option>
          }
        </mat-select>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-select class="remarktype-select"
                [formControlName]="'isInvestmentObject'"
                placeholder="Объект инвестстроительства">
      <mat-option [value]="null">Не выбрано</mat-option>
      <mat-option [value]="true">Да</mat-option>
      <mat-option [value]="false">Нет</mat-option>
    </mat-select>
    <mat-select class="remarktype-select"
                [formControlName]="'isLongspanStructureObject'"
                placeholder="Объект БПК">
      <mat-option [value]="null">Не выбрано</mat-option>
      <mat-option [value]="true">Да</mat-option>
      <mat-option [value]="false">Нет</mat-option>
    </mat-select>

    <mat-select class="remarktype-select"
                [formControlName]="'isInspectionRequired'"
                placeholder="Подлежит проверке">
      <mat-option [value]="null">Не выбрано</mat-option>
      <mat-option [value]="true">Да</mat-option>
      <mat-option [value]="false">Нет</mat-option>
    </mat-select>

    <mat-accordion class="remarktype-select">
      <mat-expansion-panel [(expanded)]="expanded1"
                           [ngStyle]="{
                           'background-color': expanded1? 'white' : 'transparent',
                           'box-shadow': expanded1? '': 'none',
                           'padding': expanded1? '': '0',
                           }">
        <mat-expansion-panel-header>
          <mat-panel-description>
            Адресные данные
          </mat-panel-description>
        </mat-expansion-panel-header>
        <input type="text " class="remarktype-textarea" [formControlName]="'Address'" placeholder="Адрес">
        <mat-select class="remarktype-select" [formControlName]="'DistrictIds'" placeholder="Округ" multiple>
          @for (district of districtTypeIdsList; track district) {
          <mat-option [value]="district.id">{{ district.name }}</mat-option>
          }
        </mat-select>
        <mat-select class="remarktype-select" [formControlName]="'RegionIds'" placeholder="Регион" multiple>
          @for (region of dependedRegions; track region) {
          <mat-option [value]="region.id">{{ region.name }}</mat-option>
          }
        </mat-select>
        <input type="text " class="remarktype-textarea" [formControlName]="'CadastralNumber'"
               placeholder="Кадастровый номер" ostsoMaskNumber>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-select class="remarktype-select" [formControlName]="'StatusId'" placeholder="Архив">
      @for (status of statuses; track status) {
      <mat-option [value]="status.id">{{ status.name }}</mat-option>
      }
    </mat-select>


  </form>
  <div mat-dialog-actions>
    <button [ngClass]="['btn', form.status==='VALID' ? 'btn-blue': 'btn-gray']"
            [ngStyle]="{color:form.status==='VALID' ? '#fff' : '#777'}"
            mat-button [mat-dialog-close]="formFacilities.value" cdkFocusInitial [disabled]="form.status!=='VALID'">
      Применить
    </button>
    <button class="btn btn-secondary" mat-button (click)="onFacilitiesReset()">Сбросить</button>
  </div>

</div>

