<div class="ostso-dialog">
  <div mat-dialog-content>
    <mat-form-field>
      <input matInput [(ngModel)]="data.name">
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button class="btn btn-secondary" mat-button (click)="onNoClick()">Отменить</button>
    <button class="btn btn-blue" mat-button [mat-dialog-close]="data" cdkFocusInitial>Сохранить</button>
  </div>
</div>
