import {AfterViewInit, Component, DestroyRef, inject, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {JsonPipe, NgClass, NgStyle} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton} from '@angular/material/button';
import {FallbackComponent} from '../../../../../shared/components/fallback/fallback.component';
import {FilterRemarktypeComponent} from '../remarktype/filter-remarktype.component';
import {ReferenceService} from '../../../../apiModule/api/reference.service';
import {FacilityService} from '../../../../apiModule/api/facility.service';
import {Group1Service} from '../../../../apiModule/api/group1.service';
import {SubgroupService} from '../../../../apiModule/api/subgroup.service';
import {KindService} from '../../../../apiModule/api/kind.service';
import {
  MatAccordion,
  MatExpansionModule,
  MatExpansionPanel,
  MatExpansionPanelDescription
} from '@angular/material/expansion';
import {DistrictService} from '../../../../apiModule/api/district.service';
import {RegionService} from '../../../../apiModule/api/region.service';
import {InstitutionsService} from '../../../../apiModule/api/institutions.service';
import {SearchSelectComponent} from '../../../../../shared/components/search-select/search-select.component';
import {MaskNumberDirective} from '../../../../../core/directives/mask-number.directive';
import {FacilityTypeService} from '../../../../apiModule/api/facility-type.service';
import {MultiSelectComponent} from '../../../../../shared/components/multi-select/multi-select.component';
import {FilterType} from '../../../../../core/services/filter-type';


@Component({
  standalone: true,
  selector: 'ostso-institutions-filter',
  templateUrl: './filter-facilities.component.html',
  styleUrls: [
    '../../dialogs/dialogForGroup/register-dialog.component.scss',
    './filter-facilities.component.scss'
  ],
    imports: [
        MatFormField,
        MatDialogClose,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        JsonPipe,
        MatAutocomplete,
        MatOption,
        ReactiveFormsModule,
        MatAutocompleteTrigger,
        MatSelect,
        MatIcon,
        MatIconButton,
        FallbackComponent,
        MatButton,
        NgClass,
        NgStyle,
        MatExpansionModule,
        MatExpansionPanelDescription,
        MatExpansionPanel,
        MatAccordion,
        SearchSelectComponent,
        MaskNumberDirective,
        MultiSelectComponent
    ]
})
export class FilterFacilitiesComponent extends FilterRemarktypeComponent implements AfterViewInit {

  formFacilities = this.fb.group({
    firmIds: new FormControl(this.data && this.data['firmIds'] || []),
    IasId: new FormControl(this.data && this.data['IasId'] || null),
    Name: new FormControl(this.data && this.data['Name'] || null),
    facilityTypeIds: new FormControl(this.data && this.data['FacilityTypeIds'] || []),
    Address: new FormControl(this.data && this.data['Address'] || null),
    isInvestmentObject: new FormControl(this.data && this.data['isInvestmentObject'] || null),
    isLongspanStructureObject: new FormControl(this.data && this.data['isLongspanStructureObject'] || null),
    isInspectionRequired: new FormControl(this.data && this.data['isInspectionRequired'] || null),
    DistrictIds: new FormControl(this.data && this.data['DistrictIds'] && this.data['DistrictIds']['id'] || null),
    RegionIds: new FormControl(this.data && this.data['RegionIds'] && this.data['RegionIds']['id'] || null),
    CadastralNumber: new FormControl(this.data && this.data['CadastralNumber'] || null),
    StatusId: new FormControl(this.data && this.data['StatusId'] && this.data['StatusId']['id'] || null) as FormControl<StatusRemarkType>,
  });

  statusService = inject(ReferenceService);
  statuses: StatusRemarkType[] = [];
  firmIdsList: Named[] = [];
  facilityTypeIdsList = [
    {id: 1, name: '1'},
    {id: 2, name: '2'},
  ];
  districtTypeIdsList = [
    {id: 1, name: '1'},
    {id: 2, name: '2'},
  ];
  regionTypeIdsList = [
    {
      id: 1, name: '1', district: {
        id: 2,
        name: 'Юго-Западный административный округ'
      }
    },
    {
      id: 2, name: '2', district: {
        id: 2,
        name: 'Юго-Западный административный округ'
      }
    },
  ];

  panelOpenState = false;
  expanded0 = false;
  expanded1 = false;

  private districtService = inject(DistrictService);
  private regionService = inject(RegionService);
  public firmService = inject(InstitutionsService);

  private isStatusServiceLoading  = false;
  private isDistrictServiceLoading = false;
  private isRegionServiceLoading  = false;
  private isFirmServiceLoading  = false;
  private isFacilityTypeLoading= false;

  override typeOfFilter: FilteredObjectName = 'facility';

  override get loaded():boolean{
    return this.isStatusServiceLoading
      && this.isDistrictServiceLoading
      && this.isRegionServiceLoading
      && this.isFirmServiceLoading
      && this.isFacilityTypeLoading;
  }

  get dependedRegions(): { id: number, name: string }[] {
    const currentDistrictIds = this.formFacilities.get('DistrictIds')?.value;
    if (!currentDistrictIds) return this.regionTypeIdsList;
    const arr = this.regionTypeIdsList.filter(v => v.district.id === currentDistrictIds);
    return arr.length ? arr : this.regionTypeIdsList;
  }

  facilityTypeService  = inject(FacilityTypeService);
  constructor(fb: FormBuilder,
              dialogRef: MatDialogRef<FilterRemarktypeComponent>,
              @Inject(MAT_DIALOG_DATA) data: Record<string, any>,
              groupData: Group1Service,
              subgroupData: SubgroupService,
              kindData: KindService,
              remarktypeService: FacilityService,
              destroyRef: DestroyRef) {
    super(fb, dialogRef, data, groupData, subgroupData, kindData, remarktypeService, destroyRef);
    this.formFacilities.get('IasId')!.valueChanges.subscribe((value: string) => {
      const newValue = value.replace(/[^0-9]/g, '');
      this.formFacilities.get('IasId')!.setValue(newValue);
    });
    this.statusService.apiReferenceStatusesGet('body').subscribe((value: any) => {
      this.statuses = value.values;
      this.isStatusServiceLoading = true;
    });
    this.districtService.apiDistrictGet({pageSize: 1e5, usedOnly: true}).subscribe((value: any) => {
      this.districtTypeIdsList = value.values;
      this.isDistrictServiceLoading  = true;
    });
    this.regionService.apiRegionGet({pageSize: 1e5, usedOnly: true}).subscribe((value: any) => {
      this.regionTypeIdsList = value.values;
      this.isRegionServiceLoading = true;
    });
    this.firmService.apiRemarktypeGet({pageSize: 1e5, usedOnly: true}).subscribe((value: any) => {
      this.firmIdsList = value.values;
      this.isFirmServiceLoading = true;
    });
    this.facilityTypeService.apiFacilitytypeGet({pageSize: 1e5, usedOnly: true}).subscribe((value: any) => {
      this.facilityTypeIdsList = value.values;
      this.isFacilityTypeLoading = true;
    });

    this.formFacilities.get('firmIds')!.valueChanges.subscribe((value: string)  =>  {
      console.log('FirmId changed',value);
    });

    // this.fillFormControlsData();
    this.formFacilities.valueChanges.subscribe(v=>{
      console.log('formFacilities',v);
    });
  }

  // fillFormControlsData():void{
  //   if(!this.data) return;
  //   const firmIds = this.data && this.data['firmIds'];
  //   this.formFacilities.get('firmIds')!.setValue(firmIds||[]);
  //   this.formFacilities.get('DistrictIds')!.setValue(this.data['DistrictIds']||[]);
  //   this.formFacilities.get('RegionIds')!.setValue(this.data['RegionIds']||[]);
  // }

onFacilitiesReset(): void {
    this.formFacilities.reset();
}
}

