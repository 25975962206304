<div class="toast" matSnackBarLabel>
  <div class="toast__container">
    @if (data && data.type === 'info') {
      <mat-icon style="color: blue;">info</mat-icon>
    }
    @if (data && data.type === 'error') {
      <mat-icon style="color: red;">error</mat-icon>
    }
    @if (data && data.type === 'success') {
      <mat-icon style="color: green;">check</mat-icon>
    }
    <span>{{ data && data.text }}</span>
  </div>
</div>
