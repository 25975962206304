import {Type} from '@angular/core';
import {GroupEstimateComponent} from '../../../modules/routing/group-estimate/group-estimate.component';

export class TypeOfEstimation {
  public readonly list: readonly {name:string, href:string, component: Type<any>, icon: string}[]= [
    { name: 'группы замечаний', href: 'group', component: GroupEstimateComponent, icon: 'groupSVG'},
    { name: 'подгруппы замечаний', href: 'subgroup', component: GroupEstimateComponent, icon: 'subgroupSVG'},
    { name: 'виды замечаний', href: 'kind', component: GroupEstimateComponent, icon: 'kindSVG'},
    { name: 'замечания', href: 'remarktype', component: GroupEstimateComponent, icon: 'remarktypeSVG'},
  ] as const;
}
