import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {StorageService} from './storage.service';
import {catchError, Observable, of, tap} from 'rxjs';
import {RETURN_URL, USERKEY} from '../../app.config';
import {UserService} from './user.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  URL = environment.api_url;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private userService: UserService,
    @Inject(USERKEY) private userkey: string,
    @Inject(RETURN_URL) private returnURL: string,
  ) {
  }


  private getJWToken(token: string): string {
    return token.replace('Bearer ', '');
  }

  private parseJwt(token: string): object {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  login(username: string, password: string): Observable<{ token: string }> {
    return this.http.post<{ token: string, user: User }>(this.URL + '/api/account/login', {username, password})
      .pipe(
        tap(({token, user}: { token: string, user: User }) => {
          this.storage.setItem(this.userkey, this.getJWToken(token));
          this.userService.user$.next(user);
        }),
        catchError(err => {
          console.error(err);
          return of(err);
        })
      );

  }

  logout(): void {
    this.storage.removeItem(this.userkey);
    // this.storage.removeItem(this.returnURL);
  }

  get isLoggedIn(): boolean {
    return !!this.storage.getItem(this.userkey) && !this.expired;
  }

  get token(): string | undefined | null {
    return this.storage.getItem(this.userkey);
  }

  get expired(): boolean {
    if (!this.token) {
      return true;
    }
    const parsedToken = this.parseJwt(this.token);
    if ('exp' in parsedToken) {
      return +(parsedToken.exp as string) < Math.floor(Date.now() / 1000);
    }
    return true;
  }

}
