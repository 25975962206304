import {RemarkType} from '../model/remarktype';
import {Kind} from '../model/kind';
import {SubgroupEstimate} from '../model/subgroupEstimate';
import {Institution} from '../model/institution';

export function isRemarkType(value: any): value is RemarkType {
  const rateKind: boolean = 'rateKind' in value && value['rateKind']!==undefined
  const rateSubGroup: boolean = 'rateSubGroup' in value && value['rateSubGroup']!==undefined
  const rateGroup: boolean = 'rateGroup' in value && value['rateGroup']!==undefined
  return rateKind && rateSubGroup && rateGroup;

}

export function isKindType(value: any): value is Kind {
  const rateKind: boolean = !('rateKind' in value) || value['rateKind']===undefined
  const rateSubGroup: boolean = 'rateSubGroup' in value && value['rateSubGroup']!==undefined
  const rateGroup: boolean = 'rateGroup' in value && value['rateGroup']!==undefined
  return rateKind && rateSubGroup && rateGroup;

}

export function isSubGroupType(value: any): value is SubgroupEstimate {
  const rateKind: boolean = !('rateKind' in value) || value['rateKind']===undefined
  const rateSubGroup: boolean = !('rateSubGroup' in value) || value['rateSubGroup']===undefined
  const rateGroup: boolean = 'rateGroup' in value && value['rateGroup']!==undefined
  return rateKind && rateSubGroup && rateGroup;

}
export function isGroupType(value: any): value is SubgroupEstimate {
  const rateKind: boolean = !('rateKind' in value) || value['rateKind']===undefined
  const rateSubGroup: boolean = !('rateSubGroup' in value) || value['rateSubGroup']===undefined
  const rateGroup: boolean = !('rateGroup' in value)

  return rateKind && rateSubGroup && rateGroup && !isInstitutionType(value)  && !isFacilityTableType(value);

}

export function isInstitutionType(value: any): value is Institution {

  const emptyIntitution = new Institution()
  return Object.keys(emptyIntitution).every(key => key in value);

}
export function isInstitutionTableType(value: any): value is Institution {
  const fields = ['chief', 'contact', 'iasId', 'id', 'ogrn','shortName']
  return fields.every(key => key in value);
}

export function isFacilityTableType(value: any): value is Institution {
  const fields = ['iasId', 'balanceHolder', 'type', 'address']
  return fields.every(key => key in value);
}
