import {Component, DestroyRef} from '@angular/core';
import {provideNativeDateAdapter} from '@angular/material/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {map, startWith} from 'rxjs/operators';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialog} from '@angular/material/dialog';
import {SideBarComponent} from '../../../shared/components/side-bar/side-bar.component';
import {SideBarDoubleComponent} from '../../../shared/components/side-bar-double/side-bar-double.component';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {TableActionDirective} from '../../../shared/components/table/table-action.directive';
import {TableCellDirective} from '../../../shared/components/table/table-cell.directive';
import {TableComponent} from '../../../shared/components/table/table.component';
import {MatTable} from '@angular/material/table';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute} from '@angular/router';
import {DataTableService} from '../../../core/services/data-table.service';
import { BaseChartDirective } from 'ng2-charts';
import { Chart, registerables, ChartType, ChartOptions } from 'chart.js';
import {RegisterHeaderComponent} from '../register/register-header/register-header.component';



import {PageEvent} from '@angular/material/paginator';

Chart.register(...registerables);

@Component({
    selector: 'rating',
    standalone: true,
    providers: [
        provideNativeDateAdapter(),
    ],
    imports: [
        MatSelectModule,
        MatSidenavModule,
        MatIconButton,
        MatIcon,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        AsyncPipe,
        CommonModule,
        MatDatepickerModule,
        SideBarComponent,
        SideBarDoubleComponent,
        MatMenu,
        TableActionDirective,
        TableCellDirective,
        TableComponent,
        MatTable,
        MatMenuTrigger,
        BaseChartDirective,
        RegisterHeaderComponent,
    ],
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss', '../../../shared/components/table/table.component.scss', '../register/register.component.scss'],
})

export class RatingComponent {

    public opened = true;
    mobileOpen = false;

    list = new BehaviorSubject<void>(undefined);

    updateList = new BehaviorSubject<void>(undefined);


 
    constructor(
        private route: ActivatedRoute,
        private destroyRef: DestroyRef,
        private dataTableService: DataTableService,
        private dialog: MatDialog,
    ) {
        this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({group}) => {
            this.dataTableService.setData(group);
        });
    }
    

    onPageChange(pager: PageEvent): void {

      }

      onSortedColumn($event: any): void {

      }
   

    openDialog(data: any): void {
    }
}


