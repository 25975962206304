import {inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastsService {
  toasts = new BehaviorSubject<Toast[]>([]);
  toasts$ = this.toasts.asObservable();
  private initDuration = 2000;

  private zone = inject(NgZone);

  addToast(toast: Toast): void {
    this.toasts.next([...this.toasts.value, toast]);
    const duration = toast.duration || this.initDuration;

    setTimeout(() => {
      this.removeToast(toast);
    }, duration);

  }

  removeToast(toast: Toast): void {
    this.toasts.next(this.toasts.value.filter(e => {
      const equalText = e.text !== toast.text;
      const equalType = e.type !== toast.type;
      return equalText || equalType;
    }));
  }
}
