import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AuthService} from '../../../core/services/auth.service';
import {Router} from '@angular/router';
import {StorageService} from '../../../core/services/storage.service';
import {RETURN_URL} from '../../../app.config';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {CommunicationService} from '../../../core/services/communication.service';
import {Subscription} from 'rxjs';
import {NgClass} from '@angular/common';
import {UserService} from '../../../core/services/user.service';


@Component({
  selector: 'ostso-login',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatCheckboxModule, ReactiveFormsModule, NgClass],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  isLoading = false;

  form: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    modePass: [false],
  });

  constructor(
    private auth: AuthService,
    private user: UserService,
    private router: Router,
    private storage: StorageService,
    public fb: FormBuilder,
    private commutator: CommunicationService,
    @Inject(RETURN_URL) private returnURL: string) {
  }

  login(): void {
    this.isLoading = true;
    const connect = this.auth.login(this.form.get('email')!.value, this.form.get('password')!.value)
      .subscribe({
        next: async (v) => {
          await this.router.navigate([this.storage.getItem(this.returnURL) || '/ratecard']);
          this.isLoading = false;
        },
        error: (err) => this.commutator.publish(err),
      }
    );
    this.subscription.add(connect);
  }

  ngOnInit(): void {
    if (this.router.url.includes('/logout')) {
      return this.auth.logout();
    }
    this.user.isEmpty && this.user.fetchUser();
    this.auth.isLoggedIn && !this.user.isEmpty && this.router.navigate([this.storage.getItem(this.returnURL) || '/ratecard']);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
