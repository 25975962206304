import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';

export interface GroupEstimateDialogData {
  id: string;
  name: string;
}

@Component({
  standalone: true,
  selector: 'ostso-group-estimate-dialog',
  templateUrl: './group-estimate-dialog.component.html',
  styleUrls: ['./group-estimate-dialog.component.scss'],
  imports: [
    MatFormField,
    MatDialogClose,
    FormsModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class GroupEstimateDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<GroupEstimateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GroupEstimateDialogData) {
    console.log('GroupEstimateDialogComponent', data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

