import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {map} from 'rxjs/operators';
import {InstitutionsService} from '../../../apiModule/api/institutions.service';
import {Institution} from '../../../apiModule/model/institution';

export const institutionsResolver: ResolveFn<any> = (
  route,
  state,
  institutionsService: InstitutionsService = inject(InstitutionsService)
) => {
  return institutionsService
    .apiRemarktypeGet({}, 'body', false, {transferCache: false})
    .pipe(map(res => ({
      pager: res.pager,
      values: res.values.map((item: any) => new Institution(
        item.id,
        item.iasId,
        item.ogrn,
        item.name,
        item.shortName,
        item.managerPosition,
        item.managerFio,
        item.email,
        item.contactPhone,
        item.status
      ))
    })));
};

