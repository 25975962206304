<nav>
   <a routerLink="/logout" class="username">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_0_176" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_0_176)">
      <path d="M20.15 13H8V11H20.15L18.6 9.45L20 8L24 12L20 16L18.6 14.55L20.15 13ZM15 9V5H5V19H15V15H17V19C17 19.55 16.8042 20.0208 16.4125 20.4125C16.0208 20.8042 15.55 21 15 21H5C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H15C15.55 3 16.0208 3.19583 16.4125 3.5875C16.8042 3.97917 17 4.45 17 5V9H15Z" fill="#1C1B1F"/>
      </g>
    </svg>
    Иванов<br>
    Иван<br>
    Иванович
  </a>


      <a class="btn btn-red" routerLink="/grade"><span>Создать заявку</span>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" matTooltip="Создать заявку" matTooltipPosition="right">
          <mask id="mask0_73_1701" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
          <rect x="0.462891" width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_73_1701)">
          <path d="M2.46289 24V20H22.4629V24H2.46289ZM6.46289 16H7.86289L15.6629 8.225L14.2379 6.8L6.46289 14.6V16ZM4.46289 18V13.75L15.6629 2.575C15.8462 2.39167 16.0587 2.25 16.3004 2.15C16.5421 2.05 16.7962 2 17.0629 2C17.3296 2 17.5879 2.05 17.8379 2.15C18.0879 2.25 18.3129 2.4 18.5129 2.6L19.8879 4C20.0879 4.18333 20.2337 4.4 20.3254 4.65C20.4171 4.9 20.4629 5.15833 20.4629 5.425C20.4629 5.675 20.4171 5.92083 20.3254 6.1625C20.2337 6.40417 20.0879 6.625 19.8879 6.825L8.71289 18H4.46289Z" fill="#1C1B1F"/>
          </g>
        </svg>
      </a>

      <a class="btn btn-grey register"  routerLink="/ratecard"><span>Реестр оценок</span>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" matTooltip="Реестр оценок" matTooltipPosition="right" matTooltipClass="redTooltip">
          <mask id="mask0_69_1677" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
          <rect x="0.462891" width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_69_1677)">
          <path d="M12.463 22.025C12.1963 22.025 11.9422 21.975 11.7005 21.875C11.4588 21.775 11.238 21.6333 11.038 21.45L3.01299 13.425C2.82965 13.225 2.68799 13.0042 2.58799 12.7625C2.48799 12.5208 2.43799 12.2667 2.43799 12C2.43799 11.7333 2.48799 11.475 2.58799 11.225C2.68799 10.975 2.82965 10.7583 3.01299 10.575L11.038 2.55001C11.238 2.35001 11.4588 2.20417 11.7005 2.11251C11.9422 2.02084 12.1963 1.97501 12.463 1.97501C12.7297 1.97501 12.988 2.02084 13.238 2.11251C13.488 2.20417 13.7047 2.35001 13.888 2.55001L21.913 10.575C22.113 10.7583 22.2588 10.975 22.3505 11.225C22.4422 11.475 22.488 11.7333 22.488 12C22.488 12.2667 22.4422 12.5208 22.3505 12.7625C22.2588 13.0042 22.113 13.225 21.913 13.425L13.888 21.45C13.7047 21.6333 13.488 21.775 13.238 21.875C12.988 21.975 12.7297 22.025 12.463 22.025ZM12.463 20.025L20.488 12L12.463 3.97501L4.43799 12L12.463 20.025ZM11.463 13H13.463V7.00001H11.463V13ZM12.463 16C12.7463 16 12.9838 15.9042 13.1755 15.7125C13.3672 15.5208 13.463 15.2833 13.463 15C13.463 14.7167 13.3672 14.4792 13.1755 14.2875C12.9838 14.0958 12.7463 14 12.463 14C12.1797 14 11.9422 14.0958 11.7505 14.2875C11.5588 14.4792 11.463 14.7167 11.463 15C11.463 15.2833 11.5588 15.5208 11.7505 15.7125C11.9422 15.9042 12.1797 16 12.463 16Z" fill="#E22B36"/>
          </g>
        </svg>
      </a>



  <h2>Справочники</h2>

  <ul>
    <li>
      <a>
        <svg width="35" height="25" viewBox="0 0 35 25" fill="none" xmlns="http://www.w3.org/2000/svg" matTooltip="Учреждения Москомспорта" matTooltipPosition="right">
          <g clip-path="url(#clip0_0_188)">
          <path d="M22.3421 22.5H23.5526V19.375H26.5789V18.125H23.5526V15H22.3421V18.125H19.3158V19.375H22.3421V22.5ZM22.9474 25C21.2728 25 19.8454 24.3906 18.6651 23.1719C17.4849 21.9531 16.8947 20.4792 16.8947 18.75C16.8947 17.0208 17.4849 15.5469 18.6651 14.3281C19.8454 13.1094 21.2728 12.5 22.9474 12.5C24.6219 12.5 26.0493 13.1094 27.2296 14.3281C28.4099 15.5469 29 17.0208 29 18.75C29 20.4792 28.4099 21.9531 27.2296 23.1719C26.0493 24.3906 24.6219 25 22.9474 25ZM6 22.5V7.5L15.6842 0L25.3684 7.5V10.375C24.9851 10.25 24.5917 10.1562 24.1882 10.0938C23.7847 10.0312 23.3711 10 22.9474 10V8.75L15.6842 3.125L8.42105 8.75V20H14.5645C14.625 20.4375 14.7158 20.8646 14.8368 21.2812C14.9579 21.6979 15.1092 22.1042 15.2908 22.5H6Z" fill="#272B37"/>
          </g>
          <defs>
          <clipPath id="clip0_0_188">
          <rect width="34.9254" height="24.9467" fill="white"/>
          </clipPath>
          </defs>
        </svg>
        <span>Учреждения Москомспорта</span></a>
    </li>
    <li>
      <a>
        <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg" matTooltip="Объекты учреждений Москомспорта" matTooltipPosition="right">
          <g clip-path="url(#clip0_0_192)">
          <path d="M3 25.9467V0.946693H17V6.50225H31V25.9467H3ZM5.8 23.1689H8.6V20.3911H5.8V23.1689ZM5.8 17.6134H8.6V14.8356H5.8V17.6134ZM5.8 12.0578H8.6V9.28003H5.8V12.0578ZM5.8 6.50225H8.6V3.72447H5.8V6.50225ZM11.4 23.1689H14.2V20.3911H11.4V23.1689ZM11.4 17.6134H14.2V14.8356H11.4V17.6134ZM11.4 12.0578H14.2V9.28003H11.4V12.0578ZM11.4 6.50225H14.2V3.72447H11.4V6.50225ZM17 23.1689H28.2V9.28003H17V12.0578H19.8V14.8356H17V17.6134H19.8V20.3911H17V23.1689ZM22.6 14.8356V12.0578H25.4V14.8356H22.6ZM22.6 20.3911V17.6134H25.4V20.3911H22.6Z" fill="#272B37"/>
          </g>
          <defs>
          <clipPath id="clip0_0_192">
          <rect width="34.9254" height="24.9467" fill="white" transform="translate(0 0.946693)"/>
          </clipPath>
          </defs>
        </svg>
        <span>Объекты учреждений Москомспорта</span></a>
    </li>
    <li>
      <a>
        <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg" matTooltip="Замечания" matTooltipPosition="right">
          <g clip-path="url(#clip0_0_196)">
          <path d="M27.5 20.8934V10.8934H30V20.8934H27.5ZM28.75 25.8934C28.3958 25.8934 28.099 25.7736 27.8594 25.534C27.6198 25.2944 27.5 24.9976 27.5 24.6434C27.5 24.2892 27.6198 23.9923 27.8594 23.7528C28.099 23.5132 28.3958 23.3934 28.75 23.3934C29.1042 23.3934 29.401 23.5132 29.6406 23.7528C29.8802 23.9923 30 24.2892 30 24.6434C30 24.9976 29.8802 25.2944 29.6406 25.534C29.401 25.7736 29.1042 25.8934 28.75 25.8934ZM17.5 25.8934C17.1875 25.8934 16.8802 25.8309 16.5781 25.7059C16.276 25.5809 16 25.3934 15.75 25.1434L5.75 15.1434C5.5 14.8934 5.3125 14.6173 5.1875 14.3153C5.0625 14.0132 5 13.7059 5 13.3934C5 13.0809 5.0625 12.7736 5.1875 12.4715C5.3125 12.1694 5.5 11.8934 5.75 11.6434L15.75 1.64339C16 1.39339 16.276 1.20589 16.5781 1.08089C16.8802 0.955887 17.1875 0.893387 17.5 0.893387C17.8125 0.893387 18.1198 0.955887 18.4219 1.08089C18.724 1.20589 19 1.39339 19.25 1.64339L25 7.39339V11.8934L17.5 4.39339L8.5 13.3934L17.5 22.3934L25 14.8934V19.3934L19.25 25.1434C19 25.3934 18.724 25.5809 18.4219 25.7059C18.1198 25.8309 17.8125 25.8934 17.5 25.8934ZM17.5 18.8934L12 13.3934L17.5 7.89339L23 13.3934L17.5 18.8934Z" fill="#272B37"/>
          </g>
          <defs>
          <clipPath id="clip0_0_196">
          <rect width="34.9254" height="24.9467" fill="white" transform="translate(0 0.893387)"/>
          </clipPath>
          </defs>
        </svg>
        <span>Замечания</span></a>
    </li>
    @for (i of typeOfEstimation.list; track i.name){
      <li>
        <a [routerLink]="['/',i.href]">
          <svg width="35" height="26" viewBox="0 0 35 26" fill="none" xmlns="http://www.w3.org/2000/svg" [matTooltip]="i.name" matTooltipPosition="right">
            <g clip-path="url(#clip0_0_196)">
              <path d="M27.5 20.8934V10.8934H30V20.8934H27.5ZM28.75 25.8934C28.3958 25.8934 28.099 25.7736 27.8594 25.534C27.6198 25.2944 27.5 24.9976 27.5 24.6434C27.5 24.2892 27.6198 23.9923 27.8594 23.7528C28.099 23.5132 28.3958 23.3934 28.75 23.3934C29.1042 23.3934 29.401 23.5132 29.6406 23.7528C29.8802 23.9923 30 24.2892 30 24.6434C30 24.9976 29.8802 25.2944 29.6406 25.534C29.401 25.7736 29.1042 25.8934 28.75 25.8934ZM17.5 25.8934C17.1875 25.8934 16.8802 25.8309 16.5781 25.7059C16.276 25.5809 16 25.3934 15.75 25.1434L5.75 15.1434C5.5 14.8934 5.3125 14.6173 5.1875 14.3153C5.0625 14.0132 5 13.7059 5 13.3934C5 13.0809 5.0625 12.7736 5.1875 12.4715C5.3125 12.1694 5.5 11.8934 5.75 11.6434L15.75 1.64339C16 1.39339 16.276 1.20589 16.5781 1.08089C16.8802 0.955887 17.1875 0.893387 17.5 0.893387C17.8125 0.893387 18.1198 0.955887 18.4219 1.08089C18.724 1.20589 19 1.39339 19.25 1.64339L25 7.39339V11.8934L17.5 4.39339L8.5 13.3934L17.5 22.3934L25 14.8934V19.3934L19.25 25.1434C19 25.3934 18.724 25.5809 18.4219 25.7059C18.1198 25.8309 17.8125 25.8934 17.5 25.8934ZM17.5 18.8934L12 13.3934L17.5 7.89339L23 13.3934L17.5 18.8934Z" fill="#272B37"/>
            </g>
            <defs>
              <clipPath id="clip0_0_196">
                <rect width="34.9254" height="24.9467" fill="white" transform="translate(0 0.893387)"/>
              </clipPath>
            </defs>
          </svg>
          <span>{{ i.name | capitalize }}</span></a>
      </li>
    }
  </ul>
</nav>
