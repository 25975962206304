<button mat-icon-button class="close-button" (click)="onNoClick()">
  <mat-icon>close</mat-icon>
</button>
<div class="ostso-dialog " [ostsoIsEnabled]="isEnabled" (closeEvent)="onNoClick()">
  @if (!loaded) {
    <ostso-fallback/>
  }
  <h1>{{ isNew ? "Создание замечания" : "Редактирование замечания" }}</h1>
  <div mat-dialog-content>
    <mat-select class="remarktype-select" [formControl]="groupSelector" placeholder="Группа">
      @for (group of groups; track group.id) {
        <mat-option [value]="group">
          {{ group.name }}
        </mat-option>
      }
    </mat-select>
    <mat-select class="remarktype-select" [formControl]="subgroupSelector" placeholder="Подгруппа">
      @for (subgroup of selectedSubgroups; track subgroup.id) {
        <mat-option [value]="subgroup">
          {{ subgroup.name }}
        </mat-option>

      }
    </mat-select>
    <mat-select class="remarktype-select" [formControl]="kindSelector" placeholder="Вид">
      @for (kind of selectedKinds; track kind.id) {
        <mat-option [value]="kind">
          {{ kind.name }}
        </mat-option>

      }
    </mat-select>
    <textarea class="remarktype-textarea"
              matInput [formControl]="name"
              placeholder="Полное наименование"
              cols="75"
              rows="2"
    ></textarea>
    <textarea class="remarktype-textarea"
              matInput [formControl]="shortName"
              placeholder="Краткое наименование"
              cols="75"
              rows="2"
    ></textarea>

    <label class="remarktype-label" for="notHasRemarks">
      <input id="notHasRemarks" matCheck [formControl]="notHasRemarksSelector" type="checkbox">
      Без замечаний
    </label>

    <h4>Стоимость</h4>
    <div class="percent-input">
      <input class="remarktype-input" matInput [formControl]="significanceSelector" type="number" max="100" min="0"
             placeholder="Введите число (%)">
      <span>%</span>
    </div>
    @if (loaded) {
      <ostso-status-form [(statusSource)]="result.ratePriorityDaysForFix" />
<!--      {{ result.ratePriorityDaysForFix | json }}-->
    }
  </div>
  <div mat-dialog-actions>
    <button [ngClass]="['btn', isValid ? 'btn-blue': 'btn-gray']" [ngStyle]="{color:isValid ? '#fff' : '#777'}"
            mat-button [mat-dialog-close]="result" cdkFocusInitial [disabled]="!isValid">Применить
    </button>
    <button class="btn btn-secondary" mat-button (click)="onNoClick()">Сбросить</button>
  </div>

</div>

