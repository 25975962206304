import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatTooltipModule} from '@angular/material/tooltip';
import {CapitalizePipe} from '../../../core/pipes/capitalize.pipe';
import {TypeOfEstimation} from './type-of-estimation';


@Component({
  selector: 'ostso-side-bar',
  standalone: true,
  imports: [
    RouterLink,
    MatTooltipModule,
    CapitalizePipe,
  ],
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss'
})
export class SideBarComponent {

  constructor(public typeOfEstimation: TypeOfEstimation) {
  }
}
