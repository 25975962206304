
const mapState: Record<string, Record<StatusRemarkTypeCodes, StatusRemarkTypeCodes|undefined>> = {
  sendToAgreement:{
    firm_fixed: 'inspector_agreement',
    firm_revision: 'inspector_agreement',
    fixed: undefined,
    inspector_agreement: undefined,
    inspector_agreement_revision: undefined,
    inspector_revision: 'operator_agreement',
    operator_agreement: undefined,
    operator_approval: undefined,
    without_remark: undefined,
    project: 'operator_agreement'
  },
  accept:{
    firm_fixed: undefined,
    firm_revision: undefined,
    fixed: undefined,
    inspector_agreement: undefined,
    inspector_agreement_revision: undefined,
    inspector_revision: undefined,
    operator_agreement: 'firm_fixed',
    operator_approval:  'fixed',
    without_remark: undefined,
    project: undefined
  },
  agree:{
    firm_fixed: undefined,
    firm_revision: undefined,
    fixed: undefined,
    inspector_agreement: 'operator_approval',
    inspector_agreement_revision: 'operator_approval',
    inspector_revision: undefined,
    operator_agreement: undefined,
    operator_approval:  undefined,
    without_remark: undefined,
    project: undefined
  },
  disagree:{
    firm_fixed: undefined,
    firm_revision: undefined,
    fixed: undefined,
    inspector_agreement: 'firm_revision',
    inspector_agreement_revision: 'firm_revision',
    inspector_revision: undefined,
    operator_agreement: undefined,
    operator_approval:  undefined,
    without_remark: undefined,
    project: undefined
  },
  reject:{
    firm_fixed: undefined,
    firm_revision: undefined,
    fixed: undefined,
    inspector_agreement: undefined,
    inspector_agreement_revision: undefined,
    inspector_revision: undefined,
    operator_agreement: 'inspector_revision',
    operator_approval:  'inspector_agreement_revision',
    without_remark: undefined,
    project: undefined
  }
};

export function changeState(method: string, status: StatusRemarkTypeCodes|undefined): StatusRemarkTypeCodes|undefined {
  if(status === undefined) return;
  if(method==='without_remark') return 'without_remark';
  return mapState[method][status];
}
