import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {ToastComponent} from '../../shared/components/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  currentSnackBar: MatSnackBarRef<any> | null = null;

  constructor(private snackBar: MatSnackBar) {
  }

  publish(name: string, options: { type: 'success' | 'error' | 'info' } = {type: 'info'}): void {
    if (this.currentSnackBar) this.currentSnackBar.dismiss();
    if (this.currentSnackBar) {
      this.currentSnackBar.afterDismissed().subscribe(() => {
        this.currentSnackBar = this.snackBar.openFromComponent(ToastComponent, {
          data: {
            text: name,
            type: options.type
          },
          duration: 2000,
          horizontalPosition: 'end',
          verticalPosition: 'bottom',
          panelClass: ['ostso-snackbar']
        });
      });
    } else {
      this.currentSnackBar = this.snackBar.openFromComponent(ToastComponent, {
        data: {
          text: name,
          type: options.type
        },
        duration: 2000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['ostso-snackbar']
      });
    }

  }

  open(name: string, options: { type: 'success' | 'error' | 'info' } = {type: 'info'}): void {
    if (this.currentSnackBar) this.currentSnackBar.dismiss();
    this.currentSnackBar = this.snackBar.open(name, '', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 2000,
      panelClass: ['ostso-snackbar']
    });
  }

  start(name: string, options: { type: 'success' | 'error' | 'info' } = {type: 'info'}):void{
    this.snackBar.openFromComponent(ToastComponent, {
      data: {
        text: name,
        type: options.type
      },
      duration: 2000,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
      panelClass: ['ostso-snackbar']
    });
  }

  sucess(name: string):void{
    this.start(name, {type:'success'});
  }
  info(name: string):void{
    this.start(name, {type:'info'});
  }
  error(name: string):void{
    this.start(name, {type:'error'});
  }
}
