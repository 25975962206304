<div [ngClass]="['upload-photo', dragOver()?'over':'' ]"
     #downloadphoto
     (dragover)="onDragOver($event)"
     (dragleave)="onDragLeave($event)"
     (drop)="onDrop($event)"
>
  <div>
    <input #files type="file" class="upload-photo__files" (change)="downloadFiles()" multiple/>
    <svg (click)="files.click()" width="80" height="80" viewBox="0 0 80 80" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_0_771" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="80"
            height="80">
        <rect width="80" height="80" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_0_771)">
        <path
          d="M21.6666 66.6667C16.611 66.6667 12.2916 64.9167 8.70825 61.4167C5.12492 57.9167 3.33325 53.6389 3.33325 48.5834C3.33325 44.25 4.63881 40.3889 7.24992 37C9.86103 33.6112 13.2777 31.4445 17.4999 30.5C18.8888 25.3889 21.6666 21.25 25.8333 18.0834C29.9999 14.9167 34.7221 13.3334 39.9999 13.3334C46.4999 13.3334 52.0138 15.5973 56.5416 20.125C61.0694 24.6528 63.3332 30.1667 63.3332 36.6667C67.1666 37.1112 70.3471 38.7639 72.8749 41.625C75.4027 44.4862 76.6666 47.8334 76.6666 51.6667C76.6666 55.8334 75.2082 59.375 72.2916 62.2917C69.3749 65.2084 65.8332 66.6667 61.6666 66.6667H43.3332C41.4999 66.6667 39.9305 66.0139 38.6249 64.7084C37.3194 63.4028 36.6666 61.8334 36.6666 60V42.8334L31.3333 48L26.6666 43.3334L39.9999 30L53.3332 43.3334L48.6666 48L43.3332 42.8334V60H61.6666C63.9999 60 65.9721 59.1945 67.5832 57.5834C69.1944 55.9723 69.9999 54 69.9999 51.6667C69.9999 49.3334 69.1944 47.3612 67.5832 45.75C65.9721 44.1389 63.9999 43.3334 61.6666 43.3334H56.6666V36.6667C56.6666 32.0556 55.0416 28.125 51.7916 24.875C48.5416 21.625 44.611 20 39.9999 20C35.3888 20 31.4583 21.625 28.2083 24.875C24.9583 28.125 23.3333 32.0556 23.3333 36.6667H21.6666C18.4444 36.6667 15.6944 37.8056 13.4166 40.0834C11.1388 42.3612 9.99992 45.1112 9.99992 48.3334C9.99992 51.5556 11.1388 54.3056 13.4166 56.5834C15.6944 58.8612 18.4444 60 21.6666 60H29.9999V66.6667H21.6666Z"
          fill="#ABABAB"/>
      </g>
    </svg>
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"
         (click)="openCamera()">
      <mask id="mask0_0_774" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="80"
            height="80">
        <rect width="80" height="80" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_0_774)">
        <path
          d="M10 70C8.16671 70 6.59726 69.3472 5.29171 68.0416C3.98615 66.7361 3.33337 65.1666 3.33337 63.3333V23.3333C3.33337 21.5 3.98615 19.9305 5.29171 18.625C6.59726 17.3194 8.16671 16.6666 10 16.6666H20.5L26.6667 9.99998H46.6667V16.6666H29.5834L23.5 23.3333H10V63.3333H63.3334V33.3333H70V63.3333C70 65.1666 69.3473 66.7361 68.0417 68.0416C66.7362 69.3472 65.1667 70 63.3334 70H10ZM63.3334 23.3333V16.6666H56.6667V9.99998H63.3334V3.33331H70V9.99998H76.6667V16.6666H70V23.3333H63.3334ZM36.6667 58.3333C40.8334 58.3333 44.375 56.875 47.2917 53.9583C50.2084 51.0416 51.6667 47.5 51.6667 43.3333C51.6667 39.1666 50.2084 35.625 47.2917 32.7083C44.375 29.7916 40.8334 28.3333 36.6667 28.3333C32.5 28.3333 28.9584 29.7916 26.0417 32.7083C23.125 35.625 21.6667 39.1666 21.6667 43.3333C21.6667 47.5 23.125 51.0416 26.0417 53.9583C28.9584 56.875 32.5 58.3333 36.6667 58.3333ZM36.6667 51.6666C34.3334 51.6666 32.3612 50.8611 30.75 49.25C29.1389 47.6389 28.3334 45.6666 28.3334 43.3333C28.3334 41 29.1389 39.0278 30.75 37.4166C32.3612 35.8055 34.3334 35 36.6667 35C39 35 40.9723 35.8055 42.5834 37.4166C44.1945 39.0278 45 41 45 43.3333C45 45.6666 44.1945 47.6389 42.5834 49.25C40.9723 50.8611 39 51.6666 36.6667 51.6666Z"
          fill="#ABABAB"/>
      </g>
    </svg>
  </div>

  <p>Загрузить фотографию/документ<br>
    или сделать фото</p>

</div>

