<button mat-icon-button class="close-button" (click)="onNoClick()">
  <mat-icon>close</mat-icon>
</button>
<div class="ostso-dialog" [class.blocked]="!loaded">
  @if (!loaded) {
    <ostso-fallback/>
  }
  <h1>Фильтр</h1>
  <form [formGroup]="form" class="filter-form">

    <mat-form-field appearance="outline">
      <mat-label>Архив</mat-label>
      <mat-select formControlName="statusId">
        <mat-option value="0">Не выбрано</mat-option>
        <mat-option value="2">Архив</mat-option>
        <mat-option value="1">Активен</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>ID</mat-label>
      <input matInput [formControlName]="'ids'" [placeholder]="'Введите ID или несколько ID, через запятую'">
    </mat-form-field>
    <div class="date-estimate">
      <mat-form-field
        appearance="outline"
      >
        <mat-label>Дата проведения оценки от:</mat-label>
        <mat-datepicker-toggle matIconPrefix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
        <input matInput [matDatepicker]="pickerFrom" [formControlName]="'dateFrom'">
      </mat-form-field>
      <mat-form-field
        appearance="outline"
      >
        <mat-label>Дата проведения оценки до:</mat-label>
        <mat-datepicker-toggle matIconPrefix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
        <input matInput [matDatepicker]="pickerTo" [formControlName]="'dateTo'">
      </mat-form-field>
    </div>
    <div class="date-estimate">
      <mat-form-field
        appearance="outline"
      >
        <mat-label>Дней в работе от:</mat-label>
        <input matInput [formControlName]="'daysInWorkFrom'">
      </mat-form-field>
      <mat-form-field
        appearance="outline"
      >
        <mat-label>Дней в работе до:</mat-label>
        <input matInput [formControlName]="'daysInWorkTo'">
      </mat-form-field>
    </div>

    @for (field of fields; track field.control) {
      @defer (when data[field.data].length > 0) {
        @if (field.control === 'facilityAddress') {
          <ostso-quick-autocomplete [label]="field.placeholder" [data]="data['addresses']"
                              [formControlName]="'facilityAddress'">
            <ng-template let-item="option">
              {{ item }}
            </ng-template>
          </ostso-quick-autocomplete>
        } @else {
<!--          <fieldset class="filter-form__fileldset">-->
<!--            <legend>{{ field.placeholder }}</legend>-->
            <ostso-multi-select [formControlName]="field.control" [data]="data[field.data]"
                                [placeholder]="field.placeholder">
              <ng-template let-item="item">
                {{ item.name }}
              </ng-template>
            </ostso-multi-select>
<!--          </fieldset>-->
        }
      } @loading (minimum 1) {
        Загружаем данные
      } @error {
        Ошибка при загрузке данных
      }
    }

    <mat-form-field appearance="outline">
      <mat-label>Объект БПК</mat-label>
      <mat-select formControlName="isLongspanStructureObject">
        <mat-option value="null">Не выбрано</mat-option>
        <mat-option value="true">Да</mat-option>
        <mat-option value="false">Нет</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Объект инвестстроительства</mat-label>
      <mat-select formControlName="isInvestmentObject">
        <mat-option value="null">Не выбрано</mat-option>
        <mat-option value="true">Да</mat-option>
        <mat-option value="false">Нет</mat-option>
      </mat-select>
    </mat-form-field>

  </form>
  <div mat-dialog-actions class="filter-form__actions">
    <button [ngClass]="['btn', form.status==='VALID' ? 'btn-blue': 'btn-gray']"
            [ngStyle]="{color:form.status==='VALID' ? '#fff' : '#777'}"
            mat-button
            cdkFocusInitial
            [disabled]="form.status!=='VALID'"
            (click)="onApply()"
    >
      Применить
    </button>
    <button class="btn btn-secondary" mat-button (click)="onReset()">Сбросить</button>
  </div>

</div>

