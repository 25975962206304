import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { IDataTableService } from '../common/interfaces/data-table-service.interface';
import { ITableColumn } from '../common/interfaces/table-column.interface';

@Injectable({
  providedIn: 'root'
})
export class DataTableService implements IDataTableService<any> {
  data$$: BehaviorSubject<any> = new BehaviorSubject([]);
  data$: Observable<any> = this.data$$.asObservable();

  showCustomColumns = true;

  constructor() {
  }

  getColumns(): ITableColumn[] {

    return [];
  }

  getData(): any[] {
    return this.data$$.getValue();
  }

  getCustomColumns(): string[] {
    throw new Error('Not implemented');
  }

  setCustomColumns(columnName: string[]): void {
    throw new Error('Not implemented');
  }

  setData(data: {pager: any; values: unknown[]}): void {
    return this.data$$.next(data);
  }
}
