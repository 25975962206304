import {
  ratePriorityDaysForFixItem
} from '../../routing/register/dialogs/dialogForPriority/register-dialog-priority.component';
import {SetStatusDto} from './set-status-dto';

export class RemarkType {
  public id?: number;
  public name: string;
  public shortName: string;
  public significance?: number;
  public rateGroup?: Item;
  public rateSubGroup?: Item;
  public rateKind?: Item;
  public ratePriorityDaysForFix?: ratePriorityDaysForFixItem[] = [];
  public status?: StatusRemarkType;
  public notHasRemarks?: boolean|null;

  constructor(
    id?: number,
    name: string = '',
    shortName: string  =  '',
    significance: number = 0,
    rateGroup: Item = {name: ''},
    rateSubGroup: Item = {name: ''},
    rateKind: Item = {name: ''},
    ratePriorityDaysForFix: ratePriorityDaysForFixItem[] = [],
    status?: StatusRemarkType,
    notHasRemarks: boolean|null = null,
  ) {
    this.id = id
    this.name = name
    this.shortName  = shortName
    this.significance = significance;
    this.rateGroup = rateGroup
    this.rateSubGroup = rateSubGroup
    this.rateKind = rateKind
    this.ratePriorityDaysForFix = ratePriorityDaysForFix
    this.status = status
    this.notHasRemarks  = notHasRemarks
  }
}

export class RemarkTypeTable {
  public id?: number;
  public name: string;
  public shortName: string;
  public significance?: number;
  public rateGroup?: string;
  public rateSubGroup?: string;
  public rateKind?: string;
  public low: string;
  public average: string;
  public high: string;
  public critical: string;
  public status?: StatusRemarkType;
  public notHasRemarks?: boolean|null;
  public archive:SetStatusDto
  constructor(
    id?: number,
    name: string = '',
    shortName: string  =  '',
    significance: number = 0,
    rateGroup: string = '',
    rateSubGroup: string = '',
    rateKind: string = '',
    low: string = '',
    average: string = '',
    high: string = '',
    critical: string = '',
    status?: StatusRemarkType,
    notHasRemarks: boolean|null  = null,
  ) {
    this.id = id
    this.name = name
    this.shortName  = shortName
    this.significance = significance
    this.rateGroup = rateGroup
    this.rateSubGroup = rateSubGroup
    this.rateKind = rateKind
    this.low = low
    this.average = average
    this.high = high
    this.critical = critical
    this.status = status
    this.notHasRemarks   = notHasRemarks
    this.archive = {
      statusId: -1,
      comment: '',
      date: ''
    }
  }
}
