import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[ostsoCellTemplate]',
    standalone: true,
})
export class TableCellDirective {
  @Input('ostsoCellTemplate') name = '';

  constructor(public template: TemplateRef<unknown>) {}
}
