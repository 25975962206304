<button mat-icon-button class="close-button" (click)="onNoClick()">
  <mat-icon>close</mat-icon>
</button>
<div class="ostso-dialog ">
  <h1>Фильтр</h1>
  <form [formGroup]="formInstitutions" class="remarktype-form">
    <input type="text " class="remarktype-textarea" [formControlName]="'IasId'" placeholder="ID ИАС СПОРТ">
    <input type="text " class="remarktype-textarea" [formControlName]="'Name'" placeholder="Наименование">
    <input type="text " class="remarktype-textarea" [formControlName]="'ManagerFioPart'" placeholder="Руководитель">
    <input type="text " class="remarktype-textarea" [formControlName]="'Ogrn'" placeholder="ОГРН">
    <mat-select class="remarktype-select" [formControlName]="'StatusId'" placeholder="Архив">
      @for (status of statuses; track status) {
        <mat-option [value]="status.id">{{ status.name }}</mat-option>
      }
    </mat-select>

  </form>
  <div mat-dialog-actions>
    <button [ngClass]="['btn', form.status==='VALID' ? 'btn-blue': 'btn-gray']" [ngStyle]="{color:form.status==='VALID' ? '#fff' : '#777'}"
            mat-button [mat-dialog-close]="formInstitutions.value" cdkFocusInitial [disabled]="form.status!=='VALID'">Применить
    </button>
    <button class="btn btn-secondary" mat-button (click)="onInstitutionsReset()">Сбросить</button>
  </div>

</div>

