import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { IDataTableService } from '../common/interfaces/data-table-service.interface';
import { ITableColumn } from '../common/interfaces/table-column.interface';
import { StorageService } from './storage.service';

const REGISTER_COLUMNS = 'REGISTER_COLUMNS';

@Injectable({
  providedIn: 'root'
})
export class RegisterDataTableService implements IDataTableService<any> {
  data$$: BehaviorSubject<any> = new BehaviorSubject([]);
  data$: Observable<any> = this.data$$.asObservable();

  showCustomColumns = true;

  constructor(
    private storageService: StorageService
  ) {
  }

  getData(): any[] {
    return this.data$$.getValue();
  }

  getColumns(): ITableColumn[] {
    return [
      {
        name: 'isSelected',
        title: '',
        isRequired: true,
        settingTitle: 'Приоритет',
        useCustomTemplate: true,
      },
      {
        name: 'id',
        title: 'Id',
        isRequired: true,
        useCustomTemplate: false,
      },

      {
        name: 'rateGroup',
        title: 'Группа оценки',
        isRequired: true,
        useCustomTemplate: false,
      },
      {
        name: 'rateSubGroup',
        title: 'Подгруппа оценки',
        isRequired: true,
        useCustomTemplate: false,
      },
      {
        name: 'rateKind',
        title: 'Вид оценки',
        isRequired: true,
        useCustomTemplate: false,
      },
      {
        name: 'name',
        title: 'Название',
        useCustomTemplate: false,
      },
    ];
  }

  getCustomColumns(): string[] {
    return JSON.parse(this.storageService.getItem(REGISTER_COLUMNS) || '[]');
  }

  setCustomColumns(columnName: string[]): void {
    this.storageService.setItem(REGISTER_COLUMNS, JSON.stringify(columnName));
  }

  setData(data: {
    pager: any;
    values: ({ firm: string; facilityAddress: string; id: number; facility: string; createAt: string } | {
      firm: string;
      facilityAddress: string;
      id: number;
      facility: string;
      createAt: string
    } | { firm: string; facilityAddress: string; id: number; facility: string; createAt: string } | {
      firm: string;
      facilityAddress: string;
      id: number;
      facility: string;
      createAt: string
    })[]
  }): void {
    this.data$$.next(data);
  }
}
