export const messages = {
  'project->operator_agreement': {
    'warning': 'Вы уверены, что хотите отправить на согласование оператору?',
    'success': 'Вы успешно отправили на согласование',
    'error': 'Ошибка при отправке на согласование оператору'
  },
  'operator_agreement->inspector_revision': {
    'warning': 'Вы уверены, что хотите отправить на доработку инспектору?',
    'success': 'Вы успешно отправили на доработку инспектору',
    'error': 'Ошибка при отправке на доработку инспектору'
  },
  'operator_agreement->firm_fixed': {
    'warning': 'Вы уверены, что хотите отправить на устранение Учреждению?',
    'success': 'Вы успешно отправили на устранение Учреждению',
    'error': 'Ошибка при отправке на устранение Учреждению'
  },
  'inspector_revision->operator_agreement': {
    'warning': 'Вы уверены, что хотите отправить на согласование оператору?',
    'success': 'Вы успешно отправили на согласование оператору',
    'error': 'Ошибка при отправке на согласование оператору'
  },
  'firm_fixed->inspector_agreement': {
    'warning': 'Вы уверены, что хотите отправить на согласование инспектору?',
    'success': 'Вы успешно отправили на согласование инспектору',
    'error': 'Ошибка при отправке на согласование инспектору'
  },
  'inspector_agreement->operator_approval': {
    'warning': 'Вы уверены, что хотите отправить на утверждение оператору?',
    'success': 'Вы успешно отправили на утверждение оператору',
    'error': 'Ошибка при отправке на утверждение оператору'
  },
  'inspector_agreement->firm_revision': {
    'warning': 'Вы уверены, что хотите отправить на доработку Учреждению?',
    'success': 'Вы успешно отправили на доработку Учреждению',
    'error': 'Ошибка при отправке на доработку Учреждению'
  },
  'operator_approval->fixed': {
    'warning': 'Вы уверены, что замечание Устранено и хотите завершить оценку?',
    'success': 'Вы успешно завершили оценку',
    'error': 'Ошибка при отправке на завершение оценки'
  },
  'operator_approval->inspector_agreement_revision': {
    'warning': 'Вы уверены, что хотите отправить на доработку инспектору?',
    'success': 'Вы успешно отправили на доработку инспектору',
    'error': 'Ошибка при отправке на доработку инспектору'
  },
  'firm_revision->inspector_agreement': {
    'warning': 'Вы уверены, что хотите отправить на согласование инспектору?',
    'success': 'Вы успешно отправили на согласование инспектору',
    'error': 'Ошибка при отправке на согласование инспектору'
  },
  'inspector_agreement_revision->operator_approval': {
    'warning': 'Вы уверены, что хотите отправить на утверждение оператору?',
    'success': 'Вы успешно отправили на утверждение оператору',
    'error': 'Ошибка при отправке на утверждение оператору'
  },
  'inspector_agreement_revision->firm_revision': {
    'warning': 'Вы уверены, что хотите отправить на доработку Учреждению?',
    'success': 'Вы успешно отправили на доработку Учреждению',
    'error': 'Ошибка при отправке на доработку Учреждению'
  },
  'without_remark': {
    'warning': 'Вы уверены, что хотите принять без замечаний',
    'success': 'Вы успешно приняли оценку без замечаний',
    'error': 'Ошибка при принятии оценки без замечаний'
  }
};
