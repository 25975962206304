<div class="toasts">
  @for (toast of (toasts | async); track toast) {
    <div class="toasts__container">
      @if (toast.type === 'info') {
        <mat-icon style="color: blue;">info</mat-icon>
      }
      @if (toast.type === 'error') {
        <mat-icon style="color: red;">error</mat-icon>
      }
      @if (toast.type === 'check') {
        <mat-icon style="color: green;">check</mat-icon>
      }
      <span>{{ toast.text }}</span>
    </div>
  }
</div>
