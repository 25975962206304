import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, inject,
  Input, OnChanges,
  Output, QueryList, SimpleChanges,
  ViewChild, ViewChildren
} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {MatSuffix} from '@angular/material/form-field';
import Swiper from 'swiper';
import {Navigation} from 'swiper/modules';
import {SwiperOptions} from 'swiper/types';
import {SaveFileService} from '../../../core/services/save-file.service';
import {ObjectURLPipe} from '../../../core/pipes/object-url.pipe';
import { NgOptimizedImage} from '@angular/common';
import {MatProgressSpinner} from '@angular/material/progress-spinner';
import {SlideComponent} from './slide/slide.component';
import {ZoomDialogComponent} from '../zoom-dialog/zoom-dialog.component';
import {ModeService} from '../../../modules/routing/grade/mode.service';
import {AccessableDirective} from '../../../core/directives/accessable.directive';
import { DialogService} from '@ngneat/dialog';
import {StatusService} from '../../../modules/routing/grade/status.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'ostso-slider',
  standalone: true,
  imports: [
    MatIcon,
    MatSuffix,
    ObjectURLPipe,
    NgOptimizedImage,
    MatProgressSpinner,
    SlideComponent,
    AccessableDirective,
  ],
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements AfterViewInit, OnChanges {
  @ViewChild('file') file!: ElementRef;
  @ViewChild('container') container!: ElementRef;

  mode = inject(ModeService).mode;
  status = inject(StatusService).status;

  swiper: Swiper | null = null;
  // _arr: Partial<RateFileDate>[] = [];
  @Input() title: string = '';

  @Input() slides: (RateFile & { data?: File | Blob })[] = [];
  @Input() canControl: boolean = false;

  @ViewChildren(SlideComponent) slideComponents!: QueryList<SlideComponent>;

  @Output() removeItem = new EventEmitter();
  @Output() addedFiles: EventEmitter<File> = new EventEmitter();

  dialog = inject(MatDialog);

  cdr = inject(ChangeDetectorRef);

  get isDisabledRightButton(): boolean {
    console.log('get isDisabledRightButton', this.swiper?.isEnd, this.swiper?.activeIndex, this.slides.length);
    // return this.swiper?.isEnd || false;
    return this.swiper?.activeIndex === this.slides.length - 1 || false;
  }

  get isDisabledLeftButton(): boolean {
    console.log('get isDisabledLeftButton', this.swiper?.isBeginning, this.swiper?.activeIndex, this.slides.length);
    // return this.swiper?.isBeginning || false;
    return this.swiper?.activeIndex === 0 || false;
  }


  public get addedData(): (RateFile & { data: File | Blob | null })[] {
    const res = this.slideComponents.filter(e => e.item.id === 0 && e.data).map(e => ({...e.item, data: e.data}));
    return res;
  }

  constructor(private base64Service: SaveFileService, private saveService: SaveFileService) {
  }

  ngAfterViewInit(): void {
    const config: SwiperOptions = {
      modules: [Navigation],
      direction: 'horizontal',
      slidesPerView: 1,
      navigation: true,
    };
    this.swiper = new Swiper(this.container.nativeElement, config);
  }

  ngOnChanges(changes:SimpleChanges):void {
    console.log('swiper updated ', changes);
    setTimeout(() => this.swiper && this.swiper.update());
  }

  slideNext(): void {
    this.swiper && this.swiper.slideNext();
  }

  slidePrev(): void {
    this.swiper && this.swiper.slidePrev();
  }


  downloadFile(): void {
    this.file.nativeElement.click();
  }

  saveFile(): void {
    const activeIndex = this.swiper?.activeIndex;
    if (activeIndex === undefined) return;
    const component = this.slideComponents.get(activeIndex);
    if (!component?.data) return;
    this.saveService.save(component.data, component.item.name);
    // this.base64Service.save(this._arr[activeIndex].data, this._arr[activeIndex].name);

  }

  selectFiles(): void {
    for (const fileElement of this.file.nativeElement.files) {
      this.addedFiles.emit(fileElement as File);
      this.cdr.detectChanges();
      this.swiper?.update();
    }
  }

  zoom(event: number): void {
    const slideData: any = this.slideComponents.get(event)?.data;
    if (!this.slideComponents.get(event) && !slideData) return;
    const dataImage = slideData?.base64?slideData.base64:slideData;
    const data = URL.createObjectURL(dataImage);

    this.dialog.open(ZoomDialogComponent, {
      data
    });

    setTimeout(() => URL.revokeObjectURL(data));
  }

}

