import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {RemarkTypeService} from '../../../../apiModule';
import {Group1Service} from '../../../../apiModule/api/group1.service';
import {JsonPipe, NgStyle} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {SubgroupService} from '../../../../apiModule/api/subgroup.service';
import {map, Observable, toArray, forkJoin} from 'rxjs';
import {GroupEstimate} from '../../../../apiModule/model/groupEstimate';
import {SubgroupEstimate} from '../../../../apiModule/model/subgroupEstimate';
import {MatSelect} from '@angular/material/select';
import {KindService} from '../../../../apiModule/api/kind.service';
import {RemarkType} from '../../../../apiModule/model/remarktype';
import {FallbackComponent} from '../../../../../shared/components/fallback/fallback.component';
import {IsEnabledDirective} from '../../../../../core/directives/is-enabled.directive';
import {AccessService} from '../../../../../core/services/access.service';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {Router} from '@angular/router';


@Component({
  standalone: true,
  selector: 'ostso-kind-dialog',
  templateUrl: './register-dialog-kind-edit.component.html',
  styleUrls: ['../dialogForGroup/register-dialog.component.scss','./register-dialog-kind-edit.component.scss'],
  imports: [
    MatFormField,
    MatDialogClose,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    JsonPipe,
    MatAutocomplete,
    MatOption,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatSelect,
    FallbackComponent,
    IsEnabledDirective,
    MatIcon,
    MatIconButton,
    NgStyle
  ]
})
export class RegisterDialogKindEditComponent implements OnInit {

  isNew: boolean = false;
  loaded: boolean = false;
  isEnabled: boolean  = false;

  groups: GroupEstimate[] = [];
  subgroups: SubgroupEstimate[] = [];

  get filteredSubgroupNames(): SubgroupEstimate[] {
    if(!this.groupSelector.value) return this.subgroups;
    return this.subgroups
      .filter(subgroup => subgroup['rateGroup']?.id===Number(this.groupSelector.value?.id));
  }


  groupSelector = new FormControl<GroupEstimate>({} as GroupEstimate);
  subgroupSelector = new FormControl<SubgroupEstimate>({} as SubgroupEstimate);


  name = new FormControl('',[Validators.required]);
  shortName = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<RegisterDialogKindEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, any>,
    public groupData: Group1Service,
    public subgroupData: SubgroupService,
    public kindData: KindService,
    public accessService: AccessService,
    private router: Router,
  ) {
    this.isNew = data['isNew'];
    this.accessService.guide$.subscribe(v=>{
      this.isEnabled = v['kind'];
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/kind']);
    });
  }

  fromTableToModel(row: any): RemarkType{
    const {id, name, shortName, rateGroup, rateSubGroup} = row;
    const remarkType: RemarkType = {
      id,
      name,
      shortName,
      rateGroup: rateGroup ? {id: this.groups.find(group => group.name === rateGroup)?.id, name: rateGroup} : undefined,
      rateSubGroup: rateSubGroup ? {id: this.subgroups.find(subgroup => subgroup.name === rateSubGroup)?.id, name: rateSubGroup} : undefined,
    };

    return remarkType;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private initSelectors():void{
    this.groupSelector.setValue(this.data['rateGroup']?.name);
    this.groupSelector.valueChanges.subscribe(groupValue => {
      this.data['rateGroup'] = groupValue;
    });
    this.subgroupSelector.setValue(this.data['rateSubGroup']?.name);
    this.subgroupSelector.valueChanges.subscribe(subgroupValue => {
      this.data['rateSubGroup'] = subgroupValue;
      const exceptGroup = this.groups.find(e=>e.id===subgroupValue?.rateGroup?.id);
      if(!exceptGroup) return;
      this.groupSelector.setValue(exceptGroup);
    });
    this.name.setValue(this.data['name']);
    this.shortName.setValue(this.data['shortName']);
    this.name.valueChanges.subscribe(v => {
      this.data['name'] = v;
    });
    this.shortName.valueChanges.subscribe(v => {
      this.data['shortName'] = v;
    });
  }

  ngOnInit(): void {

    const groups$ = this.getAll(this.groupData);
    const subgroups$ = this.getAll(this.subgroupData);
    const kinds$ = this.getAll(this.kindData);

    forkJoin([groups$, subgroups$, kinds$]).subscribe(v => {
      const [groups, subgroups ] = v;
      this.groups = groups;
      this.subgroups = subgroups;
      this.data = this.fromTableToModel(this.data);
      this.initSelectors();
      this.loaded = true;
    });

    // this.createFormControlsDependecies();

  }

  getAll(service:  RemarkTypeService):Observable<any[]> {
    return service
      .apiRemarktypeGet({pageSize: 1e6, statusId: 1}, 'body', false, {transferCache: false})
      .pipe(
        map(v=>v.values),
        toArray(),
        map(v=>v.flat())
      );
  }

  // createFormControlsDependecies():void{
  //   this.groupSelector.valueChanges.subscribe(group => {
  //     if(!group) return;
  //     const inGroup = this.subgroups.find(e=>e.name===group)?.rateGroup?.name === group;
  //     if( !inGroup || !this.subgroupSelector.value) this.subgroupSelector
  //       .setValue(this.subgroups.find(e=>e.rateGroup?.name===group)?.name||'');
  //   });
  //
  //   this.subgroupSelector.valueChanges.subscribe(subgroup => {
  //     if(!subgroup) return;
  //     const subgroupModel = this.subgroups.find(e=>e.name===subgroup);
  //     const exceptGroup = this.groups.find(e=>e.name===subgroupModel?.rateGroup?.name);
  //     const isGroupExcepted = this.groupSelector.value === subgroupModel?.rateGroup?.name;
  //     if(!isGroupExcepted) { this.groupSelector.setValue(exceptGroup?.name||'');}
  //
  //   });


  // }

  onClose(): void {
    if(!this.subgroupSelector.valid || !this.name.valid) return;
    this.dialogRef.close(this.data);
  }
}

