import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import { RemarkTypeService} from '../../../../apiModule';
import {Group1Service} from '../../../../apiModule/api/group1.service';
import {JsonPipe} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {SubgroupService} from '../../../../apiModule/api/subgroup.service';
import {map, Observable, toArray, forkJoin} from 'rxjs';
import {GroupEstimate} from '../../../../apiModule/model/groupEstimate';
import {MatSelect} from '@angular/material/select';
import {KindService} from '../../../../apiModule/api/kind.service';
import {RemarkType} from '../../../../apiModule/model/remarktype';
import {FallbackComponent} from '../../../../../shared/components/fallback/fallback.component';
import {IsEnabledDirective} from '../../../../../core/directives/is-enabled.directive';
import {AccessService} from '../../../../../core/services/access.service';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {Router} from '@angular/router';



@Component({
  standalone: true,
  selector: 'ostso-subgroup-dialog',
  templateUrl: './register-dialog-subgroup.component.html',
  styleUrls: ['../dialogForGroup/register-dialog.component.scss','../dialogForKind/register-dialog-kind-edit.component.scss'],
  imports: [
    MatFormField,
    MatDialogClose,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    JsonPipe,
    MatAutocomplete,
    MatOption,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatSelect,
    FallbackComponent,
    IsEnabledDirective,
    MatIcon,
    MatIconButton
  ]
})
export class RegisterDialogSubGroupEditComponent implements OnInit {

  isNew: boolean = false;
  loaded: boolean = false;
  isEnabled: boolean = false;

  groups: GroupEstimate[] = [];
  get groupNames(): string[] {
    return this.groups.map(group => group.name);
  }

  groupSelector = new FormControl('',[Validators.required]);

  name = new FormControl('',[Validators.required]);
  shortName = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<RegisterDialogSubGroupEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, any>,
    public groupData: Group1Service,
    public subgroupData: SubgroupService,
    public kindData: KindService,
    public accessService: AccessService,
    private router: Router
  ) {
    this.isNew = data['isNew'];
    this.accessService.guide.subscribe(v=>{
      this.isEnabled = v['subgroup'];
    });
    this.dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/subgroup']);
    });
  }

  fromTableToModel(row: any): RemarkType{
    const {id, name, shortName, rateGroup} = row;
    const remarkType: RemarkType = {
      id,
      name,
      shortName,
      rateGroup: rateGroup ? {id: this.groups.find(group => group.name === rateGroup)?.id, name: rateGroup} : undefined,
    };

    return remarkType;
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.router.navigate(['/subgroup']);
  }

  private initSelectors():void{
    this.groupSelector.setValue(this.data['rateGroup']?.name);
    this.groupSelector.valueChanges.subscribe(groupText => {
      this.data['rateGroup'] = this.groups.find(group => group.name === groupText);
    });
    this.name.setValue(this.data['name']);
    this.shortName.setValue(this.data['shortName']);
    this.name.valueChanges.subscribe(v => {
      this.data['name'] = v;
    });
    this.shortName.valueChanges.subscribe(v => {
      this.data['shortName'] = v;
    });
  }

  ngOnInit(): void {

    const groups$ = this.getAll(this.groupData);
    const subgroups$ = this.getAll(this.subgroupData);
    const kinds$ = this.getAll(this.kindData);

    forkJoin([groups$, subgroups$, kinds$]).subscribe(v => {
      const [groups ] = v;
      this.groups = groups;
      this.data = this.fromTableToModel(this.data);
      this.initSelectors();
      this.loaded = true;
    });

  }

  getAll(service:  RemarkTypeService):Observable<any[]> {
    return service
      .apiRemarktypeGet({pageSize: 1e6, statusId:1}, 'body', false, {transferCache: false})
      .pipe(
        map(v=>v.values),
        toArray(),
        map(v=>v.flat())
      );
  }


  onClose(): void {
    if(!this.groupSelector.valid || !this.name.valid) return;
    this.dialogRef.close(this.data);
  }
}

