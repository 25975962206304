import {Directive, DoCheck, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[ostsoSetUndefinedWhen0]',
  standalone: true
})
export class SetUndefinedWhen0Directive implements DoCheck, OnInit {
  el: HTMLInputElement;

  constructor(private element: ElementRef) {
    this.el = this.element.nativeElement as HTMLInputElement;
  }

  ngOnInit(): void {
    this.el.value = this.el.value === '0' ? '' : this.el.value;
  }

  ngDoCheck(): void {
    this.el.value = this.el.value === '0' ? '' : this.el.value;
  }
}


