import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient}       from '@angular/common/http';
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {RemarkTypeService} from './remark-type.service';


@Injectable({
  providedIn: 'root'
})
export class KindService extends RemarkTypeService{

  override localVarPath = '/api/ratekind'

  constructor(
    httpClient: HttpClient,
    @Optional()@Inject(BASE_PATH) basePath: string|string[],
    @Optional() configuration: Configuration
  ) {
    super(httpClient, basePath, configuration);
  }


}
