export interface connectListItem {
  service: string;
  method: string;
  target: string;
  loadedLink: string
  errorMessageLink: string
}
export const ConnectList: connectListItem[] = [
  {
    service: 'firmsService',
    method: 'apiRategroupFilterlistGet',
    target: 'firms',
    loadedLink: 'loadedFirms',
    errorMessageLink: 'firmsService'
  },
  {
    service: 'facilityTypeService',
    method: 'apiRategroupFilterlistGet',
    target: 'facilityTypes',
    loadedLink: 'loadedFacilityType',
    errorMessageLink: 'facilityTypeService'
  },
  {
    service: 'facilityService',
    method: 'apiRategroupFilterlistGet',
    target: 'facilities',
    loadedLink: 'loadedFacility',
    errorMessageLink: 'facilityService'
  },
  {
    service: 'facilityService',
    method: 'apiFacilityAdressesGet',
    target: 'addresses',
    loadedLink: 'loadedAddress',
    errorMessageLink: 'facilityService'
  },
  {
    service: 'groupService',
    method: 'apiRategroupFilterlistGet',
    target: 'groups',
    loadedLink: 'loadedGroup',
    errorMessageLink: 'groupService'
  },
  {
    service: 'subGroupService',
    method: 'apiRategroupFilterlistGet',
    target: 'subGroups',
    loadedLink: 'loadedSubGroup',
    errorMessageLink: 'subGroupService'
  },
  {
    service: 'kindService',
    method: 'apiRategroupFilterlistGet',
    target: 'kinds',
    loadedLink: 'loadedKind',
    errorMessageLink: 'kidsService'
  },
  {
    service: 'remarkTypesService',
    method: 'apiRategroupFilterlistGet',
    target: 'remarkTypes',
    loadedLink: 'loadedRemarkTypes',
    errorMessageLink: 'remarkTypesService'
  },
  {
    service: 'inspectorsService',
    method: 'getInspectors$',
    target: 'inspectors',
    loadedLink: 'loadedInspectors',
    errorMessageLink: 'inspector'
  },
  {
    service: 'operatorsService',
    method: 'getOperators$',
    target: 'operators',
    loadedLink: 'loadedOperators',
    errorMessageLink: 'operator'
  },
  {
    service: 'priorityService',
    method: 'apiRemarktypeGet',
    target: 'priority',
    loadedLink: 'loadedPriority',
    errorMessageLink: 'priority'
  },
  {
    service: 'statusesService',
    method: 'apiRatestatustypeGet',
    target: 'statuses',
    loadedLink: 'loadedStatuses',
    errorMessageLink: 'statusesService'
  },
];
