import {Injectable} from '@angular/core';
import {ITableColumn} from '../common/interfaces/table-column.interface';
import {StorageService} from './storage.service';
import {RegisterDataTableService} from './register-data-table.service';


@Injectable({
  providedIn: 'root'
})
export class RegisterRemarktypeDataTableService extends RegisterDataTableService {


  constructor(
    storageService: StorageService
  ) {
    super(storageService);
  }


  override getColumns(): ITableColumn[] {
    return [

      {
        name: 'id',
        title: 'Id',
        useCustomTemplate: false,
        disabled: false,
        default: true
      },
      {
        name: 'rateGroup',
        title: 'Группа',
        useCustomTemplate: true,
        disabled: true
      },
      {
        name: 'rateSubGroup',
        title: 'Подгруппа',
        useCustomTemplate: true,
        disabled: true
      },
      {
        name: 'rateKind',
        title: 'Вид',
        useCustomTemplate: false,
        disabled: true
      },
      {
        name: 'name',
        title: 'Полное наименование',
        isRequired: true,
        useCustomTemplate: true,
        disabled: false,
        default: true
      },
      {
        name: 'shortName',
        title: 'Краткое наименование',
        useCustomTemplate: false,
        disabled: false,
        default: true
      },
      {
        name: 'low',
        title: 'Низкий приоритет',
        hideTitle: true,
        useCustomTemplate: false,
        disabled: true,
        default: true
      },
      {
        name: 'average',
        title: 'Средний приоритет',
        hideTitle: true,
        useCustomTemplate: false,
        disabled: true,
        default: true
      },
      {
        name: 'high',
        title: 'Высокий приоритет',
        hideTitle: true,
        useCustomTemplate: false,
        disabled: true,
        default: true
      },
      {
        name: 'critical',
        title: 'Критический приоритет',
        hideTitle: true,
        useCustomTemplate: false,
        disabled: true,
        default: true
      },
      {
        name: 'significance',
        title: 'Стоимость',
        useCustomTemplate: true,
        disabled: false,
        default: true
      },
      {
        name: 'notHasRemarks',
        title: 'Без замечаний',
        useCustomTemplate: true,
        disabled: true,
        default: false
      },
      {
        name: 'status',
        title: 'Архив',
        useCustomTemplate: true,
        disabled: true,
        default: false
      },
    ];
  }
}
