import {Component, output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AsyncPipe, JsonPipe, UpperCasePipe} from '@angular/common';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';

@Component({
  selector: 'ostso-register-header',
  standalone: true,
  imports: [
    JsonPipe,
    AsyncPipe,
    UpperCasePipe,
    MatIcon,
    MatIconButton
  ],
  templateUrl: './register-header.component.html',
  styleUrl: './register-header.component.scss'
})
export class RegisterHeaderComponent {
  open = output();
  constructor(public route: ActivatedRoute,) {
  }

  onOpenClick($event: MouseEvent):void {
    $event.preventDefault();
    $event.stopPropagation();
    this.open.emit();
  }
}
