<div class="ostso-dialog">
  @if (!loaded) {
    <ostso-fallback/>
  }
  <h1 class="date-item__title">{{getInstitutionsValues('shortName') || getInstitutionsValues('name')}}</h1>
  <div class="date-item__wrap" [ngStyle]="{'max-width': 'max-content', 'margin-inline':'auto'}">
    @for (i of keys; track i) {
      <div class="date-item">
        <div class="date-item__key">{{ mapKeys[i] }}</div>
        <div class="date-item__value">{{ getInstitutionsValues(i) }}</div>
      </div>
    }
  </div>
  <div mat-dialog-actions>
    <button class="btn btn-secondary date-item__button" mat-button (click)="onClose()">Закрыть</button>
  </div>
</div>

