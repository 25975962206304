import {ResolveFn} from '@angular/router';
import {inject} from '@angular/core';
import {map} from 'rxjs/operators';
import {FacilityService} from '../../../apiModule/api/facility.service';
import {IFacility, IFacilityTable} from '../../../apiModule/model/facility';

export const convertFacilityToFacilityTable = (v: IFacility): IFacilityTable => {
  return {
    id: v.id,
    iasId: v.iasId,
    balanceHolder: v.balanceHolder,
    nameFacility:  v.name || v.shortName,
    type: v.type && v.type.name,
    address: v.address||'',
    startDate: v.startDate,
    district: v.district,
    region: v.region,
    cadastralNumber: v.cadastralNumber,
    egipRegistryNumber: v.egipRegistryNumber,
    isInvestmentObject: v.isInvestmentObject,
    isLongspanStructureObject: v.isLongspanStructureObject,
    isInspectionRequired: v.isInspectionRequired,

  };
};

export const facilityResolver: ResolveFn<any> = (
  route,
  state,
  facilityService: FacilityService = inject(FacilityService)
) => {
  return facilityService
    .apiRemarktypeGet({}, 'body', false, {transferCache: false})
    .pipe(map(v => ({pager: v.pager, values: v.values.map(convertFacilityToFacilityTable)})));

};

