import {Component, Input} from '@angular/core';
import {FilterIndicatorComponent} from '../filter-indicator/filter-indicator.component';
import {JsonPipe} from '@angular/common';

@Component({
  selector: 'ostso-filter-indicators-bar',
  standalone: true,
  imports: [
    FilterIndicatorComponent,
    JsonPipe,
  ],
  templateUrl: './filter-indicators-bar.component.html',
  styleUrls: ['../filter-indicator/filter-indicator.component.scss', './filter-indicators-bar.component.scss']
})
export class FilterIndicatorsBarComponent {
  @Input() filter: FilterFormData | null = null;
  protected readonly Array = Array;
}
