/**
 * АПИ Бэка
 * АПИ службы сообщений.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent, HttpParameterCodec, HttpContext
} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../encoder';
import {Observable} from 'rxjs';

// @ts-ignore
import {ApiRemarktypePostRequest} from '../model/api-remarktype-post-request';

// @ts-ignore
import {BASE_PATH, COLLECTION_FORMATS} from '../variables';
import {Configuration} from '../configuration';
import {map} from 'rxjs/operators';
import {ApiRatecardSetstatusIdPutRequest} from '../model/api-ratecard-setstatus-id-put-request';


export interface ApiRemarktypeGetRequestParams {
  remarkTypeIds?: Array<number>;
  rateKindIds?: Array<number>;
  significanceMin?: number;
  significanceMax?: number;
  rateSubGroupIds?: Array<number>;
  rateGroupIds?: Array<number>;
  searchString?: string;
  statusId?: number;
  sortColumnName?: string;
  sortAscending?: boolean;
  pageIndex?: number;
  pageSize?: number;
  IasId?: number,
  Name?: string,
  ManagerFioPart?: string,
  Ogrn?: string,
  StatusId?: number,
  FirmIds?: number[],
  FacilityTypeIds?: number[],
  Address?: string,
  isInvestmentObject?: boolean | null;
  isLongspanStructureObject?: boolean | null;
  isInspectionRequired?: boolean | null;
  DistrictIds?: number[],
  RegionIds?: number[],
  CadastralNumber?: string,
  notHasRemarks?: boolean | null;
  usedOnly?: boolean;
  startDate?: string;
  StatusesIds?: number[]
}

export interface ApiRemarktypeIdDeleteRequestParams {
  id: number;
}

export interface ApiRemarktypeIdGetRequestParams {
  id: number;
}

export interface ApiRemarktypeIdPutRequestParams {
  id: number;
  apiRemarktypePostRequest?: ApiRemarktypePostRequest;
  extendPath?: string;
}

export interface ApiRemarktypePostRequestParams {
  apiRemarktypePostRequest?: ApiRemarktypePostRequest;
}

export interface ApiRemarktypeSetstatusIdPutRequestParams {
  id: number;
  apiRatecardSetstatusIdPutRequest?: ApiRatecardSetstatusIdPutRequest;
}

export interface ApiRategroupFilterlistGetRequestParams {
  filteredObjectName?: FilteredObjectName;
}
export interface IsInspectionRequiredUpdateDto {
  isInspectionRequired?: boolean;
}
export type ApiFacilityIdPatchRequest = IsInspectionRequiredUpdateDto;
export interface ApiFacilityIdPatchRequestParams {
  id: number;
  apiFacilityIdPatchRequest?: ApiFacilityIdPatchRequest;
}


@Injectable({
  providedIn: 'root'
})
export class RemarkTypeService {

  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  public localVarPath = '/api/remarktype'

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string | string[], @Optional() configuration: Configuration) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }


  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
          httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemarktypeGet(requestParameters: ApiRemarktypeGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiRemarktypeGet(requestParameters: ApiRemarktypeGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiRemarktypeGet(requestParameters: ApiRemarktypeGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiRemarktypeGet(requestParameters: ApiRemarktypeGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {
    const rateRemarkTypeIds = requestParameters.remarkTypeIds;
    const rateKindIds = requestParameters.rateKindIds;
    const significanceMin = requestParameters.significanceMin;
    const significanceMax = requestParameters.significanceMax;
    const rateSubGroupIds = requestParameters.rateSubGroupIds;
    const rateGroupIds = requestParameters.rateGroupIds;
    const searchString = requestParameters.searchString;
    const statusId = requestParameters.statusId;
    const sortColumnName = requestParameters.sortColumnName;
    const sortAscending = requestParameters.sortAscending;
    const pageIndex = requestParameters.pageIndex;
    const pageSize = requestParameters.pageSize;
    const IasId = requestParameters.IasId;
    const Name = requestParameters.Name;
    const ManagerFioPart = requestParameters.ManagerFioPart;
    const Ogrn = requestParameters.Ogrn;
    const StatusId = requestParameters.StatusId;
    const FirmIds = requestParameters.FirmIds;
    const FacilityTypeIds = requestParameters.FacilityTypeIds;
    const Address = requestParameters.Address;
    const isInvestmentObject = requestParameters.isInvestmentObject;
    const isLongspanStructureObject = requestParameters.isLongspanStructureObject;
    const isInspectionRequired= requestParameters.isInspectionRequired;
    const DistrictIds = requestParameters.DistrictIds;
    const RegionIds = requestParameters.RegionIds;
    const CadastralNumber = requestParameters.CadastralNumber;
    const notHasRemarks = requestParameters.notHasRemarks;
    const usedOnly = requestParameters.usedOnly;
    const startDate = requestParameters.startDate;
    const StatusesIds = requestParameters.StatusesIds;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    // localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
    //   <any>true, 'usedOnly');

    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>startDate, 'startDate');
    }
    if (usedOnly !== undefined && usedOnly !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>usedOnly, 'usedOnly');
    }

    if (rateRemarkTypeIds) {
      rateRemarkTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RemarkTypeIds');
      })
    }
    if (rateKindIds) {
      rateKindIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RateKindIds');
      })
    }
    if (significanceMin !== undefined && significanceMin !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>significanceMin, 'SignificanceMin');
    }
    if (significanceMax !== undefined && significanceMax !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>significanceMax, 'SignificanceMax');
    }
    if (rateSubGroupIds) {
      rateSubGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RateSubGroupIds');
      })
    }
    if (rateGroupIds) {
      rateGroupIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RateGroupIds');
      })
    }
    if (searchString !== undefined && searchString !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>searchString, 'SearchString');
    }
    if (statusId !== undefined && statusId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>statusId, 'StatusId');
    }
    if (Array.isArray(StatusesIds)) {
      StatusesIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'StatusesIds');
      })
    }
    if (sortColumnName !== undefined && sortColumnName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>sortColumnName, 'Sort.ColumnName');
    }
    if (sortAscending !== undefined && sortAscending !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>sortAscending, 'Sort.Ascending');
    }
    if (pageIndex !== undefined && pageIndex !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageIndex, 'PageIndex');
    }
    if (pageSize !== undefined && pageSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>pageSize, 'PageSize');
    }
    if (IasId !== undefined && IasId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>IasId, 'IasId');
    }
    if (Name !== undefined && Name !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>Name, 'Name');
    }
    if (ManagerFioPart !== undefined && ManagerFioPart !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>ManagerFioPart, 'ManagerFioPart');
    }
    if (Ogrn !== undefined && Ogrn !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>Ogrn, 'Ogrn');
    }
    if (StatusId !== undefined && StatusId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>StatusId, 'StatusId');
    }
    if (Array.isArray(StatusId)) {
      StatusId.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'StatusId');
      })
    }
    if (FirmIds) {
      FirmIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FirmIds');
      })
    }
    if (FacilityTypeIds && FacilityTypeIds.length) {
      FacilityTypeIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'FacilityTypeIds');
      })

    }
    if (Address !== undefined && Address !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>Address, 'Address');
    }
    if (isInvestmentObject !== undefined && isInvestmentObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInvestmentObject, 'isInvestmentObject');
    }
    if (isLongspanStructureObject !== undefined && isLongspanStructureObject !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isLongspanStructureObject, 'isLongspanStructureObject');
    }
    if (isInspectionRequired!== undefined && isInspectionRequired!== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>isInspectionRequired, 'isInspectionRequired');
    }
    if (DistrictIds) {
      DistrictIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'DistrictIds');
      })
    }
    if (RegionIds) {
      RegionIds.forEach((element) => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>element, 'RegionIds');
      })
    }
    if (CadastralNumber !== undefined && CadastralNumber !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>CadastralNumber, 'CadastralNumber');
    }
    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
      <any>notHasRemarks, 'notHasRemarks');

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = this.localVarPath;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemarktypeIdDelete(requestParameters: ApiRemarktypeIdDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiRemarktypeIdDelete(requestParameters: ApiRemarktypeIdDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiRemarktypeIdDelete(requestParameters: ApiRemarktypeIdDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiRemarktypeIdDelete(requestParameters: ApiRemarktypeIdDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {
    const id = requestParameters.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiRemarktypeIdDelete.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `${this.localVarPath}/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int32"
    })}`;
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemarktypeIdGet(requestParameters: ApiRemarktypeIdGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiRemarktypeIdGet(requestParameters: ApiRemarktypeIdGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiRemarktypeIdGet(requestParameters: ApiRemarktypeIdGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiRemarktypeIdGet(requestParameters: ApiRemarktypeIdGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {
    const id = requestParameters.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiRemarktypeIdGet.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `${this.localVarPath}/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int32"
    })}`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemarktypeIdPut(requestParameters: ApiRemarktypeIdPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiRemarktypeIdPut(requestParameters: ApiRemarktypeIdPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiRemarktypeIdPut(requestParameters: ApiRemarktypeIdPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiRemarktypeIdPut(requestParameters: ApiRemarktypeIdPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {
    const id = requestParameters.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiRemarktypeIdPut.');
    }
    const apiRemarktypePostRequest = requestParameters.apiRemarktypePostRequest;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `${this.localVarPath}${requestParameters.extendPath || ''}/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int32"
    })}`;
    return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: apiRemarktypePostRequest,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemarktypePost(requestParameters: ApiRemarktypePostRequestParams, observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiRemarktypePost(requestParameters: ApiRemarktypePostRequestParams, observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiRemarktypePost(requestParameters: ApiRemarktypePostRequestParams, observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiRemarktypePost(requestParameters: ApiRemarktypePostRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {
    const apiRemarktypePostRequest = requestParameters.apiRemarktypePostRequest;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = this.localVarPath;
    return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: apiRemarktypePostRequest,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  public apiRemarkTypeGetActive(requestParameters: ApiRemarktypeGetRequestParams = {}) {
    return this.apiRemarktypeGet(requestParameters, 'body', false, {transferCache: false})
      .pipe(map((v: { values: any[], pager: any }) => {
        const res = {...v, values: v.values.filter(v => v.status.code === 'Active')}
        return res;
      }));
  }

  // public apiRemarkChangeStatus(requestParameters: ApiRemarktypeIdPutRequestParams) {
  //   return this.apiRemarktypeIdPut({
  //     ...requestParameters,
  //     extendPath: '/setstatus'
  //   }, 'body', false, {transferCache: false})
  // }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRemarktypeSetstatusIdPut(requestParameters: ApiRemarktypeSetstatusIdPutRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiRemarktypeSetstatusIdPut(requestParameters: ApiRemarktypeSetstatusIdPutRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiRemarktypeSetstatusIdPut(requestParameters: ApiRemarktypeSetstatusIdPutRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiRemarktypeSetstatusIdPut(requestParameters: ApiRemarktypeSetstatusIdPutRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const id = requestParameters.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiRemarktypeSetstatusIdPut.');
    }
    const apiRatecardSetstatusIdPutRequest = requestParameters.apiRatecardSetstatusIdPutRequest;

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `${this.localVarPath}/setstatus/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
    return this.httpClient.request<any>('put', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: apiRatecardSetstatusIdPutRequest,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiFacilityAdressesGet(observe?: 'body', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any>;
  public apiFacilityAdressesGet(observe?: 'response', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpResponse<any>>;
  public apiFacilityAdressesGet(observe?: 'events', reportProgress?: boolean, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<HttpEvent<any>>;
  public apiFacilityAdressesGet(observe: any = 'body', reportProgress: boolean = false, options?: {
    httpHeaderAccept?: undefined,
    context?: HttpContext,
    transferCache?: boolean
  }): Observable<any> {

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/facility/adresses`;
    const res = this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
    return res;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiRategroupFilterlistGet(requestParameters: ApiRategroupFilterlistGetRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiRategroupFilterlistGet(requestParameters: ApiRategroupFilterlistGetRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiRategroupFilterlistGet(requestParameters: ApiRategroupFilterlistGetRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiRategroupFilterlistGet(requestParameters: ApiRategroupFilterlistGetRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const filteredObjectName = requestParameters.filteredObjectName;

    let localVarQueryParameters = new HttpParams({encoder: this.encoder});
    if (filteredObjectName !== undefined && filteredObjectName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>filteredObjectName, 'FilteredObjectName');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `${this.localVarPath}/filterlist`;
    return this.httpClient.request<any>('get', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public apiFacilityIdPatch(requestParameters: ApiFacilityIdPatchRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any>;
  public apiFacilityIdPatch(requestParameters: ApiFacilityIdPatchRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<any>>;
  public apiFacilityIdPatch(requestParameters: ApiFacilityIdPatchRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<any>>;
  public apiFacilityIdPatch(requestParameters: ApiFacilityIdPatchRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext, transferCache?: boolean}): Observable<any> {
    const id = requestParameters.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling apiFacilityIdPatch.');
    }
    const apiFacilityIdPatchRequest = requestParameters.apiFacilityIdPatchRequest;

    let localVarHeaders = this.defaultHeaders;

    let localVarCredential: string | undefined;
    // authentication (Bearer) required
    localVarCredential = this.configuration.lookupCredential('Bearer');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', localVarCredential);
    }

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
      ];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }


    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/*+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    let localVarPath = `/api/facility/${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})}`;
    return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        body: apiFacilityIdPatchRequest,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

}
