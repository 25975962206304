<form [formGroup]="statusesControls" class="status-form">
  <h4>Установите приоритет</h4>
  @for (status of keys; track status) {
    <div [formGroupName]="status" class="status-item">
      <span [ngClass]="['status-item__color','status-item__color-'+status]"></span>
      <span class="status-item__name">{{ names[status] }}</span>
      <input class="status-item__input" matInput formControlName="from" type="number" [max]="MAX" min="0" placeholder="дней от" ostsoSetUndefinedWhen0>
      <input class="status-item__input" matInput formControlName="to" type="number" [max]="MAX" min="0" placeholder="дней до" ostsoSetUndefinedWhen0>
    </div>
  }
</form>


