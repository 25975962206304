import { ResolveFn } from '@angular/router';
import {FacilityService} from '../../apiModule/api/facility.service';
import {inject} from '@angular/core';
import {RateCardService} from '../../apiModule/api/rate-card.service';

export const rateCardIdResolver: ResolveFn<any> = (
  route,
  state,
  rateCardService = inject(RateCardService)
  ) => {
  if (!route.params['id']) return;
  return rateCardService.apiRatecardIdGet({ id: route.params['id']});
};
