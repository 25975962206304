import {Component, DestroyRef} from '@angular/core';
import {DataTableService} from '../../../core/services/data-table.service';
import {ContentMenuItems, RegisterComponent} from './register.component';
import {ActivatedRoute} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatDialog} from '@angular/material/dialog';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {CommonModule} from '@angular/common';
import {SideBarComponent} from '../../../shared/components/side-bar/side-bar.component';
import {TableComponent} from '../../../shared/components/table/table.component';
import {TableCellDirective} from '../../../shared/components/table/table-cell.directive';
import {TableActionDirective} from '../../../shared/components/table/table-action.directive';
import {RegisterHeaderComponent} from './register-header/register-header.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SideBarDoubleComponent} from '../../../shared/components/side-bar-double/side-bar-double.component';
import {InstitutionsService} from '../../apiModule/api/institutions.service';
import {
  RegisterInstitutionsDataTableService
} from '../../../core/services/register-institutions-data-table.service';
import {First3Pipe} from '../../../core/pipes/first3.pipe';
import {FilterIndicatorsBarComponent} from './filter-indicators-bar/filter-indicators-bar.component';
import {FilterInstitutionsComponent} from './filters/institutions/filter-institutions.component';


@Component({
  selector: 'ostso-register-institutions',
  standalone: true,

  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
  imports:[
    CommonModule,
    SideBarComponent,
    TableComponent,
    TableCellDirective,
    TableActionDirective,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuTrigger,
    RegisterHeaderComponent,
    MatSidenavModule,
    MatDatepickerModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    SideBarDoubleComponent,
    First3Pipe,
    FilterIndicatorsBarComponent
  ],
  providers: [
    { provide: DataTableService, useClass: RegisterInstitutionsDataTableService }
  ]
})
export class RegisterInstitutionsComponent extends RegisterComponent {

  override titleCreate = '';
  override type: RegistrType = 'institution';
  override filterComponent = FilterInstitutionsComponent;

  override contentMenuItems: ContentMenuItems = {
    edit: 'Посмотреть полностью',
    restore: undefined,
    delete: undefined,
  };

  constructor(
    actRoute: ActivatedRoute,
    breakpointObserver: BreakpointObserver,
    dataTableService: DataTableService,
    destroyRef: DestroyRef,
    dialog: MatDialog,
    public typeService: InstitutionsService,
  ) {
    super(
      actRoute,
      breakpointObserver,
      dataTableService,
      destroyRef,
      dialog,
      typeService,
    );
  }


}
