import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
  standalone: true
})
export class CapitalizePipe implements PipeTransform {

  transform(value: string): string {
    if(!value) return '';
    return value.replace(/^(.)(.*)/,(_,p1,p2)=>p1.toUpperCase()+p2);
  }

}
