@if (fileType?.includes('image')) {
  @if (data) {
    <img [src]="data | objectURL" alt=""/>
  } @else {
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  }
} @else {
  @if (data) {
    <div class="grade-right-wrapper__doc">
      <svg _ngcontent-ng-c1787845997="" width="115" height="115" viewBox="0 0 115 115" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <mask _ngcontent-ng-c1787845997="" id="mask0_198_791" maskUnits="userSpaceOnUse" x="0" y="0"
              width="115" height="115" style="mask-type: alpha;">
          <rect _ngcontent-ng-c1787845997="" width="115" height="115" fill="#D9D9D9"></rect>
        </mask>
        <g _ngcontent-ng-c1787845997="" mask="url(#mask0_198_791)">
          <path _ngcontent-ng-c1787845997=""
                d="M40.25 86.25H74.75V77.625H40.25V86.25ZM40.25 69H74.75V60.375H40.25V69ZM31.5911 103.5C29.2179 103.5 27.1927 102.655 25.5156 100.966C23.8385 99.2773 23 97.2469 23 94.875V20.125C23 17.7531 23.8445 15.7227 25.5336 14.0336C27.2227 12.3445 29.2531 11.5 31.625 11.5H69L92 34.5V94.875C92 97.2469 91.155 99.2773 89.465 100.966C87.775 102.655 85.7434 103.5 83.3702 103.5H31.5911ZM63.25 40.25V20.125H31.625V94.875H83.375V40.25H63.25Z"
                fill="#272B37"></path>
        </g>
      </svg>
      <div>
        {{ item.name }}
      </div>
    </div>
  } @else {
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  }

}
