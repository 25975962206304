export class GroupEstimate {
  public id?: number;
  public name: string;
  public status?: StatusRemarkType
  public shortName: string;
  constructor(id?: number, name: string = '', shortName: string  = '',status?: StatusRemarkType) {
    this.id = id
    this.name = name
    this.shortName = shortName
    this.status = status
  }
}
