import {Component, DestroyRef, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatFormFieldModule} from '@angular/material/form-field';
import {
  AbstractControl,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {Group1Service} from '../../../../apiModule/api/group1.service';
import {JsonPipe, NgClass, NgStyle} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from '@angular/material/autocomplete';
import {SubgroupService} from '../../../../apiModule/api/subgroup.service';
import {KindService} from '../../../../apiModule/api/kind.service';
import {MatSelect} from '@angular/material/select';
import {RemarktypeService} from '../../../../apiModule/api/remarktype.service';
import {MatIcon} from '@angular/material/icon';
import {MatButton, MatIconButton} from '@angular/material/button';
import {FallbackComponent} from '../../../../../shared/components/fallback/fallback.component';
import {FilterRemarktypeComponent} from '../remarktype/filter-remarktype.component';

export interface LinkOptions {
  data: any[],
  node: string,
  control: AbstractControl
}

export interface LinkExtendOptions extends LinkOptions {
  firstElement: any
}

@Component({
  standalone: true,
  selector: 'ostso-kind-filter',
  templateUrl: './filter-subgroup.component.html',
  styleUrls: [
    '../../dialogs/dialogForGroup/register-dialog.component.scss',
    './filter-subgroup.component.scss'
  ],
  imports: [
    MatFormField,
    MatDialogClose,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    JsonPipe,
    MatAutocomplete,
    MatOption,
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    MatSelect,
    MatIcon,
    MatIconButton,
    FallbackComponent,
    MatButton,
    NgClass,
    NgStyle
  ]
})
export class FilterSubgroupComponent extends FilterRemarktypeComponent {

  constructor(fb: FormBuilder,
              dialogRef: MatDialogRef<FilterRemarktypeComponent>,
              @Inject(MAT_DIALOG_DATA) data: Record<string, any>,
              groupData: Group1Service,
              subgroupData: SubgroupService,
              kindData: KindService,
              remarktypeService: RemarktypeService,
              destroyRef: DestroyRef) {
    super(fb, dialogRef, data, groupData, subgroupData, kindData, remarktypeService, destroyRef);
  }

  override typeOfFilter: FilteredObjectName ='ratesubgroup';

  override get loaded(): boolean {
    return this.loadedGroup && this.loadedSubgroup;
  }

  override mapData = [
    {name: 'groups', data: this.groupData, flag: 'loadedGroup', fromData: 'group'},
    {name: 'subgroups', data: this.subgroupData, flag: 'loadedSubgroup', fromData: 'subgroup'},
  ];
}

