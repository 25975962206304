import {ResolveFn} from '@angular/router';
import {GroupService} from '../../../apiModule/api/group.service';
import {inject} from '@angular/core';
import {Group1Service} from '../../../apiModule/api/group1.service';
import {SubgroupService} from '../../../apiModule/api/subgroup.service';
import {map} from 'rxjs/operators';
import {SubgroupEstimate} from '../../../apiModule/model/subgroupEstimate';
import {tap} from 'rxjs';
import {Kind} from '../../../apiModule/model/kind';
import {KindService} from '../../../apiModule/api/kind.service';

export const kindResolver: ResolveFn<any> = (
  route,
  state,
  kindService: KindService = inject(KindService)
) => {
  return kindService
    .apiRemarktypeGet({}, 'body', false, {transferCache: false})
    .pipe(map(res => ({
      pager: res.pager,
      values: res.values.map((item: {
        id: number,
        name: string,
        shortName: string,
        rateGroup: Item,
        rateSubGroup: Item,
        status: StatusRemarkType
      }) => new Kind(item.id, item.name, item.shortName, item.rateGroup ,item.rateSubGroup, item.status))
    })));
};
