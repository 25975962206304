<h4>Учреждения Москомспорта

  <div class="header-input">
    <mat-icon matPrefix>search</mat-icon>
    <input matInput>
  </div>


</h4>
<mat-dialog-content>
  <button mat-icon-button class="close-button" (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
 
  <div class="horizontal-bar-chart">
    <div>
      <div class="title">Бассейн плавательный</div>
      <div class="bar"><div style="width: 90%;">5 000</div></div>
    </div>
    <div>
      <div class="title">Велодром</div>
      <div class="bar"><div style="width: 70%;">4 000</div></div>
    </div>
    <div>
      <div class="title">Конноспортивная база</div>
      <div class="bar"><div style="width: 50%;">3 000</div></div>
    </div>
    <div>
      <div class="title">Поле для гольфа</div>
      <div class="bar"><div style="width: 50%;">3 000</div></div>
    </div>    
    <div>
      <div class="title">Бассейн плавательный</div>
      <div class="bar"><div style="width: 90%;">5 000</div></div>
    </div>
    <div>
      <div class="title">Велодром</div>
      <div class="bar"><div style="width: 70%;">4 000</div></div>
    </div>
    <div>
      <div class="title">Конноспортивная база</div>
      <div class="bar"><div style="width: 50%;">3 000</div></div>
    </div>
    <div>
      <div class="title">Поле для гольфа</div>
      <div class="bar"><div style="width: 50%;">3 000</div></div>
    </div>   
    <div>
      <div class="title">Бассейн плавательный</div>
      <div class="bar"><div style="width: 90%;">5 000</div></div>
    </div>
    <div>
      <div class="title">Велодром</div>
      <div class="bar"><div style="width: 70%;">4 000</div></div>
    </div>
    <div>
      <div class="title">Конноспортивная база</div>
      <div class="bar"><div style="width: 50%;">3 000</div></div>
    </div>
    <div>
      <div class="title">Поле для гольфа</div>
      <div class="bar"><div style="width: 50%;">3 000</div></div>
    </div>   
    <div>
      <div class="title">Бассейн плавательный</div>
      <div class="bar"><div style="width: 90%;">5 000</div></div>
    </div>
    <div>
      <div class="title">Велодром</div>
      <div class="bar"><div style="width: 70%;">4 000</div></div>
    </div>
    <div>
      <div class="title">Конноспортивная база</div>
      <div class="bar"><div style="width: 50%;">3 000</div></div>
    </div>
    <div>
      <div class="title">Поле для гольфа</div>
      <div class="bar"><div style="width: 50%;">3 000</div></div>
    </div>   
    <div>
      <div class="title">Бассейн плавательный</div>
      <div class="bar"><div style="width: 90%;">5 000</div></div>
    </div>
    <div>
      <div class="title">Велодром</div>
      <div class="bar"><div style="width: 70%;">4 000</div></div>
    </div>
    <div>
      <div class="title">Конноспортивная база</div>
      <div class="bar"><div style="width: 50%;">3 000</div></div>
    </div>
    <div>
      <div class="title">Поле для гольфа</div>
      <div class="bar"><div style="width: 50%;">3 000</div></div>
    </div>                                
  </div> 
</mat-dialog-content>
