
export class Kind {
  public id?: number;
  public name: string;
  public shortName: string;
  public rateGroup?: Item;
  public rateSubGroup?: Item;
  public status?: StatusRemarkType
  constructor(
    id?: number,
    name: string='',
    shortName: string='',
    rateGroup: Item = {name:''},
    rateSubGroup: Item = {name:''},
    status?: StatusRemarkType
  ) {
    this.id = id
    this.name = name
    this.shortName = shortName
    this.rateGroup=rateGroup
    this.rateSubGroup=rateSubGroup
    this.status = status
  }
}
