import {Component, DestroyRef} from '@angular/core';
import {provideNativeDateAdapter} from '@angular/material/core';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {map, startWith} from 'rxjs/operators';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialog} from '@angular/material/dialog';
import {GradeModal} from '../grade-modal/grade-modal.component';
import {SideBarComponent} from '../../../shared/components/side-bar/side-bar.component';
import {SideBarDoubleComponent} from '../../../shared/components/side-bar-double/side-bar-double.component';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {TableActionDirective} from '../../../shared/components/table/table-action.directive';
import {TableCellDirective} from '../../../shared/components/table/table-cell.directive';
import {TableComponent} from '../../../shared/components/table/table.component';
import {MatTable} from '@angular/material/table';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ActivatedRoute} from '@angular/router';
import {DataTableService} from '../../../core/services/data-table.service';
import {RegisterDataTableService} from '../../../core/services/register-data-table.service';
import {RegisterGroupDataTableService} from '../../../core/services/register-group-data-table.service';
import {PageEvent} from '@angular/material/paginator';
import {RemarkTypeService} from '../../apiModule';
import {GroupEstimateDialogComponent, GroupEstimateDialogData} from './dialog/group-estimate-dialog.component';
import {GroupService} from '../../apiModule/api/group.service';


@Component({
    selector: 'ostso-group-estimate',
    standalone: true,
    providers: [
        provideNativeDateAdapter(),
        {provide: DataTableService, useClass: RegisterGroupDataTableService}
    ],
    imports: [
        MatSelectModule,
        MatSidenavModule,
        MatIconButton,
        MatIcon,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        AsyncPipe,
        CommonModule,
        MatDatepickerModule,
        SideBarComponent,
        SideBarDoubleComponent,
        MatMenu,
        TableActionDirective,
        TableCellDirective,
        TableComponent,
        MatTable,
        MatMenuTrigger,
    ],
    templateUrl: './group-estimate.component.html',
    styleUrl: './group-estimate.component.scss'
})
export class GroupEstimateComponent {

    public opened = true;

    list = new BehaviorSubject<void>(undefined);

    constructor(
        private route: ActivatedRoute,
        private destroyRef: DestroyRef,
        private dataTableService: DataTableService,
        private dialog: MatDialog,
        private api: GroupService
    ) {
        this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({group}) => {
            this.dataTableService.setData(group);
        });
    }

    onRowSelected(row: { id: string; name: string; }): void {
        console.log(row);
        this.openDialog(row);
    }

    onPageChange(pager: PageEvent): void {
        // this.remarkTypeService.apiRemarktypeGet({searchString: this.searchPhrase, pageSize: pager.pageSize, pageIndex: pager.pageIndex + 1}, 'body', false, {transferCache: false}).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((remarks) => {
        //     this.setTableData(remarks);
        // });
        console.log(pager);
    }

    openDialog(data: GroupEstimateDialogData): void {
        const dialogRef = this.dialog.open(GroupEstimateDialogComponent, {
            width: 'max(320px,50vw)',
            data,
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if(!result) return;
            this.api.putGroup(result)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(console.log);
        });
    }
}
