
export class SubgroupEstimate {
  public id?: number;
  public name: string;
  public shortName: string;
  public rateGroup?: Item;
  public status?: StatusRemarkType;
  constructor(id?: number, name: string='', shortName: string = '', rateGroup: Item = {name:''}, status?: StatusRemarkType) {
    this.id = id
    this.name = name
    this.shortName  = shortName
    this.rateGroup=rateGroup
    this.status = status
  }
}
