<div class="ostso-search-select">
  <ul class="ostso-search-select__selected" #selectedListRef>
    @for (i of selectedList; track i; let idx = $index) {
      <li class="ostso-search-select__item" (click)="remove(idx)">
        <span>{{ i?.shortName || i?.name }}</span>
        <button class="ostso-search-select__button" (click)="remove(idx)">
          <mat-icon class="ostso-search-select__icon">delete</mat-icon>
        </button>
      </li>
    }
  </ul>
  <input type="text" class="ostso-search-select__input remarktype-select" [placeholder]="placeholder"
         [formControl]="searchInput"/>
  <cdk-virtual-scroll-viewport itemSize="56" class="ostso-search-select__viewport" (scrolledIndexChange)="onScroll()">
    @if (!loaded) {
      <ostso-fallback class="ostso-search-select__fallback"/>
    }
    @if (list.length === 0 && loaded) {
      <h3 class="ostso-search-select__not-found">Данные не найдены ...</h3>
    } @else {
      <ul class="ostso-search-select__list">
        @for (i of list; track i; let idx = $index) {
          <li class="ostso-search-select__item" [value]="i" (click)="add(idx)">
            <span class="ostso-search-select__name">
              <input type="checkbox" class="ostso-search-select__checkbox" />
              {{ i?.shortName || i?.name }}
            </span>
            <button class="ostso-search-select__button" (click)="add(idx)">
              <mat-icon class="ostso-search-select__icon">add</mat-icon>
            </button>
          </li>
        }
      </ul>
    }
  </cdk-virtual-scroll-viewport>
</div>
