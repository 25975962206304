import { Injectable } from '@angular/core';

export const fileMimeTypes: Record<string, string> = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  bmp: 'image/bmp',
  webp: 'image/webp',
  svg: 'image/svg+xml',
  tiff: 'image/tiff',
  ico: 'image/vnd.microsoft.icon',
  avif: 'image/avif',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
};

@Injectable({
  providedIn: 'root'
})
export class BlobToFileService {

  constructor() { }

  convert(blob: Blob, fileName:string): File {
    const ext = fileName.split('.').at(-1);
    const mime = ext && fileMimeTypes[ext.toLowerCase()];
    const options = { type: mime };
    return new File([blob], fileName, options);
  }


}
