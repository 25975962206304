import { Component } from '@angular/core';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@Component({
  selector: 'ostso-fallback',
  standalone: true,
  imports: [
    MatProgressSpinner
  ],
  templateUrl: './fallback.component.html',
  styleUrl: './fallback.component.scss'
})
export class FallbackComponent {

}
