import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';
import {RemarkTypeService} from './remark-type.service';


@Injectable({
  providedIn: 'root'
})
export class Group1Service extends RemarkTypeService {

  override localVarPath = '/api/rategroup'

  constructor(
    httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);
  }


}
