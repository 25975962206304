import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SaveFileService {

  save(data: Blob, fileName: string): void {
    // const [rawMime, data] = base64.split(',');
    // const byteCharacters = atob(data); // Декодируем Base64, убирая префикс 'data:image/png;base64,'
    // const byteNumbers = new Array(byteCharacters.length);
    //
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }

    // const mime = this.parseRawMime(rawMime); // Получаем MIME
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], { type: mime }); // Создаем Blob с типом MIME

    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(link.href); // Освобождаем память
  }

  private parseRawMime(rawMime: string): string {
    const mime = rawMime.replace(/^data:/,'').replace(/;base64,$/,'');
    return mime;
  }
}
