import {Injectable} from '@angular/core';
import {AccountControllerService} from '../../modules/apiModule/api/account-controller.service';
import {BehaviorSubject, Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InspectorsService {

  inspectors$ = new BehaviorSubject<User[]>([]);

  constructor(private accountController: AccountControllerService) {
    this.fetch();
  }

  fetch(): void {
    this.accountController.apiAccountGet({roles: ['inspector', 'admin'], pageSize: 1e5}).subscribe(v => {
      console.log('v', v);
      this.inspectors$.next(v.values);
    });
  }

  getInspectors$(): Observable<User[]> {
    return of(this.inspectors$.value.map(v=>({...v, name:v.userName})));
  }
}
