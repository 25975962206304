import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {MatSlider, MatSliderModule} from '@angular/material/slider';
import {FormsModule} from '@angular/forms';
import {NgStyle} from '@angular/common';
import {DialogRef} from '@ngneat/dialog';

@Component({
  selector: 'ostso-zoom-dialog',
  standalone: true,
  imports: [
    MatIcon,
    MatSlider,
    MatSliderModule,
    FormsModule,
    NgStyle
  ],
  templateUrl: './zoom-dialog.component.html',
  styleUrl: './zoom-dialog.component.scss',
})
export class ZoomDialogComponent {
  zoomScale: any = 1;
  constructor(
    public dialogRef: MatDialogRef<ZoomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Blob) {
    console.log(data);
  }
}
