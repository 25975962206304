import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private http: HttpClient) { }

  getGroups() {
    return this.http.get(environment.api_url + '/api/rategroup');
  }

  putGroup({id, name}: {id: string, name: string}) {
    return this.http.put(environment.api_url + '/api/rategroup/' + id,{name});
  }
}
