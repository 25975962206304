import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { NgTemplateOutlet } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  DestroyRef, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef, ViewChild, viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, FormsModule, FormBuilder } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from '@angular/material/table';
import { Observable } from 'rxjs';
import { ITableColumn } from '../../../core/common/interfaces/table-column.interface';
import { DataTableService } from '../../../core/services/data-table.service';
import { TableActionDirective } from './table-action.directive';
import { TableCellDirective } from './table-cell.directive';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {MatSelectModule} from '@angular/material/select';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import {MatSortModule, Sort} from '@angular/material/sort';



interface ICellTemplates {
  [key: string]: TemplateRef<unknown>;
}

const SETTINGS_COLUMN = 'settings';

type SelectableTableColumn = ITableColumn & { isSelected?: boolean};

@Component({
  selector: 'ostso-table',
  standalone: true,
  imports: [
    MatTable,
    MatHeaderCell,
    MatCell,
    MatHeaderRow,
    MatRow,
    MatColumnDef,
    MatHeaderRowDef,
    MatRowDef,
    MatCellDef,
    MatHeaderCellDef,
    NgTemplateOutlet,
    MatIconButton,
    MatMenuItem,
    MatMenu,
    MatMenuTrigger,
    MatCheckbox,
    MatIcon,
    MatPaginator,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MatSelectModule,
    CommonModule
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss'
})
export class TableComponent<T> implements OnInit, AfterContentInit {

  @Input() filterValues?: any;
  @Input({
    required: true
  }) updateList?: Observable<void>;

  @ViewChild(MatTable,{read: ElementRef}) tableRef!: ElementRef<HTMLTableElement>;

  @Output() sortedColumn: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>;
  @Output() rowSelected = new EventEmitter<T>;
  @ContentChildren(TableCellDirective) cellDirectives: QueryList<TableCellDirective> | undefined;
  @ContentChild(TableActionDirective) actionMenuDirective?: TableActionDirective;


  data: CdkTableDataSourceInput<T> = [];

  columns: SelectableTableColumn[] = [];

  displayedColumns: string[] = [];

  cellTemplates: ICellTemplates = {};
  actionTemplate?: TemplateRef<unknown>;

  recordCount = 25;
  pageSize = 10;

  page = 1;


  protected readonly SETTINGS_COLUMN = SETTINGS_COLUMN;

  private subscription = new Subscription();

  form = this.fb.group({
    pageSizeControl: 10
  });


  constructor(
    private dataTableService: DataTableService,
    private destroyRef: DestroyRef,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.setColumns();
    this.initDataObserver();

    this.subscription.add(this.form.get('pageSizeControl')!.valueChanges.subscribe(value => {


      this.page = 1;
      this.pageSize = value!;

      const pageData: PageEvent = {
        pageIndex: 1,
        pageSize: value!,
        length: this.recordCount
      };
      this.onPageChange(pageData);

    }));

  }

  ngAfterContentInit(): void {
    this.initTemplates();
  }

  initDataObserver(): void {
    this.dataTableService.data$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      if (data) {
        this.data = data.values;
        this.recordCount = data.pager?.countTotal;
      }
    });
  }

  setColumns(): void {
    this.columns = this.dataTableService.getColumns();

    if (this.dataTableService.showCustomColumns) {
      this.columns.push(
        {
          name: SETTINGS_COLUMN,
          sticky: true,
          isRequired: true
        });

      this.updateSelectedColumns();
    }
  }

  changedSelectedColumn(): void {
    this.dataTableService.setCustomColumns(
      this.columns
        .filter(value => value.isSelected)
        .map(value => value.name)
    );

    this.updateSelectedColumns();
  }

  updateSelectedColumns(): void {
    const selectedColumns = this.dataTableService.getCustomColumns();

    this.columns.forEach(value => {
      value.isSelected = value.isRequired || value.default ||selectedColumns.includes(value.name);
      value.default = false;
    });
    this.displayedColumns = this.columns
      .filter(value => value.isSelected)
      .map(value => value.name);
  }

  initTemplates(): void {
    if (this.cellDirectives && this.cellDirectives.length) {
      this.cellTemplates = this.cellDirectives.reduce((acc: ICellTemplates, cur) => {
        acc[cur.name] = cur.template;
        return acc;
      }, {});
    }

    if (this.actionMenuDirective) {
      this.actionTemplate = this.actionMenuDirective.template;
    }
  }

  onPageChange(page: PageEvent): void {
    this.pageChange.emit(page);
  }

  setPage(page: any): void {
    this.page=page.page;
    const pageData: PageEvent = {
      pageIndex: page.page,
      pageSize: this.pageSize,
      length: this.recordCount
    };
    this.onPageChange(pageData);
  }

  sortChange($event: Sort): void {
    this.sortedColumn.emit($event);
  }
}
