import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[ostsoMaskNumber]',
  standalone: true
})
export class MaskNumberDirective {

  constructor(public elementRef: ElementRef) {
  }

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const input = event.target as HTMLTextAreaElement;
    const cleaned = input.value.replace(/[^0-9]/g, '');
    input.value = cleaned;
  }
}
