import {Injectable} from '@angular/core';
import {ITableColumn} from '../common/interfaces/table-column.interface';
import {StorageService} from './storage.service';
import {RegisterDataTableService} from './register-data-table.service';


@Injectable({
  providedIn: 'root'
})
export class RegisterKindDataTableService extends RegisterDataTableService {


  constructor(
    storageService: StorageService
  ) {
    super(storageService);
  }


  override getColumns(): ITableColumn[] {
    return [

      {
        name: 'id',
        title: 'Id',
        default: true,
        useCustomTemplate: false,
      },
      {
        name: 'rateGroup',
        title: 'Группа',
        default: true,
        useCustomTemplate: false,
      },
      {
        name: 'rateSubGroup',
        title: 'Подгруппа',
        default: true,
        useCustomTemplate: false,
      },
      {
        name: 'name',
        title: 'Полное наименование',
        isRequired: true,
        default: true,
        useCustomTemplate: true,
      },
      {
        name: 'shortName',
        title: 'Краткое наименование',
        default: true,
        useCustomTemplate: false,
      },
      {
        name: 'status',
        title: 'Архив',
        useCustomTemplate: true,
        disabled: true,
        default: false
      },
    ];
  }


}
