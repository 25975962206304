import { ResolveFn } from '@angular/router';
import {FacilityService} from '../../apiModule/api/facility.service';
import {inject} from '@angular/core';
import {RateCardService} from '../../apiModule/api/rate-card.service';

export const rateCardResolver: ResolveFn<any> = (
  route,
  state,
  rateCardService = inject(RateCardService)
  ) => {
  return rateCardService.apiRatecardGet({});
};
